import React from 'react'
import Helmet from 'react-helmet'
import Reveal from 'react-reveal/Reveal'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import IFrame from '../IFrame'
import Footer from '../Footer'

import { disableCheckout } from '../../actions/uiActions'

import _t from '../../utils/translate'

class CheckoutPage extends React.Component {
  state = { error: false }

  componentDidMount() {
    const { checkOutAllowed, history, language } = this.props
    if (!checkOutAllowed) history.push(`/${language}`)
  }

  render() {
    const { disableCheckout } = this.props
    return (
      <div className="page_content checkout padding_top_70">
        <Helmet>
          <title>SN - Checkout</title>
        </Helmet>

        <Reveal effect="custom_fade_in">
          <div className="title_with_icon">
            <div className="icon icon_cart active" />
            <h1>{_t('Checkout')}</h1>
          </div>
        </Reveal>

        <Reveal effect="custom_fade_in">
          <div className="text_block align_left">
            <IFrame name="iframe_checkout" onLoaded={disableCheckout} />
          </div>
        </Reveal>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  checkOutAllowed: state.ui.checkOutAllowed,
  language: state.ui.language,
})

export default connect(mapStateToProps, { disableCheckout })(
  withRouter(CheckoutPage)
)
