import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const HorizontalMenu = ({ apiElement }) => {
    // console.log(apiElement)
    return (
        <div className="horizontal_menu_wrapper">
			<div className="horizontal_menu">
				<div className="column">
					<div className={`icon icon_${apiElement.icon_1} active`} />
					<h2>{apiElement.title_1}</h2>
					<p>{apiElement.description_1}</p>
					{apiElement.file_1 ? (
						<a
							href={apiElement.file_1}
							className="button"
							target="_blank"
							rel="noopener noreferrer"
						>
							{apiElement.button_text_1}
						</a>
					) : (
						<Link
							to={apiElement.button_link_1 || './'}
							className="button"
						>
							{apiElement.button_text_1}
						</Link>
					)}
				</div>
				<div className="column">
					<div className={`icon icon_${apiElement.icon_2} active`} />
					<h2>{apiElement.title_2}</h2>
					<p>{apiElement.description_2}</p>
					{apiElement.file_2 ? (
						<a
							href={apiElement.file_2}
							className="button"
							target="_blank"
							rel="noopener noreferrer"
						>
							{apiElement.button_text_2}
						</a>
					) : (
						<Link
							to={apiElement.button_link_2 || './'}
							className="button"
						>
							{apiElement.button_text_2}
						</Link>
					)}
				</div>
				<div className="column">
					<div className={`icon icon_${apiElement.icon_3} active`} />
					<h2>{apiElement.title_3}</h2>
					<p>{apiElement.description_3}</p>
					{apiElement.file_3 ? (
						<a
							href={apiElement.file_3}
							className="button"
							target="_blank"
							rel="noopener noreferrer"
						>
							{apiElement.button_text_3}
						</a>
					) : (
						<Link
							to={apiElement.button_link_3 || './'}
							className="button"
						>
							{apiElement.button_text_3}
						</Link>
					)}
				</div>
				<div className="column">
					<div className={`icon icon_${apiElement.icon_4} active`} />
					<h2>{apiElement.title_4}</h2>
					<p>{apiElement.description_4}</p>
					{apiElement.file_4 ? (
						<a
							href={apiElement.file_4}
							className="button"
							target="_blank"
							rel="noopener noreferrer"
						>
							{apiElement.button_text_4}
						</a>
					) : (
						<Link
							to={apiElement.button_link_4 || './'}
							className="button"
						>
							{apiElement.button_text_4}
						</Link>
					)}
				</div>
			</div>
		</div>
    )
}

HorizontalMenu.defaultProps = {
    apiElement: ''
}
HorizontalMenu.propTypes = {
    apiElement: PropTypes.object // eslint-disable-line
}

export default HorizontalMenu