import { LOAD_PRODUCTS, LOAD_PRODUCTS_ACF } from './types'
import { api } from '../api'

function handleErrors(response) {
	if (response.status !== 200) {
		throw Error(JSON.stringify(response))
	} else return response
}

export const loadProducts = () => dispatch => {
	// console.log('loadSingleRemedies')
	// console.log('load Products!')
	api.products
		.getProducts()
		.then(handleErrors)
		// .then(res => res.data.json())
		.then(res => {
			// console.log(res.data)
			return res.data
		})
		// .then(res => {
		// 	// 	// return res.reduce((obj, item) => {
		// 	// 	// obj[item.slug] = item
		// 	// 	// return obj
		// 	// 	// }, {})
		// 	return res.reduce((arr = [], item) => {
		// 		arr.push(item)
		// 		return arr
		// 	}, {})
		// })
		// .then(res => )
		// .then(res => {
		// 	console.log(res)
		// 	return res
		// })
		.then(products =>
			dispatch({
				type: LOAD_PRODUCTS,
				payload: products
			})
		)
		// .then(() =>
		// 	api.products
		// 		.getAcf()
		// 		.then(handleErrors)
		// 		.then(res => res.data)
		// 		.then(res => {
		// 			// console.log(res)
		// 			return res
		// 		})
		// 		.then(res =>
		// 			res.map(element => ({
		// 				id: element.id,
		// 				acf: element.acf
		// 			}))
		// 		)
		// 		.then(products_acf =>
		// 			dispatch({
		// 				type: LOAD_PRODUCTS_ACF,
		// 				payload: products_acf
		// 			})
		// 		)
		// )
		.catch(err => {
			console.error(
				`There seems to be a problem with the products api: ${err}`
			)
		})
}

export const test = 'test'
