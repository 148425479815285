import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const BackLink = ({ to, children }) => {
	return (
		<Link className="back_link" to={to}>
			<div className="icon icon_back active" />
			<span>{children}</span>
		</Link>
	)
}

BackLink.defaultProps = {
	to: './'
}

BackLink.propTypes = {
	to: PropTypes.string
}

export default BackLink
