import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'
import shortid from 'shortid'

import _t from '../../utils/translate'
import ComplexeSingle from './ComplexeSingle'
import Spinner from '../Spinner'

class EssentialsDisplay extends React.Component {
    constructor(props) {
        super(props)
        const { language } = this.props

        this.state = {
            activePage: 1,
            nrPages: null,
            nrItemsPerPage: this.props.nrItemsPerPage,
            ready: false,
            linkBase: `/${language}/${_t('shop', 'routes')}/${_t(
                'homeopathy',
                'routes'
            )}/${_t('complexes', 'routes')}`
        }

        this.nextPage = this.nextPage.bind(this)
        this.lastPage = this.lastPage.bind(this)
        this.initializeComponent = this.initializeComponent.bind(this)
    }

    componentDidMount() {
        const { products } = this.props
        if (!products.loading) this.initializeComponent()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loading && !this.props.loading) this.initializeComponent()
    }

    initializeComponent() {
        const { products } = this.props
        const { nrItemsPerPage } = this.state
        const filteredProducts = Object.values(products.products).filter(
            element => {
                return _.find(
                    element.categories,
                    cat => cat.slug === 'complexe'
                )
            }
        )
        const sortedProducts = _.orderBy(filteredProducts, e =>
            parseInt(e.acf.nr, 10)
        )

        // const essentials = apiElement.essentials_names.split('\r\n')

        const nrPages = Math.ceil(sortedProducts.length / nrItemsPerPage)
        // console.log(`Nr of Pages: ${nrPages}`)
        this.setState({ nrPages, sortedProducts, ready: true })
    }

    lastPage() {
        const { activePage } = this.state
        if (activePage > 1)
            this.setState(oldState => ({ activePage: oldState.activePage - 1 }))
    }

    nextPage() {
        const { activePage, nrPages } = this.state
        if (activePage < nrPages)
            this.setState(oldState => ({ activePage: oldState.activePage + 1 }))
        // else this.setState({ activePage: 1 })
    }

    render() {
        const { apiElement } = this.props
        const {
            activePage,
            nrPages,
            sortedProducts,
            nrItemsPerPage,
            ready,
            linkBase
        } = this.state
        const { lastPage, nextPage } = this
        // const essentials = apiElement.essentials_names.split('\r\n')

        // console.log(apiElement)

        let nrItems = 0
        let marginPercent = 0
        let offsetLeftPercent = 0
        if (ready) {
            nrItems = sortedProducts.length
            marginPercent = 0.8
            offsetLeftPercent = (activePage - 1) * 100
            if (activePage === nrPages) {
                offsetLeftPercent =
                    (activePage - 1) * 100 -
                    100 /
                        (nrItemsPerPage /
                            (nrItemsPerPage - (nrItems % nrItemsPerPage)))
            }
        }
        return (
            <div className="essentials_display_wrapper">
                <div className="essentials_display">
                    <div className="description">
                        <div
                            className={`icon icon_${apiElement.icon} active`}
                        />
                        <h1>{apiElement.title}</h1>
                        {apiElement.text !== '' && <p>{apiElement.text}</p>}
                    </div>

                    {ready && (
                        <div className="slider_navigation_wrapper">
                            <div className="slider_wrapper">
                                <div
                                    className="slider"
                                    style={{
                                        left: `-${offsetLeftPercent}%`,
                                        width: `${(100 / nrItemsPerPage) *
                                            nrItems}%`
                                    }}
                                >
                                    {sortedProducts.map(product => {
                                        return (
                                            <ComplexeSingle
                                                linkBase={linkBase}
                                                key={shortid.generate()}
                                                product={product}
                                                style={{
                                                    width: `${(100 / nrItems) *
                                                        (1 -
                                                            marginPercent *
                                                                0.2)}%`,
                                                    margin: `0 ${((100 /
                                                        nrItems) *
                                                        marginPercent) /
                                                        10}%`,
                                                    float: 'left',
                                                    padding: 0,
                                                    paddingBottom: `${(100 /
                                                        nrItems) *
                                                        (1 -
                                                            marginPercent *
                                                                0.2) *
                                                        0.666}%`
                                                }}
                                            />
                                        )
                                    })}
                                    <div className="clearfix" />
                                </div>
                            </div>

                            <button
                                type="button"
                                className={`navigation left ${activePage !==
                                    1 && 'visible'}`}
                                onClick={lastPage}
                            >
                                <div className="icon icon_back active" />
                            </button>

                            <button
                                type="button"
                                className={`navigation right ${activePage !==
                                    nrPages && 'visible'}`}
                                onClick={nextPage}
                            >
                                <div className="icon icon_forward active" />
                            </button>
                        </div>
                    )}
                    <div className={`loading ${ready ? '' : 'active'}`}>
                        <Spinner size="small" />
                    </div>

                    <Link to={linkBase} className="button">
                        {apiElement.button_text}
                    </Link>
                </div>
            </div>
        )
    }
}

EssentialsDisplay.defaultProps = {
    apiElement: '',
    nrItemsPerPage: 4
}
EssentialsDisplay.propTypes = {
    apiElement: PropTypes.object, // eslint-disable-line
    nrItemsPerPage: PropTypes.number
}

const mapStateToProps = state => ({
    products: state.products,
    language: state.ui.language
})

export default connect(mapStateToProps)(EssentialsDisplay)
