import React from 'react'
import { Redirect } from 'react-router-dom'
import detectBrowserLanguage from 'detect-browser-language'
import { connect } from 'react-redux'
import Settings from './Settings'

const LanguageRedirect = ({ chosenLanguage }) => {
	const browserLanguage = detectBrowserLanguage()
		.slice(0, 2)
		.toLowerCase()

	console.log(detectBrowserLanguage())

	const supportedLanguage = Settings.supportedLanguages.filter(
		lang => lang === browserLanguage
	)
	console.log('supportedLanguage')
	console.log(supportedLanguage)

	let languageToShow = Settings.defaultLanguage

	if (supportedLanguage.length > 0) {
		languageToShow = supportedLanguage[0] // eslint-disable-line
	}

	if (chosenLanguage) {
		languageToShow = chosenLanguage
		console.log('chosenLanguage')
		console.log(chosenLanguage)
	}

	console.log('languageToShow')
	console.log(languageToShow)

	return <Redirect to={`/${languageToShow}/`} />
}

const mapStateToProps = state => ({
	chosenLanguage: state.language.chosenLanguage
})

export default connect(mapStateToProps, null)(LanguageRedirect)
