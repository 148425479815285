import { SET_LANGUAGE_MANUALLY } from './types'

export const setLanguageManually = language => dispatch => {
    dispatch({
        type: SET_LANGUAGE_MANUALLY,
        payload: language
    })
}

export const makeEslintHappy = null
