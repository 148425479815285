import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import ProductsDisplay from '../ProductsDisplay'
import SinglePageRenderer from '../SinglePageRenderer'
import SingleProductRenderer from '../SingleProductRenderer'

import _t from '../../utils/translate'
import Settings from '../../Settings'

// import { connect } from 'react-redux'

const ShopPage = ({ language }) => {
    // window.open(Settings.shopLink, '_blank') ||
    // window.location.replace(Settings.shopLink)
    // return (
    //   <div className="page_content padding_top_140">
    //     <h3
    //       style={{
    //         marginTop: 100,
    //         textAlign: 'center',
    //         position: 'relative',
    //         left: 0,
    //         display: 'block',
    //         width: '100%',
    //       }}
    //     >
    //       {_t('Loading...')}
    //     </h3>
    //   </div>
    // )

    // return <Redirect to={`/${language}/`} />
    return (
        <React.Fragment>
            <Route
                path={`/${language}/${_t('shop', 'routes')}`}
                exact
                component={() => (
                    <Redirect
                        to={`/${language}/${_t('shop', 'routes')}/${_t(
                            'homeopathy',
                            'routes'
                        )}`}
                    />
                )}
            />
            <Route
                path={`/${language}/${_t('shop', 'routes')}/${_t(
                    'homeopathy',
                    'routes'
                )}`}
                exact
                component={() => <SinglePageRenderer pageId="shop_homeopathy" />}
            />
            <Route
                path={`/${language}/${_t('shop', 'routes')}/${_t(
                    'homeopathy',
                    'routes'
                )}/${_t('complexes', 'routes')}`}
                exact
                component={() => (
                    <SinglePageRenderer pageId="shop_homeopathy_complexes" />
                )}
            />
            <Route
                path={`/${language}/${_t('shop', 'routes')}/${_t(
                    'homeopathy',
                    'routes'
                )}/otcs`}
                exact
                component={() => <SinglePageRenderer pageId="shop_homeopathy_otcs" />}
            />
            <Route
                path={`/${language}/${_t('shop', 'routes')}/${_t(
                    'homeopathy',
                    'routes'
                )}/${_t('complexes', 'routes')}/:product`}
                exact
                component={(routeProps) => (
                    <SingleProductRenderer {...routeProps} category="complexes" />
                )}
            />
            <Route
                path={`/${language}/${_t('shop', 'routes')}/otc/:product`}
                exact
                component={(routeProps) => (
                    <SingleProductRenderer {...routeProps} category="otcs" />
                )}
            />
            <Route
                path={`/${language}/${_t('shop', 'routes')}/${_t(
                    'homeopathy',
                    'routes'
                )}/products/:product`}
                exact
                component={(routeProps) => (
                    <SingleProductRenderer {...routeProps} category="homeopathy" />
                )}
            />
            <Route
                path={`/${language}/${_t('shop', 'routes')}/${_t(
                    'homeopathy',
                    'routes'
                )}/${_t('remedies', 'routes')}/:remedyCode?`}
                component={() => (
                    <SinglePageRenderer pageId="shop_homeopathy_remedies" />
                )}
            />
            <Route
                path={`/${language}/${_t('shop', 'routes')}/${_t(
                    'gemmotherapy',
                    'routes'
                )}/:remedyCode?`}
                component={() => <SinglePageRenderer pageId="shop_gemmotherapy" />}
            />
            <Route
                path={`/${language}/${_t('shop', 'routes')}/${_t(
                    'gemmotherapy',
                    'routes'
                )}/otcs`}
                exact
                component={() => <SinglePageRenderer pageId="shop_gemmotherapy_otcs" />}
            />
            <Route
                path={`/${language}/${_t('shop', 'routes')}/${_t(
                    'phytotherapy',
                    'routes'
                )}/:remedyCode?`}
                component={() => <SinglePageRenderer pageId="shop_phytotherapy" />}
            />
            <Route
                path={`/${language}/${_t('shop', 'routes')}/${_t(
                    'oligotherapy',
                    'routes'
                )}/:remedyCode?`}
                component={() => <SinglePageRenderer pageId="shop_oligotherapy" />}
            />
            <Route
                path={`/${language}/${_t('shop', 'routes')}/${_t(
                    'schussler_salts',
                    'routes'
                )}`}
                exact
                component={() => <SinglePageRenderer pageId="shop_schussler_salts" />}
            />
            <Route
                path={`/${language}/${_t('shop', 'routes')}/${_t(
                    'schussler_salts',
                    'routes'
                )}/:product`}
                exact
                component={(routeProps) => (
                    <SingleProductRenderer {...routeProps} category="schussler_salts" />
                )}
            />
            <Route
                path={`/${language}/${_t('shop', 'routes')}/${_t(
                    'food_supplements',
                    'routes'
                )}`}
                exact
                component={() => <SinglePageRenderer pageId="shop_food_supplements" />}
            />{' '}
            <Route
                path={`/${language}/${_t('shop', 'routes')}/${_t(
                    'food_supplements',
                    'routes'
                )}/${_t('products', 'routes')}/:product`}
                exact
                component={(routeProps) => (
                    <SingleProductRenderer {...routeProps} category="food_supplements" />
                )}
            />
            <Route
                path={`/${language}/${_t('shop', 'routes')}/${_t(
                    'make_your_own',
                    'routes'
                )}`}
                component={() => <SinglePageRenderer pageId="shop_make_your_own" />}
            />
            <Route
                path={`/${language}/${_t('shop', 'routes')}/${_t(
                    'homeopathy',
                    'routes'
                )}/${_t('seasonal', 'routes')}`}
                component={() => <SinglePageRenderer pageId="shop_seasonal_products" />}
            />
            <ProductsDisplay />
        </React.Fragment>
    )
}
// const mapStateToProps = (state) => ({
//     language: state.ui.language
// })

// export default connect(mapStateToProps)(ShopPage)
export default ShopPage
