import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import shortid from 'shortid'
import _ from 'lodash'
import Reveal from 'react-reveal/Reveal'
// import Select from 'react-select'

import Settings from '../../Settings'
import Footer from '../Footer'
import BackLink from '../BackLink'
import { addToCart } from '../../actions/cartActions'
import formatPrice from '../../utils/formatPrice'
import urlFormat from '../../utils/urlFormat'
import _t from '../../utils/translate'
import QuantityField from '../../utils/QuantityField'

class SingleSchussler extends React.Component {
  constructor(props) {
    super(props)
    this.addToCartHandler = this.addToCartHandler.bind(this)
    this.selectProduct = this.selectProduct.bind(this)
    this.changeQuantity = this.changeQuantity.bind(this)
    // this.selectProduct()
    this.state = { product: null, quantity: 1 }
  }

  static getDerivedStateFromProps(nextProps) {
    const { match, products, loading } = nextProps

    // console.log(products)
    console.log(match.params.product)

    if (!loading) {
      const product = _.find(products, (element) => {
        return (
          urlFormat(element.name) === match.params.product &&
          _.find(element.categories, { name: 'Complexe' })
        )
      })
      // console.log(product)
      return { product }
    }

    return null
  }

  addToCartHandler = () => {
    const { product, quantity } = this.state
    const { addToCart: _addToCart } = this.props

    const newProduct = {
      WcId: product.id,
      price: parseFloat(product.price),
      name: `${_t('Complexe')} N°${product.acf.nr} ${product.name}`,
      icon: 'package_unknown',
      info: {},
      quantity,
    }
    _addToCart(newProduct)
  }

  changeQuantity = (quantity) => this.setState({ quantity })

  selectProduct = (e) => {
    const { products } = this.props

    const schusslerType = e.value.includes('pl') ? 'pills' : 'powder'

    const product = _.find(products, {
      slug: `schussler-salts-${schusslerType}`,
    })
    console.log(product)
    this.setState({ ...this.state, product, form: e.value })
  }

  render() {
    const { language } = this.props
    const { product, quantity } = this.state
    const { changeQuantity } = this

    // defaultValue={{
    // 	value: schusslerSalt.avl[0],
    // 	label: _t(schusslerSalt.avl[0], 'schussler_salts')
    // }}

    // console.log(options)
    // 	const { loading } = products

    // 	if (!loading && product) {
    // 		// const product = this.findProduct()

    // 		// console.log(product)
    // return <p>HELLO!!!</p>
    if (product) {
      return (
        <div className="page_content single_product padding_top_140">
          <Helmet>
            <title>{`SN - N°${product.acf.nr} ${product.name}`}</title>
          </Helmet>
          <BackLink
            to={`/${language}/${_t('shop', 'routes')}/${_t(
              'homeopathy',
              'routes'
            )}/${_t('complexes', 'routes')}`}
          >
            {_t('Back to Complexes')}
          </BackLink>

          <div className="title_with_icon_wrapper">
            <div className="title_with_icon">
              <div className="icon icon_gemmotherapy active" />
              <h1>
                N°{product.acf.nr}
                <br />
                {product.name} {_t('Complexe')}
              </h1>
            </div>
          </div>
          <div className="single_product_wrapper">
            <div className="product_image">
              <div className="icon icon_gemmotherapy" />
              <div className={`complexe_image complexe_${product.acf.nr}`} />
            </div>
            <div className="product_info">
              <div className="product_description">
                {/* false && (
									<p>
										{`dsc_${language}` in product.acf &&
										product.acf[`dsc_${language}`] !== ''
											? product.acf[`dsc_${language}`]
											: `${language.toUpperCase()} Description missing!`}
									</p>
								) */}
                {Settings.topicAllowed &&
                  `topic_${language}` in product.acf &&
                  product.acf[`topic_${language}`] !== '' && (
                    <React.Fragment>
                      <h2>{_t('Topic')}</h2>
                      <div className="topic">
                        <p>{product.acf[`topic_${language}`]}</p>
                      </div>
                    </React.Fragment>
                  )}
                {product?.acf?.[`form_${language}`] && (
                  <React.Fragment>
                    <h2>{_t('Form', 'shop')}</h2>
                    <div className="form">
                      <p>{product?.acf?.[`form_${language}`]}</p>
                    </div>
                  </React.Fragment>
                )}
                {Settings.indicationAllowed &&
                  `indication_${language}` in product.acf &&
                  product.acf[`indication_${language}`] !== '' && (
                    <React.Fragment>
                      <h2>{_t('Indication')}</h2>
                      <div
                        className="indication"
                        dangerouslySetInnerHTML={{
                          __html: product.acf[`indication_${language}`] || null,
                        }}
                      />
                    </React.Fragment>
                  )}
                {Settings.posologyAllowed &&
                  `posology_${language}` in product.acf &&
                  product.acf[`posology_${language}`] !== '' && (
                    <React.Fragment>
                      <h2>{_t('Posology')}</h2>

                      <div
                        className="posology"
                        dangerouslySetInnerHTML={{
                          __html: product.acf[`posology_${language}`] || null,
                        }}
                      />
                    </React.Fragment>
                  )}
                {`composition` in product.acf &&
                  product.acf.composition !== '' && (
                    <React.Fragment>
                      <h2>{_t('Composition')}</h2>
                      <div
                        className="composition"
                        dangerouslySetInnerHTML={{
                          __html: product.acf.composition || null,
                        }}
                      />
                    </React.Fragment>
                  )}
              </div>
              <div className="buy_section">
                <span className="price">
                  {product && formatPrice(parseFloat(product.price))}
                </span>
                <QuantityField onChange={changeQuantity} quantity={quantity} />
                <button
                  type="button"
                  className="buy"
                  onClick={this.addToCartHandler}
                  disabled={!product}
                >
                  {_t('Add to Cart', 'shop')}
                </button>
              </div>
            </div>
            <div className="clearfix" />
          </div>

          <Footer />
        </div>
      )
    }
    return null
  }
}

SingleSchussler.defaultProps = {}

SingleSchussler.propTypes = {}

const mapStateToProps = (state) => ({
  productsLoading: state.products.loading,
  products: state.products.products,
  schusslerSalts: state.singleRemedies.schussler,
  loading: state.products.loading,
  language: state.ui.language,
})

const SingleSchusslerWrapper = (props) => (
  <Reveal effect="custom_fade_in" key={shortid.generate()}>
    <SingleSchussler {...props} />
  </Reveal>
)

export default connect(mapStateToProps, { addToCart })(SingleSchusslerWrapper)
