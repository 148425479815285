import React from 'react'
import Validator from 'validator'

import _t from '../utils/translate'

class AddressField extends React.Component {
	state = { edit: false, data: {} }

	constructor(props) {
		super(props)
		this.toggleEdit = this.toggleEdit.bind(this)
		this.validate = this.validate.bind(this)
		this.resetError = this.resetError.bind(this)
		this.FirstName = React.createRef()
		this.LastName = React.createRef()
		this.Company = React.createRef()
		this.Address1 = React.createRef()
		this.Address2 = React.createRef()
		this.Postcode = React.createRef()
		this.City = React.createRef()
		this.State = React.createRef()
		this.Country = React.createRef()
	}

	componentDidMount() {
		const { field } = this.props
		this.setState({
			data: {
				[field.name]: field.data
			}
		})
		// console.log(field)
	}

	validate = data => {
		let error = false
		if (data.login) {
			if (!Validator.isEmail(data.login)) error = 'Invalid email'
		}
		// if (!data.password) errors.password = "Can't be blank"

		return error
	}

	toggleEdit(e) {
		e.preventDefault()
		const { edit } = this.state
		const { field, onSave } = this.props
		const { validate } = this
		const { type } = field
		if (!edit) this.setState(prevState => ({ edit: !prevState.edit }))
		else {
			// console.log(input.current.value)
			// const newData = { [field.name]: input.current.value }
			// const newData = {}
			// console.log(this.FirstName.current.value)
			const newData = {
				[`${type}FirstName`]: this.FirstName.current.value,
				[`${type}LastName`]: this.LastName.current.value,
				[`${type}Company`]: this.Company.current.value,
				[`${type}Address1`]: this.Address1.current.value,
				[`${type}Address2`]: this.Address2.current.value,
				[`${type}Postcode`]: this.Postcode.current.value,
				[`${type}City`]: this.City.current.value,
				[`${type}State`]: this.State.current.value,
				[`${type}Country`]: this.Country.current.value
			}

			const error = validate(newData)
			if (!error) {
				onSave(newData)
				this.setState({ edit: false })
			} else this.setState({ error })
		}
	}

	resetError() {
		this.setState({ error: false })
	}

	render() {
		const { field } = this.props
		const { edit, error } = this.state
		const { toggleEdit, resetError } = this
		const { type } = field
		return (
			<div
				className={`field ${type}_address address ${
					edit ? 'edit' : ''
				}`}
			>
				<span className="label">{field.label}</span>
				<p className="content">
					{field.data[`${type}FirstName`]}{' '}
					{field.data[`${type}LastName`]}
					<br />
					{field.data[`${type}Company`] && (
						<React.Fragment>
							{field.data[`${type}Company`]}
							<br />
						</React.Fragment>
					)}
					{field.data[`${type}Address1`]}
					<br />
					{field.data[`${type}Address2`] && (
						<React.Fragment>
							{field.data[`${type}Address2`]}
							<br />
						</React.Fragment>
					)}
					{field.data[`${type}Postcode`]} {field.data[`${type}City`]}
					<br />
					{field.data[`${type}State`]} {field.data[`${type}Country`]}
					<br />
				</p>
				<form onSubmit={toggleEdit}>
					{error ? <span className="error">{error}</span> : null}
					<label htmlFor={`${type}FirstName`}>
						{_t('First Name', 'account')}
						<input
							className="edit"
							name={`${type}FirstName`}
							defaultValue={field.data[`${type}FirstName`]}
							onChange={resetError}
							ref={this.FirstName}
						/>
					</label>

					<label htmlFor={`${type}LastName`}>
						{_t('Last Name', 'account')}
						<input
							className="edit"
							name={`${type}LastName`}
							defaultValue={field.data[`${type}LastName`]}
							onChange={resetError}
							ref={this.LastName}
						/>
					</label>

					<label htmlFor={`${type}Company`}>
						{_t('Company', 'account')}
						<input
							className="edit"
							name={`${type}Company`}
							defaultValue={field.data[`${type}Company`]}
							onChange={resetError}
							ref={this.Company}
						/>
					</label>

					<label htmlFor={`${type}Address1`}>
						{_t('Address Line 1', 'account')}

						<input
							className="edit"
							name={`${type}Address1`}
							defaultValue={field.data[`${type}Address1`]}
							onChange={resetError}
							ref={this.Address1}
						/>
					</label>

					<label htmlFor={`${type}Address2`}>
						{_t('Address Line 2', 'account')}
						<input
							className="edit"
							name={`${type}Address2`}
							defaultValue={field.data[`${type}Address2`]}
							onChange={resetError}
							ref={this.Address2}
						/>
					</label>

					<label htmlFor={`${type}Postcode`}>
						{_t('Postcode', 'account')}
						<input
							className="edit"
							name={`${type}Postcode`}
							defaultValue={field.data[`${type}Postcode`]}
							onChange={resetError}
							ref={this.Postcode}
						/>
					</label>

					<label htmlFor={`${type}City`}>
						{_t('City', 'account')}
						<input
							className="edit"
							name={`${type}City`}
							defaultValue={field.data[`${type}City`]}
							onChange={resetError}
							ref={this.City}
						/>
					</label>

					<label htmlFor={`${type}State`}>
						{_t('State', 'account')}
						<input
							className="edit"
							name={`${type}State`}
							defaultValue={field.data[`${type}State`]}
							onChange={resetError}
							ref={this.State}
						/>
					</label>

					<label htmlFor={`${type}Country`}>
						{_t('Country', 'account')}
						<input
							className="edit"
							name={`${type}Country`}
							defaultValue={field.data[`${type}Country`]}
							onChange={resetError}
							ref={this.Country}
						/>
					</label>
				</form>
				<button type="button" className="change" onClick={toggleEdit}>
					{_t(edit ? 'Save' : 'Edit')}
				</button>
			</div>
		)
	}
}
//					<input
// 	className="edit"
// 	name="login"
// 	defaultValue={field.data}
// 	onChange={resetError}
// 	ref={this.input}
// />

export default AddressField
