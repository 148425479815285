import React from 'react'
import { withRouter, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import SecondaryMenuIcon from './SecondaryMenuIcon'

import _t from '../utils/translate'

const MenuShop = () => {
  const scope = _t('shop', 'routes')
  return (
    <div className="secondary_menu">
      <SecondaryMenuIcon
        title={_t('Homeopathy', 'menu')}
        icon="homeopathy"
        scope={scope}
      />
      <SecondaryMenuIcon
        title={_t('Gemmotherapy', 'menu')}
        icon="gemmotherapy"
        scope={scope}
      />
      <SecondaryMenuIcon
        title={_t('Phytotherapy', 'menu')}
        icon="phytotherapy"
        scope={scope}
      />
      <SecondaryMenuIcon
        title={_t('Oligotherapy', 'menu')}
        icon="oligotherapy"
        scope={scope}
      />
      <SecondaryMenuIcon
        title={_t('Schussler Salts', 'menu')}
        icon="schussler_salts"
        scope={scope}
      />
      <SecondaryMenuIcon
        title={_t('Food Supplements', 'menu')}
        icon="food_supplements"
        scope={scope}
      />
      <SecondaryMenuIcon
        title={_t('Make your Own', 'menu')}
        icon="make_your_own"
        scope={scope}
      />
    </div>
  )
}

const MenuCompany = () => {
  const scope = _t('company', 'routes')
  return (
    <div className="secondary_menu">
      <SecondaryMenuIcon
        title={_t('Story', 'menu')}
        icon="story"
        scope={scope}
      />
      <SecondaryMenuIcon
        title={_t('Garden', 'menu')}
        icon="garden"
        scope={scope}
      />
      <SecondaryMenuIcon title={_t('Lab', 'menu')} icon="lab" scope={scope}/>
      <SecondaryMenuIcon
        title={_t('Values', 'menu')}
        icon="values"
        scope={scope}
      />
      <SecondaryMenuIcon
        title={_t('Quality', 'menu')}
        icon="quality"
        scope={scope}
      />
      <SecondaryMenuIcon title={_t('Team', 'menu')} icon="team" scope={scope}/>
    </div>
  )
}

const MenuMedicine = () => {
  const scope = _t('medicine', 'routes')
  return (
    <div className="secondary_menu">
      <SecondaryMenuIcon
        title={_t('Homeopathy', 'menu')}
        icon="homeopathy"
        scope={scope}
      />
      <SecondaryMenuIcon
        title={_t('Gemmotherapy', 'menu')}
        icon="gemmotherapy"
        scope={scope}
      />
      <SecondaryMenuIcon
        title={_t('Phytotherapy', 'menu')}
        icon="phytotherapy"
        scope={scope}
      />
      <SecondaryMenuIcon
        title={_t('Oligotherapy', 'menu')}
        icon="oligotherapy"
        scope={scope}
      />
      <SecondaryMenuIcon
        title={_t('Schussler Salts', 'menu')}
        icon="schussler_salts"
        scope={scope}
      />
      <SecondaryMenuIcon
        title={_t('Food Supplements', 'menu')}
        icon="food_supplements"
        scope={scope}
      />
    </div>
  )
}

const MenuResources = () => {
  const scope = _t('resources', 'routes')
  return (
    <div className="secondary_menu">
      <SecondaryMenuIcon
        title={_t('Where to find our remedies', 'menu')}
        icon="where"
        scope={scope}
      />
      <SecondaryMenuIcon
        title={_t('Training', 'menu')}
        icon="training"
        scope={scope}
      />
      <SecondaryMenuIcon
        title={_t('Research', 'menu')}
        icon="research"
        scope={scope}
      />
      <SecondaryMenuIcon
        title={_t('Foundation', 'menu')}
        icon="foundation"
        scope={scope}
      />
      <SecondaryMenuIcon
        title={_t('Associations', 'menu')}
        icon="associations"
        scope={scope}
      />
    </div>
  )
}

// const MenuScience = () => {
// 	const scope = 'science'
// 	return (
// 		<div className="secondary_menu">
// 			<SecondaryMenuIcon title="Science" icon="science" scope={scope} />
// 			<SecondaryMenuIcon title="Video" icon="video" scope={scope} />
// 		</div>
// 	)
// }

const MenuAccount = () => {
  const scope = _t('account', 'routes')
  return (
    <div className="secondary_menu">
      <SecondaryMenuIcon
        title={_t('Security', 'menu')}
        icon="security"
        scope={scope}
      />
      <SecondaryMenuIcon
        title={_t('Addresses', 'menu')}
        icon="addresses"
        scope={scope}
      />
      <SecondaryMenuIcon
        title={_t('Downloads', 'menu')}
        icon="downloads"
        scope={scope}
      />
    </div>
  )
}
// <SecondaryMenuIcon title={_t("Payment", 'menu')} icon="payment" scope={scope} />

const SecondaryMenu = ({language}) => (
  <React.Fragment key={`menu_${language}`}>
    <Route path={`/${language}/${_t('shop', 'routes')}`} component={MenuShop}/>
    <Route
      path={`/${language}/${_t('company', 'routes')}`}
      component={MenuCompany}
    />
    <Route
      path={`/${language}/${_t('medicine', 'routes')}`}
      component={MenuMedicine}
    />
    <Route
      path={`/${language}/${_t('resources', 'routes')}`}
      component={MenuResources}
    />
    <Route
      path={`/${language}/${_t('account', 'routes')}`}
      component={MenuAccount}
    />
  </React.Fragment>
)
// <Route path="/science" component={MenuScience} />

const mapStateToProps = (state) => ({
  language: state.ui.language,
})

export default connect(mapStateToProps)(withRouter(SecondaryMenu))
