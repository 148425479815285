import {
  SHOW_MESSAGE,
  HIDE_ALL_POPUPS,
  SET_LANGUAGE,
  SHOW_LANGUAGE_CHOOSER,
  SHOW_LOGIN_POPUP,
  SHOW_SIGNUP_POPUP,
  HIDE_POPUPS,
  HIDE_MESSAGE,
  SHOW_SEARCH,
  HIDE_SEARCH,
  SHOW_MENU,
  HIDE_MENU,
  SHOW_COMPLETE_RESET_PASSWORD_POPUP,
  ALLOW_CHECKOUT,
  DISABLE_CHECKOUT,
} from '../actions/types'

const initialState = {
  overlayVisible: false,
  messageVisible: false,
  messageText: '',
  messageType: '',
  language: 'fr',
  languageChooserVisible: false,
  searchVisible: false,
  menuVisible: false,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SHOW_MESSAGE:
      return {
        ...state,
        messageVisible: true,
        messageText: action.payload.text,
        messageType: action.payload.type,
        messageDuration: action.payload.duration,
      }
    case HIDE_MESSAGE:
      return {
        ...state,
        messageVisible: false,
      }
    case SHOW_LOGIN_POPUP:
      return {
        ...state,
        overlayVisible: true,
      }
    case SHOW_COMPLETE_RESET_PASSWORD_POPUP:
      return {
        ...state,
        overlayVisible: true,
      }
    case SHOW_SIGNUP_POPUP:
      return {
        ...state,
        overlayVisible: true,
      }
    case HIDE_ALL_POPUPS:
      return {
        ...state,
        overlayVisible: false,
        messageVisible: false,
        languageChooserVisible: false,
      }
    case HIDE_POPUPS:
      return {
        ...state,
        overlayVisible: false,
      }
    case SET_LANGUAGE:
      return { ...state, language: action.payload }
    case SHOW_LANGUAGE_CHOOSER:
      return {
        ...state,
        overlayVisible: true,
        languageChooserVisible: true,
      }
    case SHOW_SEARCH:
      return { ...state, searchVisible: true }
    case HIDE_SEARCH:
      return { ...state, searchVisible: false }
    case SHOW_MENU:
      return { ...state, menuVisible: true }
    case HIDE_MENU:
      return { ...state, menuVisible: false }
    case ALLOW_CHECKOUT:
      return { ...state, checkOutAllowed: true }
    case DISABLE_CHECKOUT:
      return { ...state, checkOutAllowed: false }
    default:
      return state
  }
}
