// import React from 'react'
import { method } from 'lodash'

const normalizeString = (string) => {
  // console.log(string)
  // console.log(string.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
  if (!string?.normalize) {
    console.error('This browser doesnt support the string.normalize method.')
    alert(
      'Your browser is outdated, please update the browser or switch to a modern browser to use this site.'
    )
  } else {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }
}

export default normalizeString
