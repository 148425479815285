import languageParser from 'accept-language-parser'

import { LOAD_BROWSER_LANGUAGES } from './types'
import { api } from '../api'
import Settings from '../Settings'

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText)
    } else return response
}

export const loadBrowserLanguages = () => dispatch => {
    // console.log('loadBrowserLanguages')
    if (api.browserLanguages !== undefined) {
        fetch(api.browserLanguages)
            .then(handleErrors)
            .then(res => res.json())
            // .then(res => res.split(';').map(lang => lang.split(',')))
            .then(res => languageParser.pick(Settings.supportedLanguages, res))
            .then(res => {
                // console.log(res)
                return res
            })
            .then(res =>
                dispatch({
                    type: LOAD_BROWSER_LANGUAGES,
                    payload: res
                })
            )
            .catch(err => {
                console.error(
                    `There seems to be a problem with the Browser Languages api: ${err}`
                )
            })
    } else {
        console.log('Browser Lanugage api disabled')
    }
}

export const test = 'test'