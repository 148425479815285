/* eslint-disable */

import React from 'react'
import { Slider, Handles } from 'react-compound-slider'

const sliderStyle = {}

class Handle extends React.Component {
  constructor(props) {
    super(props)
    this.onTouchStart = this.onTouchStart.bind(this)
    this.onTouchEnd = this.onTouchEnd.bind(this)
    this.state = {
      touch: false,
    }
  }

  onTouchStart = () => {
    console.log('touchStart')
    this.setState({ touch: true })
  }

  onTouchEnd = () => {
    console.log('touchEnd')
    this.setState({ touch: false })
  }

  render() {
    // your handle component
    const {
      props: {
        handle: { id, value, percent },
        getHandleProps,
        dilutions,
        selectedScale,
      },
      state: { touch },
    } = this
    return (
      <div
        className="slider_dot"
        onTouchStart={this.onTouchStart}
        onTouchEnd={this.onTouchEnd}
        style={{
          left: `${percent}%`,
          position: 'absolute',
          // marginLeft: -15,
          // marginTop: 25,
          zIndex: 2,
          // width: 30,
          // height: 30,
          border: 0,
          textAlign: 'center',
          cursor: 'pointer',
          // borderRadius: '50%',
          // backgroundColor: '#2C4870',
          color: '#333',
          transition: dilutions.length < 10 ? 'left .1s ease' : '',
        }}
        {...getHandleProps(id)}
      >
        <div className="current_value">
          {dilutions[value]}
          {selectedScale}
        </div>
        <div
          className={`current_value_mobile ${touch ? 'touch' : ''}`}
          onTouchStart={this.onTouchStart}
          onTouchEnd={this.onTouchEnd}
        >
          {dilutions[value]}
          {selectedScale}
        </div>
        <div
          className="slider_handle_overlay"
          onTouchStart={this.onTouchStart}
          onTouchEnd={this.onTouchEnd}
        />
      </div>
    )
  }
}

export default class DilutionSlider extends React.Component {
  static propTypes = {}

  state = {
    currentValue: null,
  }

  constructor(props) {
    super(props)
    this.onChangeHandler = this.onChangeHandler.bind(this)
  }

  componentDidMount() {
    const { onChange, dilutions, selectedScale, selectedDilution } = this.props
    if (selectedDilution == null)
      onChange(
        dilutions[
          Math.round(dilutions.length !== 1 ? dilutions.length / 2 : 0, 10)
        ] + selectedScale
      )
  }

  static getDerivedStateFromProps(props) {
    if (props.selectedDilution != null) {
      const dilutionsStr = props.dilutions.map((entry) => {
        if (typeof entry === 'number') return entry.toString()

        return entry
      })
      return {
        currentValue: dilutionsStr.indexOf(
          props.selectedDilution.split(props.selectedScale)[0]
        ),
      }
    }
    return null
  }

  componentDidUpdate() {
    // console.log(this.props)
  }

  onChangeHandler(e) {
    const { onChange, dilutions, selectedScale } = this.props
    // console.log(dilutions[e[0]] + selectedScale)
    onChange(dilutions[e[0]] + selectedScale)
  }

  render() {
    const { dilutions, selectedScale, keyId } = this.props
    const { currentValue } = this.state
    if (dilutions.length > 1) {
      return (
        <div className="dilution_slider_holder">
          <span className="min">
            {dilutions[0]}
            {selectedScale}
          </span>
          <span className="max">
            {dilutions[dilutions.length - 1]}
            {selectedScale}
          </span>
          <Slider
            className="dilution_slider"
            rootStyle={sliderStyle}
            domain={[0, dilutions.length - 1]}
            step={1}
            mode={1}
            values={[currentValue]}
            onChange={this.onChangeHandler}
            key={`${keyId}_slider`}
          >
            <div className="slider_line" />
            <Handles key={`${keyId}_slider_handles`}>
              {({ handles, getHandleProps }) => (
                <div className="slider-handles">
                  {handles.map((handle) => (
                    <Handle
                      key={`${keyId}_slider_handle_${handle.id}`}
                      handle={handle}
                      getHandleProps={getHandleProps}
                      dilutions={dilutions}
                      selectedScale={selectedScale}
                    />
                  ))}
                </div>
              )}
            </Handles>
          </Slider>
        </div>
      )
    }
    if (dilutions.length > 0)
      return (
        <div className="dilution_slider_holder">
          <span className="min only">
            {dilutions[0]}
            {selectedScale}
          </span>
        </div>
      )

    return <p>not available</p>
  }
}
// <div className="dilution_slider">
// <div className="slider_line" />
// <div className="slider_dot">
// <span className="current_value">10DH</span>
// </div>
// </div>
