import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import SingleResourcesPage from './SingleResourcesPage'
import _t from '../../utils/translate'
import Maps from "../Maps";
import Reveal from 'react-reveal/Reveal';
import shortid from 'shortid';


const ResourcesPage = ({language}) => {
  return (
    <React.Fragment>
      <Route
        path={`/${language}/${_t('resources', 'routes')}`}
        exact
        component={() => <Redirect to={`/${language}/${_t('resources', 'routes')}/${_t('where', 'routes')}`}/>}
      />
      <Route
        path={`/${language}/${_t('resources', 'routes')}/${_t('where', 'routes')}`}
        exact
        component={() => (
          <Reveal effect="custom_fade_in" key={shortid.generate()}>
            <div className="page_content padding_top_140">
              <h1>{_t('Where to find our remedies')}</h1>
              <Maps/>
            </div>
          </Reveal>
        )}
      />
      <Route
        path={`/${language}/${_t('resources', 'routes')}/:topic`}
        component={SingleResourcesPage}
      />
    </React.Fragment>
  )
}

export default ResourcesPage
