import _t from './translate'

const Greeting = () => {
    const myDate = new Date()
    const hrs = myDate.getHours()

    const morning = _t('Good Morning')
    const afternoon = _t('Good Afternoon')
    const evening = _t('Good Evening')
    const night = _t('Good Night')

    if (hrs >= 6 && hrs < 12) return morning
    if (hrs >= 12 && hrs < 17) return afternoon
    if (hrs >= 17 && hrs < 21) return evening

    return night
}
export default Greeting