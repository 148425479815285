import { LOAD_BROWSER_LANGUAGES } from '../actions/types'

const initialState = {
	loading: true
}

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_BROWSER_LANGUAGES:
			return {
				...state,
				loading: false,
				activeLanguage: action.payload
			}
		default:
			return state
	}
}
