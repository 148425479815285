import _ from 'lodash'
// import axios from 'axios'
import shortHash from 'short-hash'
import { store } from '../store/store'
import translations from './translations'

import Settings from '../Settings'

const LOG_ON_SERVER = Settings.LogTranslations

const alreadySent = []

const sendToServer = (input, namespace, language) => {
  const uid = shortHash(`${input}---${namespace}---${language}`)
  if (!alreadySent.includes(uid)) {
    // console.log(`${input}, ${namespace}, ${language}`)
    alreadySent.push(uid)
    // console.log(alreadySent)
    fetch(
      `https://api.one-squared.com/sn/translations/public/_/items/sn_translation_log_${language}`,
      {
        method: 'POST', // or 'PUT'
        body: JSON.stringify({
          string: input,
          namespace,
          uid,
        }), // data can be `string` or {object}!
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  }
}

const translate = (
  input,
  namespace = 'general',
  replace = {},
  languageIn = store.getState().ui.language
) => {
  var language = 'fr'

  if (languageIn !== null) language = languageIn

  // let searchIn
  // if (namespace) searchIn = translations[language][namespace]
  const searchIn = translations[language][namespace]

  if (input === null) {
    return null
  }

  const find = _.find(searchIn, (x) => x[0] === input)
  let translated = input
  if (find) {
    translated = find[1]
  }

  if (!find && language !== 'en') {
    if (LOG_ON_SERVER) sendToServer(input, namespace, language)
    // console.log({ input, namespace, language })
  }

  if (replace !== {}) {
    // console.log(Object.keys(replace).length)
  }
  if (Object.keys(replace).length > 0) {
    // console.log(`input: ${input}`)
    // console.log(`find: ${find}`)
    for (var key in replace) {
      // console.log(new RegExp("\\{" + key + "\\}", "gi"))
      // console.log(translated.replace(new RegExp("\\{" + key + "\\}", "gi"), replace[key]))

      translated = translated.replace(
        new RegExp('\\{' + key + '\\}', 'gi'),
        replace[key]
      )
    }
  }

  return translated
}

export default translate
