import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter, Route, Switch } from 'react-router-dom'

import Spinner from '../Spinner'
import TitleWithIcon from './TitleWithIcon'
import urlFormat from '../../utils/urlFormat'
import _t from '../../utils/translate'
import formatDate from '../../utils/formatDate'
import BackLink from '../BackLink'
import NotFound from '../NotFound'
import Settings from '../../Settings'

class NomenclatureDownload extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			page: 1
		}
	}

	render() {
		const {
			props: { apiElement }
		} = this

		return (
			<div className="nomenclature_download_block">
				<div className="nomenclature_download_wrapper">
					<div className="nomenclature_download">
						<a
							href={apiElement.download}
							className="image_link"
							target="_blank"
							rel="noopener noreferrer"
						>
							<div
								className="image"
								style={{
									backgroundImage: apiElement.image?.sizes
										?.large
										? `url(${apiElement.image.sizes.large})`
										: 'none'
								}}
							>
								<div className="green_overlay" />
							</div>
						</a>
						<div className="description">
							<h1>{apiElement.title}</h1>
							<div className="excerpt">
								<p>{apiElement.text}</p>
							</div>
							<div className="button_wrapper">
								<a
									href={apiElement.download}
									className="button primary"
									target="_blank"
									rel="noopener noreferrer"
								>
									{apiElement.download_text || 'download'}
								</a>
							</div>
						</div>
						<div className="clearfix" />
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps)(withRouter(NomenclatureDownload))
