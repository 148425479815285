import React from 'react'
import { withRouter } from 'react-router-dom'

import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import {
    login,
    loadUserData,
    completeResetPassword
} from '../actions/userActions'
import { showMessage } from '../actions/uiActions'
import { hidePopups } from '../actions/popupActions'

// import _t from '../utils/translate'
import CompleteResetPasswordForm from '../forms/CompleteResetPasswordForm'

class ResetPasswordPopup extends React.Component {
    constructor(props) {
        super(props)
        this.submit = this.submit.bind(this)
    }

    submit = data => {
        const { completeResetPassword } = this.props
        return completeResetPassword({ ...data })
    }

    closePopup = () => {
        this.props.hidePopups()
    }

    render() {
        const { visible } = this.props

        return (
            <div className={`popup reset_password ${visible ? 'visible' : ''}`}>
                <button
                    type="button"
                    className="close_popup "
                    onClick={this.closePopup}
                >
                    <div className="icon icon_close active" />
                </button>
                <CompleteResetPasswordForm submit={this.submit} />
            </div>
        )
    }
}
ResetPasswordPopup.defaultProps = {
    children: []
}

ResetPasswordPopup.propTypes = {
    children: PropTypes.array, // eslint-disable-line
    visible: PropTypes.bool.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
}

const mapStateToProps = state => ({
    visible: state.popup.completeResetPassword,
    token: state.popup.resetPasswordToken,
    language: state.ui.language
})

export default connect(mapStateToProps, {
    login,
    hidePopups,
    loadUserData,

    showMessage,
    completeResetPassword
})(withRouter(ResetPasswordPopup))
