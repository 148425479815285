import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'
// import ReactPlayer from 'react-player'
// import slider_dummy_image from ""
import { connect } from 'react-redux'

import Slide from './Slide'

class Slider extends React.Component {
  constructor(props) {
    super(props)
    this.nextSlide = this.nextSlide.bind(this)
    this.state = {
      activeSlide: 0,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ activeSlide: 1 })
    }, 100)
  }

  componentWillUnmount() {
    //TODO: Cancel setTimeout Subscription

    this.setState({ activeSlide: 0 })
  }

  nextSlide = () => {
    const { apiElement } = this.props
    this.setState((oldState) => {
      let newSlide = null
      newSlide =
        oldState.activeSlide + 1 > apiElement.slide.length
          ? 1
          : oldState.activeSlide + 1
      return { activeSlide: newSlide }
    })
  }

  render() {
    const { apiElement, pagesLoading } = this.props
    const { activeSlide } = this.state
    // console.log(apiElement)
    return (
      <div className="slider_wrapper">
        <div className={`slider active_slide_${activeSlide}`}>
          <div
            className="slider_page_1"
            style={{
              backgroundImage: `url(${null})`,
              backgroundSize: 'cover',
            }}
          >
            {apiElement.slide.map((slide, i) => (
              <Slide
                pagesLoading={pagesLoading}
                slide={slide}
                slideNr={i + 1}
                onNext={this.nextSlide}
                visible={i + 1 === activeSlide}
                key={`slide_${slide.image.id ||
                  slide.video.id ||
                  shortid.generate()}`}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
}

Slider.defaultProps = {
  imageUrl: '',
  caption: '',
}

Slider.propTypes = {
  imageUrl: PropTypes.string,
  caption: PropTypes.string,
}

const mapStateToProps = (state) => ({
  pagesLoading: state.pages.loading,
})

export default connect(mapStateToProps)(Slider)
