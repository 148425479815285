import React from 'react'
import _t from '../utils/translate'
import { LanguageNavLink } from '../utils/LanguageLink'
import SocialIcons from './SocialIcons'

const Footer = () => {
	return (
		<div className="footer_wrapper">
			<div className="footer">
				<SocialIcons />
				<span className="name">
					LABORATOIRE HOMEOPATHIQUE D. SCHMIDT-NAGEL SA
				</span>
				<div className="footer_menu">
					{/*<LanguageNavLink to={`/${_t('shop', 'routes')}`}>
						{_t('Shop')}
					</LanguageNavLink>*/}
					<LanguageNavLink to={`/${_t('company', 'routes')}`}>
						{_t('Company', 'menu')}
					</LanguageNavLink>
					<LanguageNavLink to={`/${_t('medicine', 'routes')}`}>
						{_t('Medicine', 'menu')}
					</LanguageNavLink>
					<LanguageNavLink to={`/${_t('health', 'routes')}`}>
						{_t('Health', 'menu')}
					</LanguageNavLink>
					<LanguageNavLink to={`/${_t('resources', 'routes')}`}>
						{_t('Resources', 'menu')}
					</LanguageNavLink>
					<LanguageNavLink to={`/${_t('imprint', 'routes')}`}>
						{_t('Imprint', 'menu')}
					</LanguageNavLink>
					<LanguageNavLink to={`/${_t('sitemap', 'routes')}`}>
						{_t('Sitemap', 'menu')}
					</LanguageNavLink>
				</div>
				<div className="logo" />
				<div className="switzerland" />
			</div>
		</div>
	)
}

export default Footer
