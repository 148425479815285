import React from 'react'
// import PropTypes from 'prop-types'
// import { Form, Button, Message } from 'semantic-ui-react'
import { connect } from 'react-redux'

// import InlineError from '../messages/InlineError'

import { updateUserData } from '../actions/userActions'
import AddressField from './AddressField'
// import PasswordField from './PasswordField'
// import { Link } from 'react-router-dom'
import _t from '../utils/translate'

import { showMessage } from '../actions/uiActions'

class AddressForm extends React.Component {
    state = {
        loading: false
    }

    constructor(props) {
        super(props)
        this.onSave = this.onSave.bind(this)
    }

    // componentDidMount() {
    //  const { userData } = this.props
    //  this.setState({
    //      data: {
    //          ...userData
    //      }
    //  })
    // }

    onSave(data) {
        // this.props.showLoading()
        const { updateUserData, showMessage } = this.props

        updateUserData(data)
            .then(res => {
                console.log(res)
            })
            .catch(err => showMessage(err.response.data.message, 'error'))
    }

    render() {
        const {
            onSave,
            props: { userData }
        } = this
        return (
            <div className="address_form account_form">
                <AddressField
                    field={{
                        name: 'Billing',
                        data: userData,
                        type: 'billing',
                        label: _t('Billing Address', 'account')
                    }}
                    onSave={onSave}
                />
                <AddressField
                    field={{
                        name: 'shipping',
                        data: userData,
                        type: 'shipping',
                        label: _t('Shipping Address', 'account')
                    }}
                    onSave={onSave}
                />

                <div className="clearfix" />
            </div>
        )
    }
}

AddressForm.propTypes = {
    // submit: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    userData: state.user
})

export default connect(mapStateToProps, { updateUserData, showMessage })(
    AddressForm
)
