import axios from 'axios'

import Settings from './Settings'
import { store } from './store/store'

const { apiBase, cachedApiBase, useCache, apiBaseDirectus } = Settings
const domain = Settings.apiDomain

const getAuthHeader = () => {
  if (store.getState().user.token) {
    return {
      Authorization: `Bearer ${store.getState().user.token}`,
    }
  }
  return {}
}

export const api = {
  // pages: `${apiBase}/wp/v2/pages?per_page=100`,

  pages: {
    getPages: () =>
      axios.get(`${apiBase}/wp/v2/pages?per_page=100`).then((res) => res),
    getPagesLang: (language) => {
      // Very weird hack to avoid cors problem with lang=en
      // Could have to do with redirection maybe?
      let langString = ''
      if (language !== 'en') {
        langString = `&lang=${language}`
      }

      const url = useCache
        ? `${cachedApiBase}?path=sn_pages_${language}&domain=${domain}`
        : `${apiBase}/wp/v2/pages?per_page=100${langString}`

      return axios.get(url).then((res) => res)
    },
  },

  posts: {
    getPostsLang: (language) => {
      // Very weird hack to avoid cors problem with lang=en
      // Could have to do with redirection maybe?
      let langString = ''
      if (language !== 'en') {
        langString = `&lang=${language}`
      }

      const url = useCache
        ? `${cachedApiBase}?path=sn_posts_${language}&domain=${domain}`
        : `${apiBase}/wp/v2/posts?per_page=100${langString}`

      return axios.get(url).then((res) => res)
    },
  },

  singleRemedies: {
    url: `${apiBaseDirectus}/items/single_remedies?limit=-1`,
    getSchussler: () =>
      axios
        .get(`${apiBaseDirectus}/items/schussler?limit=-1`)
        .then((res) => res),
    getGemmotherapy: () =>
      axios
        .get(`${apiBaseDirectus}/items/gemmotherapy?limit=-1`)
        .then((res) => res),
    getPhytotherapy: () =>
      axios
        .get(`${apiBaseDirectus}/items/phytotherapy?limit=-1`)
        .then((res) => res),
    getOligotherapy: () =>
      axios
        .get(`${apiBaseDirectus}/items/oligotherapy?limit=-1`)
        .then((res) => res),
  },

  browserLanguages: `${apiBase}/browser_lang/browser_lang`,
  userData: `${apiBase}/sn_api/user`,
  user: {
    login: (credentials) => {
      const formData = new FormData()
      formData.append('username', credentials.username)
      formData.append('password', credentials.password)

      return axios
        .post(`${apiBase}/jwt-auth/v1/token`, formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        .then((res) => res)
    },
    resetPassword: (data) => {
      const formData = new FormData()
      formData.append('email', data.username)

      return axios
        .post(`${apiBase}/sn_api/reset_password`, formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        .then((res) => res)
    },
    completeResetPassword: (data) => {
      const formData = new FormData()
      formData.append('token', data.token)
      formData.append('password', data.password)

      return axios
        .post(`${apiBase}/sn_api/complete_reset_password`, formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        .then((res) => res)
    },
    data: () =>
      axios
        .get(`${apiBase}/sn_api/user`, {
          headers: {
            ...getAuthHeader(),
          },
        })
        .then((res) => res),
    // updateUserData: newUserData =>
    // axios
    //  .post(`${apiBase}/sn_api/user`, encodeForm(newUserData), {
    //      headers: { 'Content-Type': 'multipart/form-data' }
    //  })
    //  .then(res => res)
    updateUserData: (newUserData) =>
      axios
        .post(`${apiBase}/sn_api/user`, newUserData, {
          headers: {
            ...getAuthHeader(),
          },
        })
        .then((res) => res),

    newUser: (newUserData) =>
      axios
        .post(`${apiBase}/sn_api/new_user`, newUserData, {})
        .then((res) => res),
    contactForm: (data) => {
      return axios.post(`${apiBase}/sn_api/contact_form`, data).then((res) => {
        // console.log(res)
        return res
      })
    },
    contestForm: (data) => {
      return axios.post(`${apiBase}/sn_api/contest_form`, data).then((res) => {
        // console.log(res)
        return res
      })
    },
    webinarForm: (data) => {
      return axios.post(`${apiBase}/sn_api/webinar_form`, data).then((res) => {
        // console.log(res)
        return res
      })
    },
    checkToken: () => {
      return axios
        .post(`${apiBase}/jwt-auth/v1/token/validate`, null, {
          headers: {
            ...getAuthHeader(),
          },
        })
        .then((res) => {
          console.log(['checkToken', res])
          return res
        })
    },
  },
  products: {
    products: `${apiBase}/wc_api_proxy/products/`,
    getProducts: () => {
      const url = useCache
        ? `${cachedApiBase}?path=products&domain=${domain}`
        : `${apiBase}/wc_api_proxy/products/`

      return axios.get(url).then((res) => {
        return res
      })
    },
    getAcf: () => {
      // console.log(`${apiBase}/wp/v2/product/`)
      // .get(`${apiBase}/wp/v2/product/?per_page=500`)
      // return axios.get(`${apiBase}/wc_api_proxy/acf/`).then(res => res)

      const url = useCache
        ? `${cachedApiBase}?path=products_acf&domain=${domain}`
        : `${apiBase}/wc_api_proxy/acf/`
      return axios.get(url).then((res) => res)
    },
  },

  options: {
    getOptionsLang: (language) => {
      const url = useCache
        ? `${cachedApiBase}?domain=${domain}&path=options_${language}`
        : `${apiBase}/acf/v3/options/options?lang=${language}`

      return axios.get(url).then((res) => res)
      // return (
      //     axios
      //         // .get(`${apiBase}/acf/v3/options/options?lang=${language}`)
      //         .get(
      //             `${cachedApiBase}?domain=rothcoaching&path=options_${language}`
      //         )
      //         .then(res => res)
      // )
    },
  },
}

export default api
