/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import rootReducer from '../reducers/rootReducer'

const persistConfig = {
	key: 'sn_v6',
	storage,
	// blacklist: ['products', 'pages']
	blacklist: [
		'products',
		'pages',
		'ui',
		'singleRemedies',
		'popup',
		'options',
		'posts'
	]
	// blacklist: []
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const middleware = applyMiddleware(thunk)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// export const store = createStore(rootReducer, composeEnhancers(middleware))

export const store = createStore(persistedReducer, composeEnhancers(middleware))
export const persistor = persistStore(store)
