import React from 'react'
import PropTypes from 'prop-types'

import Reveal from 'react-reveal/Reveal'
import shortid from 'shortid'

import Slider from './blocks/Slider'
import HeaderImage from './blocks/HeaderImage'
import Video from './blocks/Video'
import ThreeColumns from './blocks/ThreeColumns'
import Banner from './blocks/Banner'
import HorizontalMenu from './blocks/HorizontalMenu'
import EssentialsDisplay from './blocks/EssentialsDisplay'
import TitleWithIcon from './blocks/TitleWithIcon'
import TwoImages from './blocks/TwoImages'
import Quote from './blocks/Quote'
import Text from './blocks/Text'
import TeamMember from './blocks/TeamMember'
import LinkShop from './blocks/LinkShop'
import HealthTopicsDisplay from './blocks/HealthTopicsDisplay'
import HistoryDisplay from './blocks/HistoryDisplay'
import RemedyDisplay from './blocks/RemedyDisplay'
import ProductDisplay from './blocks/ProductDisplay'
import LandingLinkShop from './blocks/LandingLinkShop'
import SeasonalRemedyDisplay from './blocks/SeasonalRemedyDisplay'
import ContactForm from './blocks/ContactForm'
import ContestForm from './blocks/ContestForm'
import WebinarForm from './blocks/WebinarForm'
import PostDisplay from './blocks/PostDisplay'
import SocialFeed from './blocks/SocialFeed'
import ContactMap from './blocks/ContactMap'
import NomenclautureDownload from './blocks/NomenclautureDownload'
import WebinarBanner from './blocks/WebinarBanner'

const BlocksRenderer = ({ loading, apiContent }) => {
  return (
    <React.Fragment>
      {!loading &&
        'acf' in apiContent &&
        'content' in apiContent.acf &&
        apiContent.acf.content.length > 0 &&
        apiContent.acf.content.map((element, n) => {
          switch (element.acf_fc_layout) {
            case 'text':
              return (
                <Reveal effect="custom_fade_in" key={shortid.generate()}>
                  <Text apiElement={element} />
                </Reveal>
              )
            case 'slider':
              return (
                <Reveal effect="custom_fade_in" key={shortid.generate()}>
                  <Slider apiElement={element} />
                </Reveal>
              )
            case 'banner':
              return (
                <Reveal effect="custom_fade_in" key={shortid.generate()}>
                  <Banner
                    imageUrl={element.image.sizes.large}
                    caption={element.tag_line}
                    textColor={element.text_color}
                    apiElement={element}
                    callToActionButton={element.call_to_action_button}
                  />
                </Reveal>
              )
            case 'header_image':
              return (
                <Reveal effect="custom_fade_in" key={shortid.generate()}>
                  <HeaderImage apiElement={element} />
                </Reveal>
              )
            case 'video':
              return (
                <Reveal effect="custom_fade_in" key={shortid.generate()}>
                  <Video apiElement={element} />
                </Reveal>
              )
            case 'three_columns':
              return (
                <Reveal effect="custom_fade_in" key={shortid.generate()}>
                  <ThreeColumns apiElement={element} />
                </Reveal>
              )
            case 'horizontal_menu':
              return (
                <Reveal effect="custom_fade_in" key={shortid.generate()}>
                  <HorizontalMenu apiElement={element} />
                </Reveal>
              )
            case 'essentials_display':
              return (
                <Reveal effect="custom_fade_in" key={shortid.generate()}>
                  <React.Fragment>
                    <div className="desktop_only">
                      <EssentialsDisplay
                        apiElement={element}
                        nrItemsPerPage={6}
                      />
                    </div>
                    <div className="mobile_only">
                      <EssentialsDisplay
                        apiElement={element}
                        nrItemsPerPage={1}
                      />
                    </div>
                  </React.Fragment>
                </Reveal>
              )
            case 'title_with_icon':
              return (
                <Reveal effect="custom_fade_in" key={shortid.generate()}>
                  <TitleWithIcon apiElement={element} />
                </Reveal>
              )
            case 'two_images':
              return (
                <Reveal effect="custom_fade_in" key={shortid.generate()}>
                  <TwoImages apiElement={element} />
                </Reveal>
              )
            case 'quote':
              return (
                <Reveal effect="custom_fade_in" key={shortid.generate()}>
                  <Quote apiElement={element} />
                </Reveal>
              )
            case 'team_member':
              return (
                <Reveal effect="custom_fade_in" key={shortid.generate()}>
                  <TeamMember apiElement={element} />
                </Reveal>
              )
            case 'link_shop':
              return (
                <Reveal effect="custom_fade_in" key={shortid.generate()}>
                  <LinkShop apiElement={element} />
                </Reveal>
              )
            case 'health_topics_display':
              return (
                <Reveal effect="custom_fade_in" key={shortid.generate()}>
                  <HealthTopicsDisplay apiElement={element} />
                </Reveal>
              )
            case 'history_display':
              return <HistoryDisplay apiElement={element} />
            case 'remedy_display':
              return (
                <React.Fragment key={shortid.generate()}>
                  {
                    // <Reveal
                    // 	effect="custom_fade_in"
                    // 	key={shortid.generate()}
                    // >
                  }
                  <RemedyDisplay apiElement={element} />
                  {
                    // </Reveal>
                  }
                </React.Fragment>
              )
            case 'product_display':
              return (
                <Reveal effect="custom_fade_in" key={shortid.generate()}>
                  <ProductDisplay apiElement={element} />
                </Reveal>
              )
            case 'landing_link_shop':
              return (
                <Reveal effect="custom_fade_in" key={shortid.generate()}>
                  <LandingLinkShop apiElement={element} />
                </Reveal>
              )
            case 'seasonal_remedy_display':
              return (
                <Reveal effect="custom_fade_in" key={shortid.generate()}>
                  <SeasonalRemedyDisplay apiElement={element} />
                </Reveal>
              )
            case 'contact_form':
              return (
                <Reveal effect="custom_fade_in" key={shortid.generate()}>
                  <ContactForm apiElement={element} />
                </Reveal>
              )
            case 'contest_form':
              return (
                <Reveal effect="custom_fade_in" key={shortid.generate()}>
                  <ContestForm apiElement={element} />
                </Reveal>
              )
            case 'webinar_form':
              return (
                <Reveal effect="custom_fade_in" key={shortid.generate()}>
                  <WebinarForm apiElement={element} />
                </Reveal>
              )
            case 'post_display':
              return (
                <Reveal effect="custom_fade_in" key={shortid.generate()}>
                  <PostDisplay apiElement={element} />
                </Reveal>
              )
            case 'nomenclature_download':
              return (
                <Reveal effect="custom_fade_in" key={shortid.generate()}>
                  <NomenclautureDownload apiElement={element} />
                </Reveal>
              )
            case 'social_feed':
              return (
                <Reveal effect="custom_fade_in" key={shortid.generate()}>
                  <SocialFeed apiElement={element} />
                </Reveal>
              )
            case 'contact_map':
              return (
                <Reveal effect="custom_fade_in" key={shortid.generate()}>
                  <ContactMap apiElement={element} />
                </Reveal>
              )
            case 'webinar_banner':
              return <WebinarBanner apiElement={element} />
            default:
              return (
                <p
                  style={{
                    color: 'rgba(200,200,200,1)',
                    textAlign: 'center',
                  }}
                >
                  Block: '{element.acf_fc_layout}' not found!
                </p>
              )
          }
        })}
      {(!loading &&
        'acf' in apiContent &&
        'content' in apiContent.acf &&
        apiContent.acf.content.length > 0) || <h1>no content</h1>}
    </React.Fragment>
  )
}

BlocksRenderer.defaultProps = {
  apiContent: {},
}

BlocksRenderer.propTypes = {
  loading: PropTypes.bool.isRequired,
  apiContent: PropTypes.object, // eslint-disable-line
}

export default BlocksRenderer
