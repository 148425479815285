import axios from 'axios'
import LogRocket from 'logrocket'

import {
  USER_LOGGED_IN,
  USER_DATA_LOADED,
  USER_LOGGED_OUT,
  HIDE_POPUPS,
} from './types'
import api from '../api'
import setAuthorizationHeader from '../utils/setAuthorizationHeader'

import { showMessage } from './uiActions'
import _t from '../utils/translate'

export const userLoggedIn = (user) => ({
  type: USER_LOGGED_IN,
  payload: user,
})

export const userDataLoaded = (data) => ({
  type: USER_DATA_LOADED,
  data,
})

export const userLoggedOut = () => ({
  type: USER_LOGGED_OUT,
})

export const loadUserData = () => (dispatch) =>
  api.user
    .data()
    .then((res) => {
      // console.log(res)
      return res
    })
    .then((data) => {
      dispatch(userDataLoaded(data.data))
      // localStorage.snUser = JSON.stringify(data)
      dispatch({
        type: HIDE_POPUPS,
      })
      return data
    })

export const logUserOut = () => (dispatch) => {
  localStorage.removeItem('snJWT')
  localStorage.removeItem('snUser')
  dispatch(userLoggedOut())
}

export const login = (credentials) => (dispatch) =>
  api.user.login(credentials).then((user) => {
    // console.log(user.data)
    // localStorage.snJWT = user.data.token
    setAuthorizationHeader(user.data.token)
    dispatch(userLoggedIn(user.data))
    // loadUserData()
    // console.log('user')
    // console.log(user)
    if (!window.location.href.includes('localhost')) {
      LogRocket.identify(user?.data?.user_email)
    }
  })

export const resetPassword = (data) => (dispatch) =>
  api.user.resetPassword(data)

export const completeResetPassword = (data) => (dispatch) =>
  api.user.completeResetPassword(data)

export const loginx = (credentials) => (dispatch) => {
  // console.log(credentials)

  const body = {
    username: credentials.email,
    password: credentials.password,
  }

  axios
    .post('', body)
    .then((res) => res.data)
    .then((user) => {
      // localStorage.snJWT = user.token
      setAuthorizationHeader(user.token)

      dispatch({
        type: USER_LOGGED_IN,
        payload: user,
      })
      return user
    })
    .then((res) => {
      console.log(res)
      return res
    })
    .then(() => {
      axios.get(api.user_data).then((res) =>
        dispatch({
          type: USER_DATA_LOADED,
          payload: res.data,
        })
      )
    })
}

export const updateUserData = (newUserData) => (dispatch) => {
  // console.log(newUserData)
  // return null
  return api.user.updateUserData(newUserData).then((data) => {
    dispatch(userDataLoaded(data.data.data))
    return data
  })
}

export const createNewUser = (newUserData) => (dispatch) => {
  return api.user.newUser(newUserData).then((data) => {
    // dispatch(userDataLoaded(data.data.data))
    console.log(data)
    return data
  })
}

export const submitContactFormRequest = (data) => (dispatch) => {
  console.log('submitContactFormRequest')
  console.log(data)
  return api.user.contactForm(data).then((res) => {
    console.log(res)
    return res
  })
}

export const submitContestFormRequest = (data) => (dispatch) => {
  console.log('submitContestFormRequest')
  console.log(data)
  return api.user.contestForm(data).then((res) => {
    console.log(res)
    return res
  })
}
export const submitWebinarFormRequest = (data) => (dispatch) => {
  console.log('submitWebinarFormRequest')
  console.log(data)
  return api.user.webinarForm(data).then((res) => {
    console.log(res)
    return res
  })
}

export const checkToken = () => (dispatch) => {
  // console.log('checkToken')
  return api.user
    .checkToken()
    .then((res) => {
      // console.log(['checkedTokenSuccess', res])
      return res
    })
    .catch((err) => {
      // console.log(['Token not valid, logging user out.', err])
      dispatch(
        showMessage(
          _t('Your session expired, please log in again.', 'message'),
          'success'
        )
      )
      localStorage.removeItem('snJWT')
      localStorage.removeItem('snUser')

      dispatch(userLoggedOut())
      dispatch(userLoggedOut())
    })
}
