import React from 'react'
import { Link } from 'react-router-dom'

// import _t from '../../utils/translate'
import urlFormat from '../../utils/urlFormat'

const ComplexeSingle = ({ product, linkBase, style }) => {
  const imageUrl = product?.acf?.product_image?.sizes?.large

  return (
    <Link
      key={`OTC_${product.name.toLowerCase()}`}
      className="single_otc"
      to={`${linkBase}/${urlFormat(product.name)}`}
      style={style}
    >
      {/*<span className="no">{product.acf.potency}</span> */}
      {imageUrl && (
        <div
          className="image"
          style={{ backgroundImage: `url("${imageUrl}")` }}
        />
      )}
      {!imageUrl && <div className="image" />}
      <div className="text">
        <p className="title">{product.acf.title_fr}</p>
        <p className="potency">{product.acf.potency}</p>
      </div>
    </Link>
  )
}

export default ComplexeSingle
