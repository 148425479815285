import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

const LanguageLinkElement = ({ to, language, children, className }) => {
    return (
        <Link to={`/${language}${to}`} className={className} >{children}</Link>
    )
}

LanguageLinkElement.propTypes = {
    to: PropTypes.string.isRequired
}

const mapStateToPropsLink = (state) => ({
    language: state.ui.language
})

export const LanguageLink = connect(mapStateToPropsLink)(LanguageLinkElement);



const LanguageNavLinkElement = ({ to, language, children, className }) => {
    return (
        <NavLink to={`/${language}${to}`} className={className} >{children}</NavLink>
    )
}

LanguageNavLinkElement.propTypes = {
    to: PropTypes.string.isRequired
}

const mapStateToPropsNavLink = (state) => ({
    language: state.ui.language
})

export const LanguageNavLink = connect(mapStateToPropsNavLink)(LanguageNavLinkElement);