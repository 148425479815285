import {
	LOAD_SINGLE_REMEDIES,
	SCHUSSLER_LOADED,
	SET_LOADING,
	GEMMOTHERAPY_LOADED,
	PHYTOTHERAPY_LOADED,
	OLIGOTHERAPY_LOADED
} from '../actions/types'

const initialState = {
	loading: true,
	schusslerLoading: true,
	gemmotherapyLoading: true,
	phytotherapyLoading: true,
	oligotherapyLoading: true
}

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_SINGLE_REMEDIES:
			return {
				...state,
				loading: false,
				singleRemedies: { ...action.payload }
			}
		case SCHUSSLER_LOADED:
			return {
				...state,
				schusslerLoading: false,
				schussler: [...action.payload]
			}
		case GEMMOTHERAPY_LOADED:
			return {
				...state,
				gemmotherapyLoading: false,
				gemmotherapy: [...action.payload]
			}
		case PHYTOTHERAPY_LOADED:
			return {
				...state,
				phytotherapyLoading: false,
				phytotherapy: [...action.payload]
			}
		case OLIGOTHERAPY_LOADED:
			return {
				...state,
				oligotherapyLoading: false,
				oligotherapy: [...action.payload]
			}
		case SET_LOADING:
			return initialState
		default:
			return state
	}
}
