import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter, Route, Switch, Redirect } from 'react-router-dom'
import _ from 'lodash'
import { Helmet } from 'react-helmet'

import Spinner from '../Spinner'
import TitleWithIcon from './TitleWithIcon'
import urlFormat from '../../utils/urlFormat'
import _t from '../../utils/translate'
import formatDate from '../../utils/formatDate'
import BackLink from '../BackLink'
import NotFound from '../NotFound'
import Settings from '../../Settings'

class PostDisplay extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
    }
  }

  render() {
    const {
      // state: { page },
      props: { loading, posts, language, apiElement },
    } = this

    if (loading)
      return (
        <div className="post_display">
          <Spinner />
        </div>
      )
    const languagePosts = posts[language]
    if (apiElement.post_main_page)
      return (
        <div className="post_display">
          <Switch>
            <Route path={`/${language}/${_t('news', 'routes')}/`} exact>
              <TitleWithIcon
                apiElement={{
                  icon_name: 'document',
                  title: apiElement.title || 'News',
                }}
              />
              <div className="overview">
                {languagePosts.map((post) => (
                  <Link
                    to={`/${language}/${_t('news', 'routes')}/${urlFormat(
                      post.slug
                    )}`}
                    key={`post_overview_${post.id}`}
                  >
                    <div
                      className="image"
                      style={{
                        backgroundImage: post?.acf?.post_image?.sizes?.large
                          ? `url(${post.acf.post_image.sizes.large})`
                          : 'none',
                      }}
                    >
                      <div className="green_overlay" />{' '}
                    </div>
                    <div className="description">
                      <h1
                        dangerouslySetInnerHTML={{
                          __html: post.title.rendered || null,
                        }}
                      />
                      <span className="date">{formatDate(post.date)}</span>

                      <div className="excerpt">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: post.excerpt.rendered
                              .slice(3, -4)
                              .replace(`[&hellip;]`, '')
                              .replace(`<p>`, '')
                              .replace(`</p>`, '')
                              .substr(0, 200)
                              .replace(/\s\S*$/gi, '...'),
                          }}
                        />
                      </div>
                      <div className="center">
                        <button
                          type="button"
                          className="button mobile_only"
                          onClick={() => {}}
                        >
                          {_t(Settings.readMore)}
                        </button>
                      </div>
                    </div>
                    <div className="clearfix" />
                  </Link>
                ))}
              </div>
            </Route>
            {languagePosts.map((post) => {
              let seoDescription = null

              // if (post?.acf?.seo_description) {
              // seoDescription = post.acf.seo_description
              // }

              // If loading, show a standard title
              let metaTitle = 'Laboratoire homéopathique Schmidt-Nagel'

              // If loading is finished and the page Title is found, use it in the title
              if (!loading && post?.title?.rendered) {
                metaTitle = `${post?.title?.rendered} - Laboratoire homéopathique Schmidt-Nagel`
              }

              // If there was a title set in yoast, use this title instead
              if (!loading && post?.yoast_meta) {
                const yoastMeta = _.find(
                  post?.yoast_meta,
                  (property) => property.property === 'og:title'
                )
                if (yoastMeta?.content) {
                  metaTitle = yoastMeta.content
                }
              }

              if (!loading && post?.yoast_meta) {
                // console.log('post?.yoast_meta')
                // console.log(post?.yoast_meta)
                const yoastMetaDescription = _.find(
                  post?.yoast_meta,
                  (property) => property?.name === 'description'
                )
                // console.log(yoastMetaDescription)
                if (yoastMetaDescription?.content) {
                  seoDescription = yoastMetaDescription.content
                }
              }
              return (
                <Route
                  path={`/${language}/${_t('news', 'routes')}/${urlFormat(
                    post.slug
                  )}`}
                  key={`post_single_${post.id}`}
                >
                  <Helmet>
                    <title>{metaTitle}</title>
                    {seoDescription && (
                      <meta name="description" content={seoDescription} />
                    )}
                  </Helmet>
                  <div className="single">
                    {post?.acf?.post_image && (
                      <div
                        className="header_image "
                        style={{
                          backgroundImage: `url(${post?.acf?.post_image?.sizes?.large})`,
                          backgroundPosition: `${post?.acf
                            ?.post_image_position_horizontal || 'center'} ${post
                            ?.acf?.post_image_position_vertical || 'center'}`,
                        }}
                      />
                    )}
                    <BackLink to={`/${language}/${_t('news', 'routes')}`}>
                      {_t('Back to News')}
                    </BackLink>
                    <div className="post_wrapper">
                      <span className="date">{formatDate(post.date)}</span>
                      <h1
                        dangerouslySetInnerHTML={{
                          __html: post.title.rendered || null,
                        }}
                      />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: post.content.rendered || null,
                        }}
                      />
                      <div className="center">
                        <Link
                          className="button primary"
                          to={`/${language}/${_t('news', 'routes')}`}
                        >
                          {_t('Back to News')}
                        </Link>
                      </div>
                    </div>
                  </div>
                </Route>
              )
            })}
            <Route>
              <Redirect to={`/${language}/${_t('news', 'routes')}`} />
            </Route>
          </Switch>
        </div>
      )
    // means that it is not tne main page
    // const postsToDisplay =

    return (
      <div className="post_block">
        <div className="posts_wrapper">
          {languagePosts.map((post, x) => {
            if (x < apiElement.nr_posts)
              return (
                <div className="single_post" key={`post_single_2_${post.id}`}>
                  <Link
                    className="image_link"
                    to={`${apiElement.post_base_url}${urlFormat(post.slug)}`}
                  >
                    <div
                      className="image"
                      style={{
                        backgroundImage: post?.acf?.post_image?.sizes?.large
                          ? `url(${post.acf.post_image.sizes.large})`
                          : 'none',
                      }}
                    >
                      <div className="green_overlay" />
                    </div>
                  </Link>
                  <div className="description">
                    <Link
                      to={`${apiElement.post_base_url}${urlFormat(post.slug)}`}
                    >
                      <h1
                        dangerouslySetInnerHTML={{
                          __html: post.title.rendered || null,
                        }}
                      />
                      <span className="date">{formatDate(post.date)}</span>
                    </Link>
                    <div className="excerpt">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: post.excerpt.rendered
                            .slice(3, -4)
                            .replace(`[&hellip;]`, '')
                            .replace(`<p>`, '')
                            .replace(`</p>`, '')
                            .substr(0, 200)
                            .replace(/\s\S*$/gi, '...'),
                        }}
                      />
                    </div>
                    <div className="button_wrapper">
                      <Link
                        className="button primary"
                        to={`${apiElement.post_base_url}${urlFormat(
                          post.slug
                        )}`}
                      >
                        {apiElement?.text_read_more !== ''
                          ? apiElement?.text_read_more
                          : 'Read more'}
                      </Link>

                      <Link
                        className="button show_all "
                        to={`${apiElement.post_base_url}`}
                      >
                        {apiElement?.text_show_all_news !== ''
                          ? apiElement?.text_show_all_news
                          : 'Show all News'}
                      </Link>
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>
              )
            return null
          })}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  posts: state.posts.data,
  loading: state.posts.loading,
  language: state.ui.language,
})

export default connect(mapStateToProps)(withRouter(PostDisplay))
