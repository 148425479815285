import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import '../css/maps.scss';
import _t from '../utils/translate';
import Spinner from "./Spinner";
import { useMarkerData } from '../context/MarkerDataContext';
import iconTarget from '../img/icons/icon_target.svg';

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const createClusterCustomIcon = (cluster) => {
  return L.divIcon({
    html: `<div style="background-color: #2A93EE; color: white; border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center;">
            ${cluster.getChildCount()}
          </div>`,
    className: 'custom-cluster-icon',
    iconSize: L.point(30, 30, true),
  });
};

const MapStateHandler = ({ setMapState }) => {
  useMapEvents({
    moveend: (event) => {
      const map = event.target;
      const newMapState = {
        center: map.getCenter(),
        zoom: map.getZoom(),
      };
      setMapState(newMapState);
    },
  });
  return null;
};

const Maps = () => {
  const { locations, loading } = useMarkerData();
  const mapRef = useRef();
  const initialMapState = {
    center: [46.8182, 8.2275],
    zoom: 8,
  };

  const savedFilter = localStorage.getItem('selectedResourcesFilter') || 'Pharmacy';
  const [filter, setFilter] = useState(savedFilter);
  const [mapState, setMapState] = useState(initialMapState);

  const filterOptions = [
    'Pharmacy',
    'Drugstore',
    'Doctor',
    'Homeopath',
    'Naturopath',
    'Dentist',
    'Midwife',
    'Veterinarian',
    'Other therapist'
  ];

  useEffect(() => {
    localStorage.setItem('selectedResourcesFilter', filter);
  }, [filter]);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const handleRecentering = () => {
    const map = mapRef.current;
    if (map) {
      map.setView(initialMapState.center, initialMapState.zoom);
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-title">{_t('Loading maps')}</div>
        <Spinner />
      </div>
    );
  }

  const filteredLocations = locations.filter(location => {
    return new RegExp(_t(filter), 'i').test(location.category);
  });

  return (
    <div className="maps-container">
      <div className="map-toggle">
        {filterOptions.map(option => (
          <button
            key={option}
            className={`toggle-button ${filter === option ? 'active' : ''}`}
            onClick={() => handleFilterChange(option)}
          >
            {_t(option)}
          </button>
        ))}
      </div>
      <div className="map-wrapper">
        <MapContainer
          key={filter}
          center={mapState.center}
          zoom={mapState.zoom}
          style={{ height: '100%', width: '100%' }}
          minZoom={4}
          whenCreated={mapInstance => { mapRef.current = mapInstance }}
        >
          <MapStateHandler setMapState={setMapState} mapState={mapState} filter={filter} />
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerClusterGroup
            iconCreateFunction={createClusterCustomIcon}
          >
            {filteredLocations.map(location => (
              <Marker
                key={location.id}
                position={[location.latitude, location.longitude]}
                icon={DefaultIcon}
                eventHandlers={{
                  click: () => {
                    setMapState(prevState => ({
                      ...prevState,
                      openPopup: location.id,
                    }));
                  },
                }}
              >
                <Popup offset={[12, 5]} autoPan={false} open={mapState.openPopup === location.id}>
                  <strong>{location.name}</strong><br />
                  {location.address}<br />
                </Popup>
              </Marker>
            ))}
          </MarkerClusterGroup>
          <div className="recenter-button" onClick={handleRecentering}>
            <img src={iconTarget} alt="Recenter" />
          </div>
        </MapContainer>
      </div>
    </div>
  );
};

export default Maps;
