import React from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'

import KitsSlider from './KitsSlider'
import SchusslerDisplay from './SchusslerDisplay'
import FoodSupplementsDisplay from './FoodSupplementsDisplay'
import ComplexesDisplay from './ComplexesDisplay'
import ComplexesSlider from './ComplexesSlider'
import OtcSlider from './OtcSlider'
import OtcDisplay from './OtcDisplay'
import Spinner from '../Spinner'

const ProductDisplay = ({ apiElement, products }) => {
	let filteredProducts = []

	if (!products.loading)
		filteredProducts = Object.values(products.products).filter(element => {
			return _.find(element.categories, cat => cat.slug === 'kits')
		})

	if (products.loading)
		return (
			<div className="product_display_wrapper">
				<div className="product_display" style={{}}>
					<Spinner size="medium" position="relative" />
				</div>
			</div>
		)

	return (
		<div className="product_display_wrapper">
			<div className="product_display">
				{apiElement.type === 'kits' && (
					<React.Fragment>
						<div className="description">
							<div
								className={`icon icon_${apiElement.icon} active`}
							/>
							<h1>{apiElement.title}</h1>
							{apiElement.text !== '' && (
								<p className="text">{apiElement.text}</p>
							)}
						</div>
						<KitsSlider
							products={filteredProducts}
							apiElement={apiElement}
							loading={products.loading}
						/>
					</React.Fragment>
				)}

				{!products.loading && apiElement.type === 'schussler_salts' && (
					<SchusslerDisplay apiElement={apiElement} />
				)}
				{apiElement.type === 'food_supplements' && (
					<FoodSupplementsDisplay apiElement={apiElement} />
				)}
				{!products.loading && apiElement.type === 'complexes' && (
					<ComplexesDisplay apiElement={apiElement} />
				)}
				{apiElement.type === 'complexes_slider' && (
					<React.Fragment>
						<div className="desktop_only">
							<ComplexesSlider
								apiElement={apiElement}
								loading={products.loading}
								nrItemsPerPage={4}
							/>
						</div>
						<div className="mobile_only">
							<ComplexesSlider
								apiElement={apiElement}
								loading={products.loading}
								nrItemsPerPage={1}
							/>
						</div>
					</React.Fragment>
				)}
				{apiElement.type === 'otc' && (
					<React.Fragment>
						<div className="desktop_only">
							<OtcDisplay
								apiElement={apiElement}
								loading={products.loading}
								nrItemsPerPage={3}
							/>
						</div>
						<div className="mobile_only">
							<OtcDisplay
								apiElement={apiElement}
								loading={products.loading}
								nrItemsPerPage={1}
							/>
						</div>
					</React.Fragment>
				)}
				{apiElement.type === 'otc_slider' && (
					<React.Fragment>
						<div className="desktop_only">
							<OtcSlider
								apiElement={apiElement}
								loading={products.loading}
								nrItemsPerPage={4}
							/>
						</div>
						<div className="mobile_only">
							<OtcSlider
								apiElement={apiElement}
								loading={products.loading}
								nrItemsPerPage={1}
								className="mobile_only"
							/>
						</div>
					</React.Fragment>
				)}
			</div>
		</div>
	)
}
ProductDisplay.propTypes = {}

const mapStateToProps = state => ({
	products: state.products
})

export default connect(mapStateToProps)(ProductDisplay)
