import React from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'

// import Slider from '../Slider'
import SinglePageRenderer from '../SinglePageRenderer'
// import SeasonalHomeopathy from '../SeasonalHomeopathy'

const LandingPage = ({ loading }) => {
	return (
		<div>
			{!loading && (
				<SinglePageRenderer paddingTop={70} pageId="landing" />
			)}
		</div>
	)
}

LandingPage.defaultProps = {
	apiContent: { acf: {} }
}

LandingPage.propTypes = {
	loading: PropTypes.bool.isRequired,
	apiContent: PropTypes.shape({
		acf: PropTypes.object.isRequired
	})
}

const mapStatetoProps = state => ({
	loading: state.pages.loading,
	apiContent: state.pages.landing
})

export default connect(mapStatetoProps)(LandingPage)
