import React from 'react'

const Spinner = ({ size = 'medium', visible = true }) => {
    let pixels = 0
    switch (size) {
        case 'small':
            pixels = 64
            break
        case 'medium':
            pixels = 128
            break
        case 'large':
            pixels = 256
            break
        default:
            pixels = 128
            break
    }
    return (
        <div className={`spinner ${!visible ? 'hidden' : ''}`}>
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                width="128px"
                height="128px"
                viewBox="0 0 128 128"
                style={{ width: pixels, height: pixels }}
            >
                <g>
                    <path
                        className="st0"
                        d="M32.4,53.8c0-1.5,1.4-2.6,3.7-2.6h17.6v-15H32.3c-9.5,0-19.5,5.3-19.4,15.9c0.2,16.6,22,16.6,22,21.9
                c0,1.5-1.1,2.5-3.5,2.5H12.2v15.6h22.3c13.1,0,20.8-6.2,20.8-17.6C55.3,59.4,32.4,58.3,32.4,53.8z"
                    />
                    <path
                        className="st0"
                        d="M91.6,35.9c-15.7,0-24.2,10.6-24.2,24.8v31.3h17.8v-29c0-4.7,2.4-8.3,6.4-8.3c3.9,0,6.4,3.6,6.4,8.3v29h17.8
                V60.7C115.8,46.5,107.3,35.9,91.6,35.9z"
                    />
                </g>
            </svg>
        </div>
    )
}

export default Spinner
