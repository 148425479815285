import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import shortid from 'shortid'
import _ from 'lodash'

import ComplexeSingle from './ComplexeSingle'

const ComplexesDisplay = ({ apiElement, products, match }) => {
	// const sortedSchussler = _.sortBy(schusslerSalts, 'srt')
	const linkBase = match.path.split(':')[0]
	const filteredProducts = Object.values(products.products).filter(
		element => {
			return _.find(element.categories, cat => cat.slug === 'complexe')
		}
	)
	const sortedProducts = _.orderBy(filteredProducts, e => parseInt(e.acf.nr))
	return (
		<div className="complexe_holder">
			{!products.loading &&
				sortedProducts.map(product => (
					<ComplexeSingle
						linkBase={linkBase}
						key={shortid.generate()}
						product={product}
					/>
				))}
			<div className="clearfix" />
		</div>
	)
}
//

const mapStateToProps = state => ({
	products: state.products
})

export default connect(mapStateToProps)(withRouter(ComplexesDisplay))
