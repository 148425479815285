import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _t from '../utils/translate'
import Spinner from './Spinner'
import { emptyCart } from '../actions/cartActions'

class IFrame extends React.Component {
  constructor(props) {
    super(props)
    this.state = { height: 1000, status: 'loading' }
    this.onReceiveMessage = this.onReceiveMessage.bind(this)
  }

  componentDidMount() {
    window.addEventListener('message', this.onReceiveMessage)
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onReceiveMessage)
  }

  onReceiveMessage = (event) => {
    let { data } = event
    // this.setState({ status: 'checkout' })
    switch (data.name) {
      case 'checkout_loaded':
        // console.info('onReceiveMessage', event)
        // do something
        // console.log('checkout_loaded')
        this.props.onLoaded()
        this.setState({ status: 'checkout' })
        break
      case 'height':
        console.log('height: ' + data.height)
        this.setState({ height: data.height })
        break
      case 'thankyou_loaded':
        console.log('thankyou_loaded')
        this.props.emptyCart()
        window.scrollTo(0, 0)
        this.setState({ status: 'thankyou' })
        break
      default:
        break
    }
  }

  render() {
    const {
      state: { height, status },
      props: { name, language, options, optionsLoading },
    } = this
    const thankYouHtml = options?.[language]?.general?.thankyou_text

    return (
      <div className="iframe_holder">
        {status !== 'thankyou' && (
          <iframe
            className={name}
            title={name}
            name={name}
            height={`${height}px`}
            scrolling="no"
          />
        )}
        {status === 'thankyou' && !optionsLoading && (
          <React.Fragment>
            <div
              className="thankyou"
              dangerouslySetInnerHTML={{
                __html: thankYouHtml || null,
              }}
            />
            <Link className="button primary" to={`/${language}/`}>
              {_t('Back')}
            </Link>
          </React.Fragment>
        )}
        <div className={`overlay ${status === 'loading' ? 'visible' : ''}`} />
        <Spinner visible={status === 'loading'} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  optionsLoading: state.options.loading,
  options: state.options.data,
  language: state.ui.language,
})

IFrame.defaultProps = {
  onLoaded: () => {},
}

export default connect(mapStateToProps, { emptyCart })(IFrame)
