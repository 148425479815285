import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import $ from 'jquery'

import formatPrice from '../utils/formatPrice'
import { hideCart, removeFromCart } from '../actions/cartActions'
import { allowCheckout } from '../actions/uiActions'
import _t from '../utils/translate'
import Settings from '../Settings'
const { costShipping } = Settings

function roundTo(n, digits) {
  var negative = false
  if (digits === undefined) {
    digits = 0
  }
  if (n < 0) {
    negative = true
    n = n * -1
  }
  var multiplicator = Math.pow(10, digits)
  n = parseFloat((n * multiplicator).toFixed(11))
  n = (Math.round(n) / multiplicator).toFixed(2)
  if (negative) {
    n = (n * -1).toFixed(2)
  }
  return +n
}

class CartButton extends React.Component {
  constructor(props) {
    super(props)
    this.hideCartHandler = this.hideCartHandler.bind(this)
    // this.goToCheckout = this.goToCheckout.bind(this)
    this.getItems = this.getItems.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.formRef = React.createRef()
  }

  componentDidMount() {
    $('.cart_items').scrollGuard()
  }

  getItems() {
    // const items = this.props.cart;
    const { cartItems } = this.props
    // const items = {}

    return JSON.stringify({
      items: cartItems.map((item) => ({
        id: item.WcId,
        quantity: item.quantity,
        name: item.name,
        info: item.info,
      })),
    })
  }

  removeItem = (id) => {
    // console.log('Remove Item:')
    // console.log(id)
    const { removeFromCart: _removeFromCart } = this.props
    _removeFromCart(id)
  }

  hideCartHandler = () => {
    this.props.hideCart() //eslint-disable-line
  }

  // goToCheckout = () => {
  // 	const { cartItems } = this.props
  // 	console.log(cartItems)
  // }

  submitForm = (e) => {
    e.preventDefault()
    const { history, language, allowCheckout } = this.props

    allowCheckout()
    history.push(`/${language}/checkout`)
    console.log(this.formRef)
    // this.formRef.submit()
    // this.formRef.dispatchEvent(new Event('submit'))
    this.hideCartHandler()
    // document.checkout_button.submit()
    setTimeout(() => document.checkout_button.submit(), 200)

    console.log('checkout')
  }

  render() {
    const {
      displayCart,
      cartItems,
      JWT,
      loggedIn,
      discount,
      language,
    } = this.props
    // const { goToCheckout } = this

    if (!loggedIn) {
      return null
    }

    let cartTotal = 0
    cartItems.map((element) => {
      cartTotal += element.price * element.quantity
      return null
    })

    // const discountSum = Math.round((cartTotal * ((discount) / 100) + Number.EPSILON) * 100) / 100
    // const discountSum = Math.round(cartTotal * 100 * (discount / 100)) / 100
    // const cartSum = cartTotal - discountSum
    // let discountSum = roundTo(cartTotal * (discount / 100), 2)
    const discountSum = +(cartTotal * (discount / 100)).toFixed(2)
    // let discountSum = cartTotal * (discount / 100)
    // discountSum = +discountSum
    const cartSum = cartTotal - discountSum

    // const calculatedShippingCost = cartTotal > 30 ? 0 : costShipping
    const calculatedShippingCost = costShipping

    let nrCartItems = 0
    cartItems.map((element) => {
      nrCartItems += element.quantity
      return null
    })
    return (
      <div className={`cart ${!displayCart ? 'hidden' : ''}`}>
        <h2 style={{ color: 'white' }}>{_t('Cart', 'shop')}</h2>
        {cartItems.length < 1 ? (
          <p className="empty">{_t('Your cart is empty.', 'cart')}</p>
        ) : (
          undefined
        )}
        <div className="cart_items">
          {cartItems.map((element) => {
            return (
              <div className="item" key={element.cartId}>
                <div
                  className={`product_icon icon icon_${element.icon} white`}
                />
                <div className="text">
                  <span className="name">{element.name}</span>
                  <span className="info">
                    {_t(element.info.form, 'shop')} {element.info.size}
                    {element.info.remedies &&
                      `${element.info.remedies} Remedies`}
                  </span>
                  <div className="right">
                    <span className="quantity">{element.quantity}x</span>
                    <span className="price">{formatPrice(element.price)}</span>
                  </div>
                  <button
                    className="remove"
                    type="button"
                    onClick={this.removeItem.bind(this, element.cartId)}
                  >
                    <div className="icon icon_close white" />
                  </button>
                </div>
              </div>
            )
          })}
        </div>
        <div className="total">
          <div className="line">
            <span className="total_items">
              {nrCartItems !== 1
                ? _t('Total: {nr} items', 'shop', {
                    nr: nrCartItems,
                  })
                : _t('Total: {nr} item', 'shop', {
                    nr: nrCartItems,
                  })}
            </span>
            <span className="total_sum">{formatPrice(cartTotal)}</span>
          </div>
          {discount !== undefined && discount !== '0' && (
            <React.Fragment>
              <div className="line">
                <span className="discount_text">
                  {_t('{percent}% Discount:', 'cart', {
                    percent: discount,
                  })}
                </span>
                <span className="discount_sum">
                  -{formatPrice(discountSum)}
                </span>
              </div>

              <div className="line">
                <span className="shipping">{_t('Shipping', 'shop')}</span>
                <span className="shipping_sum">
                  {formatPrice(calculatedShippingCost)}
                </span>
              </div>

              <div className="line">
                <span className="final_text">Total:</span>
                <span className="final_sum">
                  {formatPrice(cartSum + calculatedShippingCost)}
                </span>
              </div>
            </React.Fragment>
          )}
        </div>
        <form
          className="checkout_button"
          method="post"
          target="iframe_checkout"
          name="checkout_button"
          action={Settings.checkoutUrl}
          onSubmit={this.submitForm}
          ref={this.formRef}
        >
          <input type="hidden" name="items" value={this.getItems()} />
          <input type="hidden" name="jwt" value={JWT} />
          <input type="hidden" name="language" value={language} />
          <button
            type="button"
            disabled={cartItems.length < 1}
            onClick={this.submitForm}
          >
            <span>{_t('Checkout', 'shop')}</span>
            <div className="icon icon_forward white" />
          </button>
        </form>
        <button className="close" type="button" onClick={this.hideCartHandler}>
          <div className="icon icon_close white" />
        </button>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  displayCart: state.cart.displayCart,
  cartItems: state.cart.cartItems,
  JWT: state.user.token,
  loggedIn: state.user.loggedIn,
  discount: state.user.discount,
  language: state.ui.language,
})

export default connect(mapStateToProps, {
  hideCart,
  removeFromCart,
  allowCheckout,
})(withRouter(CartButton))
