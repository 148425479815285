import React from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import { Redirect } from 'react-router-dom'

// import Slider from '../Slider'
import SinglePageRenderer from '../SinglePageRenderer'
import LanguageRedirect from '../../LanguageRedirect'
// import SeasonalHomeopathy from '../SeasonalHomeopathy'

const NotFoundPage = ({ language }) => {
	console.error('page not found!')
	if (language !== '') return <Redirect to={`/${language}`} />
	return <LanguageRedirect />
}

NotFoundPage.defaultProps = {
	apiContent: { acf: {} }
}

NotFoundPage.propTypes = {
	loading: PropTypes.bool.isRequired,
	apiContent: PropTypes.shape({
		acf: PropTypes.object.isRequired
	})
}

const mapStatetoProps = state => ({
	loading: state.pages.loading,
	apiContent: state.pages.landing
})

export default connect(mapStatetoProps)(NotFoundPage)
