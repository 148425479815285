import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

class EssentialsDisplay extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			activePage: 1,
			nrPages: null
		}

		this.nextPage = this.nextPage.bind(this)
		this.lastPage = this.lastPage.bind(this)
	}

	componentDidMount() {
		const { apiElement, nrItemsPerPage } = this.props
		const essentials = apiElement.essentials_names.split('\r\n')
		const nrPages = Math.ceil(essentials.length / nrItemsPerPage)
		// console.log(`Nr of Pages: ${nrPages}`)
		this.setState({ nrPages })
	}

	lastPage() {
		const { activePage } = this.state
		if (activePage > 1)
			this.setState(oldState => ({ activePage: oldState.activePage - 1 }))
	}

	nextPage() {
		const { activePage, nrPages } = this.state
		if (activePage < nrPages)
			this.setState(oldState => ({ activePage: oldState.activePage + 1 }))
		// else this.setState({ activePage: 1 })
	}

	render() {
		const { apiElement, nrItemsPerPage } = this.props
		const { activePage, nrPages } = this.state
		const { lastPage, nextPage } = this
		const essentials = apiElement.essentials_names.split('\r\n')
		const nrItems = essentials.length
		const marginPercent = 0.8
		let offsetLeftPercent = (activePage - 1) * 100
		if (activePage === nrPages && nrItems % nrItemsPerPage > 0) {
			offsetLeftPercent =
				(activePage - 1) * 100 -
				100 /
					(nrItemsPerPage /
						(nrItemsPerPage - (nrItems % nrItemsPerPage)))
		}
		// console.log(essentials)
		return (
			<div className="essentials_display_wrapper">
				<div className="essentials_display">
					<div className="description">
						<div
							className={`icon icon_${apiElement.icon_name} active`}
						/>
						<h1>{apiElement.title}</h1>
						<p>{apiElement.description}</p>
					</div>
					<div className="slider_navigation_wrapper">
						<div className="slider_wrapper">
							<div
								className="slider"
								style={{
									left: `-${offsetLeftPercent}%`,
									width: `${(100 / nrItemsPerPage) *
										nrItems}%`
								}}
							>
								{essentials.map(element => {
									const parts = element.split(':')
									const name = parts[0]
									const link = parts[1]
									return (
										<Link
											className="single_remedy"
											key={name
												.split(' ')
												.join('_')
												.toLowerCase()}
											to={link || './'}
											style={{
												width: `${(100 / nrItems) *
													(1 -
														marginPercent * 0.2)}%`,
												margin: `0 ${((100 / nrItems) *
													marginPercent) /
													10}%`
											}}
										>
											<div className="spacer" />
											<div className="tube" />
											<p>{name}</p>
										</Link>
									)
								})}
								<div className="clearfix" />
							</div>
						</div>

						<button
							type="button"
							className={`navigation left ${activePage !== 1 &&
								'visible'}`}
							onClick={lastPage}
						>
							<div className="icon icon_back active" />
						</button>

						<button
							type="button"
							className={`navigation right ${activePage !==
								nrPages && 'visible'}`}
							onClick={nextPage}
						>
							<div className="icon icon_forward active" />
						</button>
					</div>
				</div>
			</div>
		)
	}
}

EssentialsDisplay.defaultProps = {
	apiElement: '',
	nrItemsPerPage: 6
}
EssentialsDisplay.propTypes = {
	apiElement: PropTypes.object, // eslint-disable-line
	nrItemsPerPage: PropTypes.number
}

export default EssentialsDisplay
