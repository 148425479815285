import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const LinkShop = ({ apiElement }) => {
	return (
		<div className="link_shop_wrapper">
			<div
				className="image"
				style={{
					backgroundImage: `url(${apiElement.image.sizes.large})`,
				}}
			/>
			<div className="content_box box">
				<div className={`icon icon_${apiElement.icon_name} active`} />
				<h2>{apiElement.title}</h2>
				<p>{apiElement.text}</p>
				<Link
					className="button"
					to={
						apiElement.button_link === ''
							? '#'
							: apiElement.button_link
					}
				>
					{apiElement.button_text}
				</Link>
			</div>
		</div>
	);
};

LinkShop.defaultProps = {
	apiElement: {},
};
LinkShop.propTypes = {
	apiElement: PropTypes.object, // eslint-disable-line
};

export default LinkShop;
