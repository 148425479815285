import React from 'react'
import { connect } from 'react-redux'
// import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import shortid from 'shortid'
import _ from 'lodash'
import Reveal from 'react-reveal/Reveal'

import { addToCart } from '../actions/cartActions'
import Footer from './Footer'
import Spinner from './Spinner'
import formatPrice from '../utils/formatPrice'
import _t from '../utils/translate'
import QuantityField from '../utils/QuantityField'

import SingleSchussler from './pages/SingleSchussler'
import SingleFoodSupplement from './pages/SingleFoodSupplement'
import SingleComplexe from './pages/SingleComplexe'
import SingleOtc from './pages/SingleOtc'
import Settings from '../Settings'

class SingleProductRenderer extends React.Component {
  constructor(props) {
    super(props)
    this.addToCartHandler = this.addToCartHandler.bind(this)
    this.changeQuantity = this.changeQuantity.bind(this)
    this.state = { product: null, quantity: 1 }
  }

  static getDerivedStateFromProps(nextProps) {
    const { products, match } = nextProps
    const { loading, products: productsArr } = products
    if (!loading) {
      const product = _.find(productsArr, { slug: match.params.product })
      return { product, productsArr }
    }
    return null
  }

  addToCartHandler = () => {
    const { product, quantity } = this.state
    const { addToCart: _addToCart } = this.props

    const newProduct = {
      WcId: product.id,
      price: parseFloat(product.price),
      name: product.name,
      icon: 'kit',
      info: { remedies: product.acf.number_remedies },
      quantity,
    }
    _addToCart(newProduct)
  }

  changeQuantity = (quantity) => this.setState({ quantity })

  render() {
    const { products, category, match, language } = this.props
    const { loading } = products
    const { product, quantity } = this.state
    const { changeQuantity } = this

    if (products.loading) {
      return <Spinner />
    }

    if (category === 'schussler_salts') return <SingleSchussler match={match} />
    if (category === 'food_supplements')
      return <SingleFoodSupplement match={match} />
    if (category === 'complexes') return <SingleComplexe match={match} />
    if (category === 'otcs') return <SingleOtc match={match} />

    if (!loading && product) {
      const productDescription = product.acf[`description_${language}`]
      const productTitle = product.acf[`title_${language}`]

      // const product = this.findProduct()
      console.log(product)
      // if (product) {
      return (
        <div className="page_content single_product padding_top_140">
          <Helmet>
            <title>{productTitle}</title>
          </Helmet>

          <Reveal
            effect="custom_fade_in"
            key={`__${productTitle}_header_image`}
          >
            <div
              className="header_image"
              style={{
                // eslint-disable-next-line
                backgroundImage: `url(${product.acf.hasOwnProperty(
                  'topic_image'
                ) &&
                  product.acf.topic_image.hasOwnProperty('sizes') &&
                  product.acf.topic_image.sizes.large})`,
              }}
            />
          </Reveal>

          <Reveal effect="custom_fade_in" key={`__${productTitle}_title`}>
            <div className="title_with_icon_wrapper">
              <div className="title_with_icon">
                <div className={`icon icon_${category} active`} />
                <h1>{productTitle}</h1>
              </div>
            </div>
          </Reveal>

          <Reveal
            effect="custom_fade_in"
            key={`__${productTitle}_product_info`}
          >
            <div className="single_product_wrapper">
              <div
                className="product_image"
                style={{
                  // eslint-disable-next-line
                  backgroundImage: `url(${product.acf.hasOwnProperty(
                    'product_image'
                  ) &&
                    product.acf.product_image.hasOwnProperty('sizes') &&
                    product.acf.product_image.sizes.large})`,
                }}
              />
              <div className="product_info">
                {Settings.descriptionAllowed && (
                  <div
                    className="product_description"
                    dangerouslySetInnerHTML={{
                      __html: productDescription || null,
                    }}
                  />
                )}

                <div className="info_box_holder">
                  <div className="info_box">
                    <h3>{_t('General information', 'shop')}</h3>
                    {product.acf.number_remedies && (
                      <p key={shortid.generate()}>
                        {product.acf.number_remedies} Remedies
                      </p>
                    )}
                    <div className="">
                      {product.acf.general_information &&
                        product.acf.general_information
                          .split('\n')
                          .map((i, key) => {
                            return <p key={shortid.generate()}>{i}</p>
                          })}
                    </div>
                  </div>

                  {product.acf.included_remedies && (
                    <div className="info_box two_column">
                      <h3>{_t('Remedies')}</h3>{' '}
                      <div className="">
                        {product.acf.included_remedies.map((element) => (
                          <p key={shortid.generate()}>{element.remedy}</p>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="clearfix" />
                </div>
                <div className="buy_section">
                  <span className="price">
                    {formatPrice(parseFloat(product.price))}
                  </span>
                  <QuantityField
                    onChange={changeQuantity}
                    quantity={quantity}
                  />
                  <button
                    type="button"
                    className="buy"
                    onClick={this.addToCartHandler}
                  >
                    {_t('Add to Cart', 'shop')}
                  </button>
                </div>
              </div>
              <div className="clearfix" />
            </div>
          </Reveal>
          <Footer />
        </div>
      )
      // }
    }
    return (
      <div
        className="page_content single_product"
        style={{
          paddingTop: '140px',
          position: 'relative',
          paddingBottom: '50px',
        }}
      >
        <p className="align_center">Loading Product Details...</p>
      </div>
    )
  }
}

SingleProductRenderer.defaultProps = {}

SingleProductRenderer.propTypes = {}

const mapStateToProps = (state) => ({
  products: state.products,
  language: state.ui.language,
})

export default connect(mapStateToProps, { addToCart })(SingleProductRenderer)
