import React from 'react'
import PropTypes from 'prop-types'
import { Textfit } from 'react-textfit'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { MobileView, TabletView, BrowserView } from 'react-device-detect'
import urlFormat from '../../utils/urlFormat'

// import _t from '../../utils/translate'

const HealthTopicsDisplay = ({ apiElement, language }) => (
	<div className="health_topics_display">
		<div className="health_topics">
			{apiElement.health_topics.map(topic => {
				const key = urlFormat(topic.name)
				return (
					<Link
						key={key}
						to={`${apiElement.base_url}/${key}`}
						className="topic_preview"
						style={{
							backgroundImage: `url(${topic.preview_image.hasOwnProperty(
								'sizes'
							) && topic.preview_image.sizes.medium_large})`
						}}
					>
						<div className="green_overlay" />
						<div className="text_holder">
							<div className="text_holder_inner">
								<MobileView>
									<Textfit mode="single" max={20.6}>
										<p>{topic.name}</p>
									</Textfit>
								</MobileView>
								<TabletView>
									<Textfit mode="single" max={26}>
										<p>{topic.name}</p>
									</Textfit>
								</TabletView>
								<BrowserView>
									<Textfit mode="single" max={26}>
										<p>{topic.name}</p>
									</Textfit>
								</BrowserView>
							</div>
						</div>
					</Link>
				)
			})}
			<div className="clearfix" />
		</div>
	</div>
)

HealthTopicsDisplay.defaultProps = {
	apiElement: {}
}
HealthTopicsDisplay.propTypes = {
	apiElement: PropTypes.object, // eslint-disable-line
	language: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
	language: state.ui.language
})

export default connect(mapStateToProps)(HealthTopicsDisplay)
