import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import shortid from 'shortid'
import _ from 'lodash'

import Spinner from '../Spinner'
import _t from '../../utils/translate'

const FoodSupplementSingle = ({ product, language }) => {
    console.log(product)
    return (
        <Link
		className="single"
		to={`/${language}/${_t('shop', 'routes')}/${_t('food_supplements', 'routes')}/${_t('products', 'routes')}/${product.slug}`}
	>
		<div
			className="image"
			style={{
				// eslint-disable-next-line
				backgroundImage: `url(${product.acf.hasOwnProperty(
					'product_image'
				) &&
					product.acf.product_image.hasOwnProperty('sizes') &&
					product.acf.product_image.sizes.large})`
			}}
		/>
		<div className="info">
			<h2>{product.name}</h2>
			<div className="description"><p>{product.acf[`description_short_${language}`]}</p>
			</div>
			<button type="button">{_t('View Item')}</button>
		</div>
	</Link>
    )
}

class FoodSupplementsDisplay extends React.Component {
    state = { products: null }

    static getDerivedStateFromProps(nextProps, lastState) {
        const { loading, allProducts } = nextProps

        if (!loading) {
            const products = allProducts.filter(element => {
                return _.find(
                    element.categories,
                    cat => cat.slug === 'food-supplements'
                )
            })
            const sortedProducts = _.sortBy(products, 'name')
            // console.log(products)
            return { products: sortedProducts }
        }
        return null
    }

    render() {
        const { loading, language } = this.props
        const { products } = this.state
        return (
            <div className="food_supplements_holder">
				{!loading &&
					products.map(product => (
						<FoodSupplementSingle
							key={shortid.generate()}
							product={product}
							language={language}
						/>
					))}
				{loading && <Spinner size="small"/>}	
				<div className="clearfix" />
			</div>
        )
    }
}
//

const mapStateToProps = state => ({
    allProducts: state.products.products,
    loading: state.products.loading,
    language: state.ui.language
})

export default connect(mapStateToProps)(FoodSupplementsDisplay)