import React from 'react'
// import Validator from 'validator'

import _t from '../utils/translate'

class PasswordField extends React.Component {
	state = { edit: false, data: {} }

	constructor(props) {
		super(props)
		this.toggleEdit = this.toggleEdit.bind(this)
		this.validate = this.validate.bind(this)
		this.resetError = this.resetError.bind(this)
		this.oldPassword = React.createRef()
		this.newPassword = React.createRef()
		this.newPasswordRepeat = React.createRef()
	}

	componentDidMount() {
		const { field } = this.props
		this.setState({ data: { [field.name]: field.data } })
		// console.log(field)
	}

	validate = data => {
		let error = false
		// if (data.login) {
		// 	if (!Validator.isEmail(data.login)) error = 'Invalid email'
		// }
		if (data.newPassword !== data.newPasswordRepeat)
			error = 'The passwords are not the same'
		if (!data.newPassword) error = 'New Password cannot be empty!'
		if (!data.oldPassword) error = 'Please enter your old Password!'
		if (data.newPassword.length < 8)
			error = 'Your password has to be at least 8 characters long!'
		// if (!data.password) errors.password = "Can't be blank"

		return error
	}

	toggleEdit(e) {
		e.preventDefault()
		const { edit } = this.state
		const { onSave } = this.props
		const { validate } = this
		if (edit) {
			// console.log(input.current.value)
			const newData = {
				oldPassword: this.oldPassword.current.value,
				newPassword: this.newPassword.current.value,
				newPasswordRepeat: this.newPasswordRepeat.current.value
			}
			// const newData = ''
			const error = validate(newData)
			if (!error) {
				onSave(newData)
				this.setState(prevState => ({ edit: !prevState.edit }))
			} else this.setState({ error })
		} else this.setState(prevState => ({ edit: !prevState.edit }))
	}

	resetError() {
		this.setState({ error: false })
	}

	render() {
		const { field, passwordLength } = this.props
		const { edit, error } = this.state
		const { toggleEdit, resetError } = this
		return (
			<div className={`password_field field ${edit ? 'edit' : ''}`}>
				<span className="label">{_t(field.label)}</span>
				<span className="content">{'•'.repeat(passwordLength)}</span>
				<form onSubmit={toggleEdit}>
					{error ? <span className="error">{error}</span> : null}
					<input
						className="old_password"
						name="password"
						type="password"
						placeholder={_t('Old Password', 'account')}
						onChange={resetError}
						ref={this.oldPassword}
					/>
					<input
						className="new_password"
						name="password"
						type="password"
						placeholder={_t('New Password', 'account')}
						onChange={resetError}
						ref={this.newPassword}
					/>
					<input
						className="repeat_password"
						name="repeat_password"
						type="password"
						placeholder={_t('Repeat New Password', 'account')}
						onChange={resetError}
						ref={this.newPasswordRepeat}
					/>
				</form>
				<button type="button" className="change" onClick={toggleEdit}>
					{_t(edit ? 'Save' : 'Edit')}
				</button>
			</div>
		)
	}
}

PasswordField.defaultProps = {
	passwordLength: 3
}

export default PasswordField
