import React from 'react'
import PropTypes from 'prop-types'

const TitleWithIcon = ({ apiElement }) => {
	return (
		<div className="title_with_icon_wrapper">
			<div className="title_with_icon">
				<div className={`icon icon_${apiElement.icon_name} active`} />
				<h1>{apiElement.title}</h1>
			</div>
		</div>
	)
}

TitleWithIcon.defaultProps = {
	apiElement: ''
}
TitleWithIcon.propTypes = {
	apiElement: PropTypes.object // eslint-disable-line
}

export default TitleWithIcon
