import React from 'react'
import { connect } from 'react-redux'

import { showCart } from '../actions/cartActions'

class CartButton extends React.Component {
    constructor(props) {
        super(props)
        this.clickHandler = this.clickHandler.bind(this)
    }

    clickHandler = () => {
        this.props.showCart() //eslint-disable-line
    }

    render() {
        const { displayCart, cartItems, user } = this.props
        let nrCartItems = 0
        cartItems.map(element => {
            nrCartItems += element.quantity
            return null
        })

        if (!user.login) return null

        return (
            <button
				type="button"
				className={`cart_button ${displayCart && 'hidden'}`}
				onClick={this.clickHandler}
			>
				<div className="icon icon_cart active" />

				{cartItems.length > 0 ? (
					<div className="number">
						<span>{nrCartItems}</span>
					</div>
				) : (
					undefined
				)}
			</button>
        )
    }
}

const mapStateToProps = state => ({
    displayCart: state.cart.displayCart,
    cartItems: state.cart.cartItems,
    user: state.user
})

export default connect(
    mapStateToProps, { showCart }
)(CartButton)