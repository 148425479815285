import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const PopupOverlay = ({ overlayVisible }) => (
	<div className={`popup_overlay ${overlayVisible ? 'visible' : ''}`} />
)

const mapStateToProps = state => ({
	overlayVisible: state.ui.overlayVisible
})

PopupOverlay.propTypes = {
	overlayVisible: PropTypes.bool.isRequired
}

export default connect(mapStateToProps)(PopupOverlay)
