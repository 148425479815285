import React from 'react'
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom'

import { BrowserView, isBrowser } from 'react-device-detect'

// import slider_dummy_image from ""

class Slide extends React.Component {
  constructor(props) {
    super(props)
    this.showText = this.showText.bind(this)
    this.hideText = this.hideText.bind(this)
    this.onDuration = this.onDuration.bind(this)
    this.startSlide = this.startSlide.bind(this)
    this.state = {
      duration: null,
      textClass: 'visible',
      videoPlaying: false,
    }
  }

  componentDidUpdate(oldProps, oldState) {
    if (
      this.props.visible &&
      !oldProps.visible &&
      this.state.duration &&
      isBrowser
    ) {
      // console.log('start because of Visible')
      this.startSlide()
    }
    if (this.props.visible && !oldProps.visible && !isBrowser) {
      this.setState({ duration: 5 }, () => this.startSlide())
    }
  }

  componentWillUnmount() {
    this.setState({ videoPlaying: false, textVisible: false })
    if (isBrowser) this.player.seekTo(0)
    clearTimeout(this.timerNextSlide)
    clearTimeout(this.timerShowText)
    clearTimeout(this.timerHideText)
    clearTimeout(this.timerStopVideo)
  }

  ref = (player) => {
    this.player = player
  }

  showText = () => {
    this.setState({ textClass: 'visible' })
  }

  hideText = () => {
    this.setState({ textClass: 'fade_out' })
  }

  nextSlide = () => {
    const { onNext } = this.props

    this.timerStopVideo = setTimeout(() => {
      this.setState({ videoPlaying: false })
      if (this.player) this.player.seekTo(0)
    }, 1000)
    onNext()
  }

  onDuration = (duration) => {
    const { visible } = this.props
    this.setState({ duration })
    if (visible) {
      this.startSlide()
      // console.log('start because of duration')
    }
  }

  startSlide = () => {
    const { duration } = this.state
    this.setState({ videoPlaying: true, textClass: 'fade_in' })
    this.timerNextSlide = setTimeout(() => {
      this.nextSlide()
    }, duration * 1000 - 1000)
    this.timerShowText = setTimeout(() => {
      this.showText()
    }, 500)
    this.timerHideText = setTimeout(() => {
      this.hideText()
    }, duration * 1000 - 1000)
  }

  render() {
    const { slide, visible } = this.props
    const { textClass, videoPlaying } = this.state
    const imageUrl =
      slide &&
      slide.hasOwnProperty('image') &&
      slide.image.hasOwnProperty('sizes') &&
      slide.image.sizes.large

    return (
      <div className={`slide ${visible ? 'visible' : ''}`}>
        {
          // <video className="vid" loop autoPlay>
          //  <source
          //      src={slide.video.url}
          //      type="video/mp4"
          //      muted
          //  />
          // </video>
        }
        <BrowserView>
          <ReactPlayer
            className="slide_video_player"
            url={slide.video.url}
            playing={videoPlaying}
            width="100%"
            height="auto"
            onDuration={this.onDuration}
            volume={0}
            muted
            preload="auto"
            ref={this.ref}
            // loop={true}
            style={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              // display: 'none'
            }}
          />
        </BrowserView>
        <div
          className="image"
          style={{ backgroundImage: `url('${imageUrl}')` }}
        />


        <Link to={slide.link} className={`text_holder ${textClass}`}>
            <div className={`images_holder images_holder--${slide.product_image_size} images_holder--${slide.product_image && slide.product_image_2 && slide.product_image_3 ? '3' : slide.product_image && slide.product_image_2 ? '2' : '1'}`}>
              
              {slide.product_image
              ?
                <div className="__img-wrapper">
                <img src={slide.product_image.sizes.large} />
                </div>
              :
                ''
              }

              {slide.product_image_2
              ?
                <div className="__img-wrapper">
                <img src={slide.product_image_2.sizes.large} />
                </div>
              :
                ''
              }

              {slide.product_image_3
              ?
                <div className="__img-wrapper">
                <img src={slide.product_image_3.sizes.large} />
                </div>
              :
                ''
              }

            </div>
          <div className="text">
            <h2>{slide.tag_line}</h2>
            <h1>{slide.title}</h1>
          </div>
        </Link>
      </div>
    )
  }
}

Slide.defaultProps = {}

Slide.propTypes = {}

export default Slide
