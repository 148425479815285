import React from 'react'
import { connect } from 'react-redux'

import DilutionSlider from '../DilutionSlider'
import SelectionButton from '../SelectionButton'

import _t from '../../utils/translate'

class MakeYourOwnSingleSubstance extends React.Component {
  state = {
    data: {
      searchStr: '',
      showAll: false,
      selectedRemedy: null,
      selectedScale: null,
      selectedDilution: null,
      selectedForm: null,
      selectedCarrier: null,
      selectedSize: null,
      currentPrice: null,
      currentWcId: null,
      selectedRemedyName: null,
      error: null,
      priceCategory: null,
      slug: null,
    },
    remedies: null,
    closed: false,
    deleteDialogueVisible: false,
  }

  constructor(props) {
    super(props)
    this.setType = this.setType.bind(this)
    this.setSubstance = this.setSubstance.bind(this)
    this.setRemedy = this.setRemedy.bind(this)
    this.toggleDeleteDialog = this.toggleDeleteDialog.bind(this)
    this.delete = this.delete.bind(this)
  }

  static getDerivedStateFromProps(nextProps, lastState) {
    return {
      ...lastState,
      remedies: {
        ...lastState.remedies,
        Homeopathy: nextProps.homeopathyRemedies
          ? Object.values(nextProps.homeopathyRemedies)
          : null,
        Phytotherapy: nextProps.phytotherapyRemedies,
        Gemmotherapy: nextProps.gemmotherapyRemedies,
      },
    }
  }

  toggleOpen = () => {
    this.setState((oldState) => ({ closed: !oldState.closed }))
  }

  onChange = (e) =>
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      errors: { ...this.state.errors, [e.target.name]: false },
    })

  setType = (type) => {
    // console.log(type)
    const { changeSubstance, substance, keyx } = this.props
    changeSubstance({
      [keyx]: {
        ...substance,
        type,
        substanceName: null,
        remedy: null,
        scale: null,
        dilution: null,
        finished: false,
      },
    })
    this.setState((oldState) => ({
      data: { ...oldState.data, searchStr: '' },
    }))
  }

  toggleDeleteDialog = () => {
    this.setState((oldState) => ({
      deleteDialogueVisible: !oldState.deleteDialogueVisible,
    }))
  }

  delete = () => {
    // console.log(type)
    const { deleteSubstance, keyx } = this.props
    deleteSubstance(keyx)
  }

  setScale = (scale) => {
    // console.log(type)
    const { changeSubstance, substance, keyx } = this.props
    changeSubstance({
      [keyx]: { ...substance, scale, dilution: null },
    })
  }

  setDilution = (dilution) => {
    // console.log(type)
    const { changeSubstance, substance, keyx } = this.props
    changeSubstance({
      [keyx]: { ...substance, dilution, finished: true },
    })
  }

  setRemedy = (id) => {
    const { remedies } = this.state
    const {
      changeSubstance,
      substance,
      substance: { type },
      keyx,
    } = this.props

    const activeRemedy = remedies[type].find((element) => {
      return element.id === id
    })
    // console.log(categoryRemedies)

    const isHomeopathy = type === 'Homeopathy'

    changeSubstance({
      [keyx]: {
        ...substance,
        substanceName: activeRemedy.nm,
        remedy: activeRemedy,
        finished: isHomeopathy ? false : true,
      },
    })
  }

  setSubstance = (e) => {
    const { changeSubstance, substance, keyx } = this.props
    // console.log(substance)
    changeSubstance({
      [keyx]: { ...substance, substanceName: e.target.value },
    })
  }

  unSetRemedy = () => {
    const { changeSubstance, substance, keyx } = this.props
    changeSubstance({
      [keyx]: {
        ...substance,
        substanceName: null,
        remedy: null,
        scale: null,
      },
    })
    this.setState((oldState) => ({
      data: { ...oldState.data, searchStr: '' },
    }))
  }

  render() {
    const {
      loading,
      substance: { type, substanceName, scale, dilution, remedy, finished },
      keyx,
    } = this.props

    const {
      remedies,
      closed,
      deleteDialogueVisible,
      data: {
        searchStr,
        showAll,
        selectedRemedy,
        // selectedSize,
        // currentPrice,
        // activeRemedy,
        // selectedScale,
        // selectedDilution,
        // selectedForm,
        // selectedCarrier,
        // error,
        // slug
      },
    } = this.state

    return (
      <div className={`single_substance ${closed ? 'closed' : ''}`}>
        <button
          type="button"
          className="substance_summary"
          onClick={this.toggleOpen}
        >
          <span>
            {_t(type)}: {substanceName} {dilution}
          </span>
        </button>
        <div className="substance_controls">
          <div className="type section">
            <div className="label">{_t('Type', 'shop')}</div>
            <SelectionButton
              attribute={type}
              method={this.setType}
              value="Homeopathy"
              icon="homeopathy"
            />
            <SelectionButton
              attribute={type}
              method={this.setType}
              value="Phytotherapy"
              icon="phytotherapy"
            />
            <SelectionButton
              attribute={type}
              method={this.setType}
              value="Gemmotherapy"
              icon="gemmotherapy"
            />
          </div>
          {type && (
            <div className="substance section">
              <div className="label">{_t('Substance', 'shop')}</div>
              <input
                type="text"
                name="searchStr"
                value={substanceName ? substanceName : searchStr}
                onChange={selectedRemedy ? this.unSetRemedy : this.onChange}
                onClick={substanceName ? this.unSetRemedy : undefined}
                placeholder={
                  loading
                    ? _t('Loading...')
                    : _t(`Search {type}...`, 'general', {
                        type: _t(type),
                      })
                }
              />
            </div>
          )}

          <div className="remedies">
            {!loading &&
              type &&
              (remedies[type].length < 500 ||
                searchStr.length > 1 ||
                showAll) &&
              // console.log(remedies)
              remedies[type].map((element) => {
                const index = element.nm
                  .toLowerCase()
                  .indexOf(searchStr.toLowerCase())
                if (
                  ((index !== -1 && searchStr !== '') ||
                    (showAll && searchStr === '')) &&
                  !substanceName
                ) {
                  const { id, nm } = element
                  return (
                    <button
                      type="button"
                      className="remedy"
                      key={`remedy_${id}`}
                      onClick={this.setRemedy.bind(this, id)}
                    >
                      {nm}
                    </button>
                  )
                }
                return null
              })}
            {!loading &&
              searchStr.length > 0 &&
              searchStr.length < 2 &&
              remedies[type].length >= 500 &&
              !substanceName && (
                <p>{_t('Type more to see results...', 'shop')}</p>
              )}
          </div>
          {type === 'Homeopathy' && substanceName && (
            <div className="scale section">
              <div className="label">{_t('Scale', 'shop')}</div>

              <SelectionButton
                attribute={scale}
                method={this.setScale}
                value="DH"
              />
              <SelectionButton
                attribute={scale}
                method={this.setScale}
                value="CH"
              />
              {/* <SelectionButton
                attribute={scale}
                method={this.setScale}
                value="K"
              /> */}
            </div>
          )}

          {type === 'Homeopathy' && substanceName && scale && (
            <div className="dilution section">
              <div className="label">{_t('Dilution', 'shop')}</div>
              <DilutionSlider
                key={`${keyx}_slider_${scale}`}
                keyId={`${keyx}_slider_${scale}`}
                dilutions={remedy.availableDilutions[scale]}
                selectedScale={scale}
                selectedDilution={dilution}
                onChange={this.setDilution}
              />
            </div>
          )}
          <button
            type="button"
            onClick={this.toggleOpen}
            className="close"
            disabled={
              !type || !substanceName || (type === 'Homeopathy' && !scale)
            }
          >
            {_t('Close')}
          </button>
          <button
            type="button"
            onClick={this.toggleDeleteDialog}
            className="delete red"
          >
            {_t('Delete')}
          </button>
          <div className="clearfix" />
        </div>
        <div
          className={
            deleteDialogueVisible ? 'delete_dialog visible' : 'delete_dialog'
          }
        >
          <div className="dialog_holder">
            <p>{_t('Do you want to delete this substance?')}</p>
            <button type="button" onClick={this.delete} className="red">
              {_t('Delete')}
            </button>
            <button
              type="button"
              onClick={this.toggleDeleteDialog}
              className=""
            >
              {_t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  homeopathyRemedies: state.singleRemedies.singleRemedies,

  phytotherapyRemedies: state.singleRemedies.phytotherapy,
  gemmotherapyRemedies: state.singleRemedies.gemmotherapy,
})

export default connect(mapStateToProps)(MakeYourOwnSingleSubstance)
