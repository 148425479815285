import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Spinner from '../Spinner'

const LoadingScreen = ({ loading }) => (
	<div
		className={loading ? 'loading_screen visible' : 'loading_screen hidden'}
	>
		<Spinner />
	</div>
)

LoadingScreen.propTypes = {
	loading: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
	loading: state.pages.loading
})

export default connect(mapStateToProps)(LoadingScreen)
