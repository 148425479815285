import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import shortid from 'shortid'
import _ from 'lodash'

import _t from '../../utils/translate'
import urlFormat from '../../utils/urlFormat'

const SchusslerSingle = ({ product, language }) => (
    <Link
		className="single"
		to={`/${language}/${_t('shop', 'routes')}/${_t('schussler_salts', 'routes')}/${urlFormat(product.name)}`}
	>
		<span className="no">N°{product.nr}</span>
		<div className="image" />
		<div className="text">
			<p className="title">
				N°{product.nr}
				<br />
				{product.name}
			</p>
			{product.avl &&
				product.avl.map(element => (
					<p key={shortid.generate()}>
						{_t(element, 'schussler_salts')}
					</p>
				))}
		</div>
	</Link>
)

const SchusslerDisplay = ({ apiElement, schusslerSalts, loading, language }) => {
    const sortedSchussler = _.sortBy(schusslerSalts, 'srt')
    return (
        <div className="schussler_holder">
			{!loading &&
				sortedSchussler.map(product => (
					<SchusslerSingle
						key={shortid.generate()}
						product={product}
						language={language}
					/>
				))}
			<div className="clearfix" />
		</div>
    )
}
//

const mapStateToProps = state => ({
    schusslerSalts: state.singleRemedies.schussler,
    loading: state.singleRemedies.schusslerLoading,
    language: state.ui.language
})

export default connect(mapStateToProps)(SchusslerDisplay)