import { LOAD_PAGES, SET_LANGUAGE, LANGUAGE_LOADED } from '../actions/types'

const initialState = {
	loading: true,
	language: 'en'
}

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_PAGES:
			return { ...state, ...action.payload, loading: false }
		case SET_LANGUAGE:
			// if (state.language !== action.payload)
			return { ...initialState, language: action.payload }
		case LANGUAGE_LOADED:
			// let log = []
			// Object.entries(action.payload).map(entry => {
			// 	log.push(entry[0])
			// 	log.push(JSON.stringify(entry[1]?.acf, null, 4))
			// })
			// console.log(log.join('\n\n'))
			return {
				...action.payload,
				// language: action.payload.language,
				loading: false
			}
		default:
			return state
	}
}
