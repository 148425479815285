import _ from 'lodash'

import {
    SHOW_CART,
    HIDE_CART,
    ADD_TO_CART,
    REMOVE_FROM_CART,
    EMPTY_CART
} from '../actions/types'

const initialState = {
    displayCart: false,
    cartItems: []
}

export default function(state = initialState, action) {
    let foundProduct = undefined
    let newProduct = undefined
    let newCart = undefined
    switch (action.type) {
        case SHOW_CART:
            return {
                ...state,
                displayCart: true
            }
        case HIDE_CART:
            return {
                ...state,
                displayCart: false
            }
        case ADD_TO_CART:
            // console.log('add to cart:')
            // console.log(action.payload)
            // console.log('current cart:')
            // console.log(state.cartItems)
            foundProduct = _.find(state.cartItems, {
                name: action.payload.name,
                WcId: action.payload.WcId,
                info: action.payload.info
            })
            if (foundProduct) {
                console.log('found product:')
                console.log(foundProduct)
                newProduct = {
                    ...foundProduct,
                    quantity: foundProduct.quantity + action.payload.quantity
                }
            } else {
                newProduct = action.payload
                console.log('product not in cart yet.')
            }
            newCart = {
                ...state,
                cartItems: [
                    ...state.cartItems.filter(
                        item => item.cartId !== newProduct.cartId
                    ),
                    newProduct
                ]
            }
            console.log('New Cart:')
            console.log(newCart.cartItems)

            return newCart
        case REMOVE_FROM_CART:
            return {
                ...state,
                cartItems: [
                    ...state.cartItems.filter(
                        item => item.cartId !== action.payload
                    )
                ]
            }
        case EMPTY_CART:
            return {
                ...state,
                cartItems: []
            }
        // case CART_LOADED:
        //  return {
        //      ...state,
        //      cartItems: [...state.cartItems, action.payload]
        //  }
        default:
            return state
    }
}
