import React from 'react'
import PropTypes from 'prop-types'

const Quote = ({ apiElement }) => {
	const isSingleLine = apiElement.quote_line_2 === ''
	const closingElementLine1 = isSingleLine ? (
		<React.Fragment>
			<span>"</span>
			<span className="quotee">- {apiElement.quotee}</span>
		</React.Fragment>
	) : (
		''
	)
	return (
		<div className="quote_wrapper">
			<div
				className={`quote ${
					isSingleLine ? 'single_line' : 'two_lines'
				}`}
			>
				<span className="quote_line_1">
					{`"${apiElement.quote}`}
					{closingElementLine1}
				</span>
				{!isSingleLine && (
					<React.Fragment>
						<br />
						<span className="quote_line_2">
							{apiElement.quote_line_2}"
							<span className="quotee">{`- ${
								apiElement.quotee
							}`}</span>
						</span>
					</React.Fragment>
				)}
			</div>
		</div>
	)
}

Quote.defaultProps = {
	apiElement: {}
}
Quote.propTypes = {
	apiElement: PropTypes.object // eslint-disable-line
}

export default Quote
