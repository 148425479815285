import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
// import slider_dummy_image from ""

const Banner = ({
    imageUrl,
    caption,
    textColor,
    callToActionButton,
    apiElement
}) => {
    return (
        <div className="banner_wrapper">
			<div
				className="banner"
				style={{
					backgroundImage: `url(${imageUrl})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center'
				}}
			>
				<div className="darken" style={{opacity: apiElement.darken_percentage / 100}}/>
				<h1 className={textColor}>{caption}</h1>
				<div className="products_holder">
					{apiElement.products &&
						apiElement.products.map(element => (
							<Link
								className="product_link"
								to={element.product_link || ''}
								key={`banner_product_${element.product_name}`}
							>
								<div
									className={`icon white icon_${element.product_icon ||
										'unknown'}`}
								/>
								<span className="product_name">
									{element.product_name}
								</span>
							</Link>
						))}
				</div>
				<Link
					className="button white outline"
					to={apiElement.call_to_action_link}
				>
					{callToActionButton}
				</Link>
			</div>
		</div>
    )
}

Banner.defaultProps = {
    imageUrl: '',
    caption: '',
    textColor: 'white',
    callToActionButton: ''
}

Banner.propTypes = {
    imageUrl: PropTypes.string,
    caption: PropTypes.string,
    textColor: PropTypes.string,
    callToActionButton: PropTypes.string
}

export default Banner