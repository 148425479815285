const parseDate = (date) => {
  const year = date.substr(0, 4)
  const month = date.substr(5, 2)
  const day = date.substr(8, 2)
  const hour = date.substr(11, 2)
  const minute = date.substr(14, 2)

  return { year, month, day, hour, minute }
}

export default parseDate
