import {
	USER_LOGGED_IN,
	USER_LOGGED_OUT,
	USER_DATA_LOADED,
	SET_LOADING
} from '../actions/types'

const initialState = {
	loggedIn: false
}

export default function(state = initialState, action) {
	switch (action.type) {
		case USER_LOGGED_IN:
			return { ...state, loggedIn: true, token: action.payload.token }
		case USER_LOGGED_OUT:
			return { loggedIn: false }

		case USER_DATA_LOADED:
			// console.log(action)
			return { ...state, ...action.data }
		case SET_LOADING:
			return initialState
		default:
			return state
	}
}
