import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Validator from 'validator'

import { submitContactFormRequest } from '../../actions/userActions'
import { showMessage } from '../../actions/uiActions'
import Spinner from '../Spinner'
import _t from '../../utils/translate'

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    const { user } = this.props

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

    // console.log(user)

    this.state = {
      loading: false,
      success: false,
      data: {
        name: '',
        company: '',
        phone: '',
        email: '',
        message: '',
        agb: false,
        newsletter: true,
        ...user,
      },
      errors: {},
    }
  }

  onChange = (e) => {
    // const setUserData = this.props
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    // console.log(target)
    // console.log(name)
    // console.log(value)
    // console.log(this.props.setUserData({ asdf: 'adsf' }))

    this.setState({
      data: { ...this.state.data, [name]: value },
      errors: { ...this.state.errors, [name]: false },
    })

    // if (name !== 'newsletter' && name !== 'agb' && name !== 'message')
    //     this.props.setUserData({
    //         [name]: value
    //     })
  }

  onSubmit = (e) => {
    const { showMessage } = this
    const { submitContactFormRequest, language, options } = this.props
    const { data } = this.state
    const errors = this.validate(data)
    const contactFormOptions = options.data[language].contact_form

    // console.log('adfsdasfadfsfads')

    e.preventDefault()

    this.setState({ errors })
    if (Object.keys(errors).length !== 0) {
      showMessage(errors)
    }
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true })
      // submit(data)
      submitContactFormRequest({ ...data, language })
        .then((res) => {
          // console.log(res)
          this.setState({ loading: false, success: true })
          return res
        })
        .catch((err) => {
          this.setState({ loading: false })
          this.props.showMessage(
            err.response && err.response.data && err.response.data.message
              ? _t(err.response.data.message)
              : contactFormOptions?.problem_sending ||
                  _t('There was a problem sending your message.'),
            'error'
          )
        })
    }
  }

  validate = (data) => {
    const { language, options } = this.props
    const contactFormOptions = options.data[language].contact_form
    const errors = {}
    if (!data.name)
      errors.name =
        contactFormOptions?.name_missing ||
        _t(`Please enter your name.`, 'account')
    // if (!data.phone)
    //     errors.phone = _t(
    //         `Bitte geben Sie Ihre Telefonnummer an.`,
    //         'account'
    //     )
    if (!Validator.isEmail(data.email))
      errors.email =
        contactFormOptions?.email_wrong ||
        _t('Please correct your email address.', 'account')
    if (!data.email)
      errors.email =
        contactFormOptions?.email_missing ||
        _t(`Please enter your email address.`, 'account')
    if (!data.agb)
      errors.agb =
        contactFormOptions?.accept_terms ||
        _t(`You have to accept the terms and conditions.`, 'account')

    return errors
  }

  showMessage = (message) => {}

  render() {
    if (this.props.options.loading) {
      return null
    }

    const {
      language,
      apiElement: { is_background_color, anchor_name },
    } = this.props
    const contactFormOptions = this.props.options.data[language].contact_form

    // console.log(language)
    // console.log(this.props.options.data[language])

    if (!contactFormOptions || contactFormOptions.length < 1) {
      return <span>Options not found!</span>
    }
    const { data, errors, loading, success } = this.state
    const { onChange, onSubmit } = this
    return (
      <div
        className={`contact_form_wrapper ${
          is_background_color ? 'background_color' : 'background_white'
        }`}
      >
        <div className="contact_form" id={`${anchor_name}_anchor`}>
          <div className="text">
            {contactFormOptions.title?.length > 0 && (
              <h1>{contactFormOptions.title}</h1>
            )}
            <p>{contactFormOptions.text}</p>
          </div>
          <div className="form">
            <div
              className={`form_holder ${loading ? 'loading' : ''} ${
                success ? 'success' : ''
              }`}
            >
              <form onSubmit={onSubmit}>
                <div className={`field ${errors.name ? 'error' : ''}`}>
                  <span className="error_msg">{errors.name}</span>
                  <span className="required">
                    <input
                      className={is_background_color ? 'white' : ''}
                      name="name"
                      value={data.name}
                      placeholder={`${contactFormOptions.text_name || 'Name'}*`}
                      onChange={onChange}
                      type="text"
                    />
                  </span>
                </div>
                <div className={`field ${errors.company ? 'error' : ''}`}>
                  <span className="error_msg">{errors.company}</span>
                  <span className="required">
                    <input
                      className={is_background_color ? 'white' : ''}
                      name="company"
                      value={data.company}
                      placeholder={`${contactFormOptions.text_company ||
                        'Company'}`}
                      onChange={onChange}
                      type="text"
                    />
                  </span>
                </div>
                <div className={`field ${errors.email ? 'error' : ''}`}>
                  <span className="error_msg">{errors.email}</span>
                  <span className="required">
                    <input
                      className={is_background_color ? 'white' : ''}
                      name="email"
                      value={data.email}
                      placeholder={`${contactFormOptions.text_email ||
                        'Email'}*`}
                      onChange={onChange}
                      type="email"
                    />
                  </span>
                </div>
                <div className={`field ${errors.phone ? 'error' : ''}`}>
                  <span className="error_msg">{errors.phone}</span>
                  <span className="required">
                    <input
                      className={is_background_color ? 'white' : ''}
                      name="phone"
                      value={data.phone}
                      placeholder={`${contactFormOptions.text_phone ||
                        'Phone'}`}
                      onChange={onChange}
                      type="text"
                    />
                  </span>
                </div>
                <div className={`field ${errors.message ? 'error' : ''}`}>
                  <span className="error_msg">{errors.message}</span>
                  <textarea
                    className={is_background_color ? 'white' : ''}
                    name="message"
                    value={data.message}
                    style={{}}
                    onChange={onChange}
                    rows={5}
                    placeholder={
                      contactFormOptions.text_message || 'Your Message'
                    }
                  />
                </div>
                <div className={`field ${errors.agb ? 'error' : ''} `}>
                  <span className="error_msg">{errors.agb}</span>
                  <input
                    type="checkbox"
                    id="agb"
                    name="agb"
                    className={is_background_color ? 'white' : ''}
                    value="agb_read"
                    checked={data.agb}
                    onChange={onChange}
                  />
                  <label
                    htmlFor="agb"
                    className={is_background_color ? 'white agb' : 'agb'}
                  >
                    {contactFormOptions.text_agb_checkbox ||
                      'I accept the terms and conditions. '}{' '}
                    (
                    <Link to={contactFormOptions.agb_link} target="_blank">
                      {contactFormOptions.agb_link_text ||
                        'Terms and Conditions'}
                    </Link>
                    )*
                  </label>
                </div>
                <input
                  type="checkbox"
                  id="newsletter"
                  name="newsletter"
                  value="newsletter"
                  checked={data.newsletter}
                  onChange={onChange}
                  className={is_background_color ? 'white' : ''}
                />
                <label
                  htmlFor="newsletter"
                  className={is_background_color ? 'white' : ''}
                >
                  {contactFormOptions.text_newsletter_checkbox ||
                    'Subscribe to newsletter'}
                </label>
                <button
                  className={`primary ${is_background_color ? 'white' : ''}`}
                  type="submit"
                >
                  {contactFormOptions.text_submit || 'Submit'}
                </button>
                <div className="form_overlay"></div>
              </form>
              <div className="loading_holder">
                {loading && (
                  <Spinner
                    status={`${loading ? 'loading' : ''}${
                      success ? 'success' : ''
                    }`}
                    color="primary"
                  />
                )}
                <div className="messages">
                  <span className="loading_message">
                    {contactFormOptions.text_loading || _t('Loading...')}
                  </span>
                  <span className="success_message">
                    {contactFormOptions.text_success ||
                      'Your message has been sent.'}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  options: state.options,
  language: state.ui.language,
})

ContactForm.propTypes = {
  lanugage: PropTypes.string.isRequired,
  apiElement: PropTypes.shape({
    anchor_name: PropTypes.string,
    is_background_color: PropTypes.bool.isRequired,
  }).isRequired,
  showMessage: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {
  submitContactFormRequest,
  showMessage,
})(ContactForm)
