import React from 'react'
import PropTypes from 'prop-types'

const HeaderImage = ({ apiElement }) => {
	if (!apiElement?.image_mobile?.sizes?.large)
		return (
			<div
				className="header_image"
				style={{
					backgroundImage: `url(${apiElement.image.sizes.large})`
				}}
			/>
		)

	return (
		<React.Fragment>
			<div
				className="header_image desktop_only"
				style={{
					backgroundImage: `url(${apiElement.image.sizes.large})`
				}}
			/>
			<div
				className="header_image mobile_only"
				style={{
					backgroundImage: `url(${
						apiElement?.image_mobile?.sizes?.large
							? apiElement.image_mobile.sizes.large
							: ''
					})`
				}}
			/>
		</React.Fragment>
	)
}
HeaderImage.propTypes = {
	apiElement: PropTypes.object.isRequired // eslint-disable-line
}

export default HeaderImage
