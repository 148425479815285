import React from 'react'
import PropTypes from 'prop-types'
// import { Form, Button, Message } from 'semantic-ui-react'
// import Validator from 'validator'
import { connect } from 'react-redux'
import { get } from 'lodash'
// import { Link } from 'react-router-dom'

import InlineError from '../messages/InlineError'
import { showMessage } from '../actions/uiActions'
import { login, loadUserData } from '../actions/userActions'

// import { hidePopups } from '../actions/popupActions'
import _t from '../utils/translate'
// import { LanguageLink } from '../utils/LanguageLink'

class CompleteResetPasswordForm extends React.Component {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        // console.log(this.props.name)

        this.state = {
            data: {
                new_password_reset: '',
                repeat_password_reset: ''
            },
            loading: false,
            success: false,
            errors: {}
        }
    }

    hidePopup = e => {
        e.preventDefault()
        const {
            hideCompleteResetPasswordPopup
            // firstName,
            // sendEvent
        } = this.props
        hideCompleteResetPasswordPopup()
        window.location.hash = ''
        // console.log('hidePopup')
    }

    onChange = e =>
        this.setState({
            data: { ...this.state.data, [e.target.name]: e.target.value },
            errors: { ...this.state.errors, [e.target.name]: false }
        })

    onSubmit = e => {
        const {
            props: { submit, showMessage, login, token },
            state: { data }
        } = this

        // console.log(this.props.activationToken)
        // debugger
        const dataWithCode = {
            password: data.new_password_reset,
            token
        }
        e.preventDefault()
        const errors = this.validate(data)
        this.setState({ errors })
        if (Object.keys(errors).length === 0) {
            this.setState({ loading: true })
            submit(dataWithCode)
                .then(res => {
                    console.log(res.data.email)
                    return login({
                        username: res.data.email,
                        password: data.new_password_reset
                    })
                })
                .then(() => this.props.loadUserData())
                .then(res => {
                    this.setState({ success: true, loading: false })
                })
                .then(res => {
                    window.location.hash = ''
                    showMessage(
                        _t(
                            'Your password has successfully been reset.',
                            'account'
                        ),
                        'success'
                    )
                })
                .then(() =>
                    this.props.history.push(
                        `/${this.props.language}/${_t('account', 'routes')}/`
                    )
                ) // eslint-disable-line
                .catch(err => {
                    // console.log('ERROR in CompleteResetPasswordForm:')
                    // console.log(JSON.stringify(err.response))
                    const errorMessage =
                        get(err, ['response', 'data', 'message']) ||
                        'There was a problem, please try again'
                    this.setState({
                        loading: false
                    })

                    showMessage(_t(errorMessage, 'account'), 'error')
                })
        }
    }

    validate = data => {
        const errors = {}
        if (!data.new_password_reset)
            errors.new_password_reset = _t(
                `Please choose a password.`,
                'account'
            )

        if (data.new_password_reset !== data.repeat_password_reset)
            errors.repeat_password_reset = _t(
                `Passwords are not the same`,
                'account'
            )

        if (!data.repeat_password_reset)
            errors.repeat_password_reset = _t(
                `Please enter your password again.`,
                'account'
            )

        if (data.new_password_reset.length < 8)
            errors.new_password_reset = _t(
                `Your Password has to be at least 8 characters long.`,
                'account'
            )

        return errors
    }

    resolveError = e => {
        e.preventDefault()
        this.setState(oldState => ({
            errors: { ...oldState.errors, global: '' }
        }))
    }

    render() {
        const {
            // resolveError,
            // hidePopup,
            state: { data, errors, loading, success }
        } = this
        // const errors = {}

        // console.log(this.state)

        return (
            <form
                onSubmit={this.onSubmit}
                className={`${loading ? 'loading' : ''} ${
                    errors.global ? 'global_error' : ''
                } ${success ? 'success' : ''}`}
            >
                {null && errors.global && (
                    <div>
                        <div>{_t('Something went wrong', 'account')}</div>
                        <p>{errors.global}</p>
                    </div>
                )}
                <h2 className="align_center">
                    {_t('Set password', 'account')}
                </h2>
                <p className="align_center">
                    {_t(`Ok, great! Let's set a new password:`, 'account')}
                </p>

                <div className="field">
                    {errors.new_password_reset && (
                        <InlineError text={errors.new_password_reset} />
                    )}
                    <input
                        type="password"
                        id="new_password_reset"
                        name="new_password_reset"
                        placeholder={_t('Password', 'account')}
                        value={data.new_password}
                        autoComplete="new-password"
                        onChange={this.onChange}
                    />
                </div>
                <div className="field">
                    {errors.repeat_password_reset && (
                        <InlineError text={errors.repeat_password_reset} />
                    )}
                    <input
                        type="password"
                        id="repeat_password_reset"
                        name="repeat_password_reset"
                        placeholder={_t('Repeat Password', 'account')}
                        value={data.repeat_password}
                        autoComplete="new-password"
                        onChange={this.onChange}
                    />
                </div>
                <button className="primary" type="submit">
                    {_t('Continue', 'account')}
                </button>
                <div className="loading">
                    <div className="content">
                        <span>{_t('Loading...')}</span>
                    </div>
                </div>
            </form>
        )
    }
}

CompleteResetPasswordForm.propTypes = {
    submit: PropTypes.func.isRequired
}

const mapStateTopProps = state => ({
    token: state.popup.resetPasswordToken
})

export default connect(mapStateTopProps, {
    showMessage,
    login,
    loadUserData
})(CompleteResetPasswordForm)
