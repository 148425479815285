import shortid from 'shortid'

import { store } from '../store/store'

import {
  SHOW_CART,
  HIDE_CART,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  SHOW_MESSAGE,
  EMPTY_CART,
} from './types'
import _t from '../utils/translate'

export const showCart = () => (dispatch) => {
  dispatch({
    type: SHOW_CART,
  })
}
export const hideCart = () => (dispatch) => {
  dispatch({
    type: HIDE_CART,
  })
}

export const addToCart = (rawProduct) => (dispatch) => {
  console.log(rawProduct.quantity)
  console.log(parseInt(rawProduct.quantity, 10))

  const quantity =
    'quantity' in rawProduct ? parseInt(rawProduct.quantity, 10) : 1
  const product = { ...rawProduct }
  const newProduct = { ...product, cartId: shortid.generate(), quantity }

  console.log('New Product:')
  console.log(newProduct)
  let error = false

  if (!store.getState().user.loggedIn) {
    // alert('not logged in!')
    error = true
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        text: _t('Please log in or register to buy products.'),
        type: 'error',
        duration: 4000,
      },
    })
  }

  if (
    store.getState()?.user?.user_can_order === false &&
    store.getState()?.user?.user_can_order_with_form
  ) {
    // alert('not logged in!')
    error = true
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        text: _t(
          'Unfortunately you cannot order in the shop. Please use the order form that you can download on your account page.'
        ),
        type: 'error',
        duration: 10000,
      },
    })
  }

  if (
    store.getState()?.user?.user_can_order === false &&
    !store.getState()?.user?.user_can_order_with_form
  ) {
    // alert('not logged in!')
    error = true
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        text: _t(
          'Unfortunately you cannot order in the shop. Please contact us to enable this function.'
        ),
        type: 'error',
        duration: 10000,
      },
    })
  }

  if (!error) {
    dispatch({
      type: ADD_TO_CART,
      payload: newProduct,
    })
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        text: _t('Item has ben added to Cart!'),
        type: 'success',
        duration: 10000,
      },
    })
  }
}

export const removeFromCart = (id) => (dispatch) => {
  // console.log(product)
  dispatch({
    type: REMOVE_FROM_CART,
    payload: id,
  })
}

export const emptyCart = () => (dispatch) => {
  // console.log(product)
  dispatch({
    type: EMPTY_CART,
  })
}
