import React from 'react'
import { Route } from 'react-router-dom'

import SinglePageRenderer from '../SinglePageRenderer'
import SingleHealthPage from './SingleHealthPage'

import _t from '../../utils/translate'

const HealthPage = ({ language }) => {
    return (
        <React.Fragment>
			<Route
				exact
				path={`/${language}/${_t('health','routes')}`}
				component={() => (
					<SinglePageRenderer
						paddingTop={70}
						title="SN - Health"
						pageId="health"
					/>
				)}
			/>
			<Route path={`/${language}/${_t('health','routes')}/:topic`} component={SingleHealthPage} />
		</React.Fragment>
    )
}
export default HealthPage