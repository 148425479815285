import { combineReducers } from 'redux'
import pagesReducer from './pagesReducer'
import productsReducer from './productsReducer'
import singleRemediesReducer from './singleRemediesReducer'
import browserLanguagesReducer from './browserLanguagesReducer'
import popupReducer from './popupReducer'
import userReducer from './userReducer'
import cartReducer from './cartReducer'
import uiReducer from './uiReducer'
import languageReducer from './languageReducer'
import optionsReducer from './optionsReducer'
import postsReducer from './postsReducer'

const rootReducer = combineReducers({
	pages: pagesReducer,
	products: productsReducer,
	singleRemedies: singleRemediesReducer,
	browserLanugages: browserLanguagesReducer,
	popup: popupReducer,
	user: userReducer,
	cart: cartReducer,
	ui: uiReducer,
	language: languageReducer,
	options: optionsReducer,
	posts: postsReducer
})

export default rootReducer
