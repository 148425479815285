import { fr } from './translations/fr'
import { en } from './translations/en'
import { de } from './translations/de'
import { it } from './translations/it'

const translations = {
      en,
      fr,
      de,
      it
}

export default translations
