import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'
import Reveal from 'react-reveal/Reveal'

const HistoryElement = ({ element, index }) => {
    const layout = (index + 1) % 2 === 0 ? 'layout_2' : 'layout_1'
    return (
        <Reveal effect="custom_fade_in" key={shortid.generate()}>
			<div className={`history_element ${layout}`}>
				<div
					className="image"
					style={{
						backgroundImage: `url(${element.image.hasOwnProperty(
							'sizes'
						) && element.image.sizes.medium_large})`
					}}
				/>
				<div className="year">{element.year}</div>
				<div className="text_box">
					<h2>{element.title}</h2>
					<p>{element.text}</p>
				</div>
				<div className="clearfix" />
			</div>
		</Reveal>
    )
}

const HistoryDisplay = ({ apiElement }) => {
    const myDate = new Date()
    const year = myDate.getFullYear()

    return (
        <div className="history_display_wrapper">
			<div className="history_display">
				<div className="line" />
				{ /*<div className="year founded">{apiElement.founded_date}</div> */ }
				<div className="year today">{year}</div>
				{apiElement.history_events.map((element, index) => (
					<HistoryElement
						key={shortid.generate()}
						element={element}
						index={index}
					/>
				))}
			</div>
		</div>
    )
}

HistoryDisplay.defaultProps = {
    apiElement: {}
}
HistoryDisplay.propTypes = {
    apiElement: PropTypes.object // eslint-disable-line
}

export default HistoryDisplay