import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'

import { hideSearch } from '../actions/uiActions'
import _t from '../utils/translate'
import normalizeString from '../utils/normalizeString'
import RoutesForSearch from '../utils/RoutesForSearch'

class Search extends React.Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.state = {
      searchStr: '',
      searchResults: {
        homeopathy: [],
        phytotherapy: [],
        gemmotherapy: [],
        oligotherapy: [],
        pages: [],
        nrEntries: 0,
      },
      alreadyClicked: false,
      selectionCursor: 0,
    }
    this.onKeyDown = this.onKeyDown.bind(this)
    this.goToResult = this.goToResult.bind(this)
    this.componentGotVisible = this.componentGotVisible.bind(this)
    this.componentGotInvisible = this.componentGotInvisible.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    let searchResults = {
      homeopathy: [],
      phytotherapy: [],
      gemmotherapy: [],
      oligotherapy: [],
      pages: [],
      nrEntries: 0,
    }
    const { searchStr } = this.state

    // ----------------------- THE COMPONENT GOT VISIBLE ----------------------- //
    if (!prevProps.visible && this.props.visible) {
      this.componentGotVisible()
    }

    // ----------------------- THE COMPONENT GOT INVISIBLE ----------------------- //
    if (prevProps.visible && !this.props.visible) {
      this.componentGotInvisible()
    }

    // ----------------------- SEARCH FOR RESULTS IF SEARCHSTRING IS LONG ENOUGH AND CHANGED ----------------------- //
    if (prevState.searchStr !== searchStr && searchStr.length > 1) {
      searchResults = this.doSearch()
      this.setState({ searchResults, selectionCursor: 0 })
    }
    if (prevState.searchStr !== searchStr && searchStr.length < 2) {
      this.setState({ searchResults })
    }
  }

  onChange = (e) => {
    this.setState({ searchStr: e.target.value })
  }

  onKeyDown(e) {
    const { hideSearch } = this.props
    const { searchResults, selectionCursor } = this.state
    // console.log(e.keyCode)
    if (e.keyCode === 27) {
      hideSearch()
    }
    if (e.keyCode === 40 && selectionCursor < searchResults.nrEntries) {
      this.setState((oldState) => ({
        selectionCursor: oldState.selectionCursor + 1,
      }))
    }
    if (e.keyCode === 38 && selectionCursor > 0) {
      this.setState((oldState) => ({
        selectionCursor: oldState.selectionCursor - 1,
      }))
    }

    if (e.keyCode === 13 && selectionCursor > 0) {
      this.goToResult(selectionCursor)
    }

    if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 13)
      e.preventDefault()
  }

  doSearch = () => {
    const { searchStr } = this.state
    const {
      singleHomeopathy,
      singlePhytotherapy,
      singleGemmotherapy,
      singleOligotherapy,
      singleRemediesLoading,
      language,
    } = this.props
    const searchResults = {
      homeopathy: [],
      phytotherapy: [],
      gemmotherapy: [],
      oligotherapy: [],
      pages: [],
    }
    let selectionIndex = 1

    if (singleRemediesLoading) return searchResults

    // ----------------------- PAGES RESULTS ----------------------- //
    RoutesForSearch.map((element) => {
      const index = normalizeString(
        _t(element.title, 'pages')?.toLowerCase()
      )?.indexOf(normalizeString(searchStr.toLowerCase()))
      if (typeof index !== 'undefined' && index !== -1 && searchStr !== '') {
        searchResults.pages.push({
          ...element,
          link:
            element?.url?.length > 0
              ? `/${language}/${element.url
                  .map((urlPart) => _t(urlPart, 'routes') + '/')
                  .join('')}`
              : '',
          title: _t(element.title, 'pages'),
          selectionIndex,
          type: 'page',
        })
        selectionIndex += 1
      }
      return null
    })

    // ----------------------- SINGLE HOMEPATHY RESULTS ----------------------- //

    Object.values(singleHomeopathy).map((element) => {
      const index = element.nm.toLowerCase().indexOf(searchStr.toLowerCase())
      if (
        index !== -1 &&
        searchStr !== '' &&
        searchResults.homeopathy.length < 5
      ) {
        searchResults.homeopathy.push({
          ...element,
          selectionIndex,
          type: 'homeopathy',
        })
        selectionIndex += 1
      }
      return null
    })

    // ----------------------- PHYTOHTERAPY RESULTS ----------------------- //

    Object.values(singlePhytotherapy).map((element) => {
      const index = element.nm.toLowerCase().indexOf(searchStr.toLowerCase())
      if (
        index !== -1 &&
        searchStr !== '' &&
        searchResults.phytotherapy.length < 5
      ) {
        searchResults.phytotherapy.push({
          ...element,
          selectionIndex,
          type: 'phytotherapy',
        })
        selectionIndex += 1
      }
      return null
    })

    // ----------------------- GEMMOTHERAPY RESULTS ----------------------- //

    Object.values(singleGemmotherapy).map((element) => {
      const index = element.nm.toLowerCase().indexOf(searchStr.toLowerCase())
      if (
        index !== -1 &&
        searchStr !== '' &&
        searchResults.gemmotherapy.length < 5
      ) {
        searchResults.gemmotherapy.push({
          ...element,
          selectionIndex,
          type: 'gemmotherapy',
        })
        selectionIndex += 1
      }
      return null
    })

    Object.values(singleOligotherapy).map((element) => {
      const name = !element[language] ? element.fr : element[language]

      const index = name.toLowerCase().indexOf(searchStr.toLowerCase())
      if (
        index !== -1 &&
        searchStr !== '' &&
        searchResults.oligotherapy.length < 5
      ) {
        searchResults.oligotherapy.push({
          ...element,
          selectionIndex,
          nm: name,
          type: 'oligotherapy',
        })
        selectionIndex += 1
      }
      return null
    })
    searchResults.nrEntries = selectionIndex - 1
    return searchResults
  }

  goToResult = (selectionIndex) => {
    const { history, hideSearch, language } = this.props
    const { alreadyClicked, searchResults } = this.state
    const allSearchResults = [
      ...searchResults.pages,
      ...searchResults.homeopathy,
      ...searchResults.phytotherapy,
      ...searchResults.gemmotherapy,
      ...searchResults.oligotherapy,
    ]
    const selectedResult = _.find(allSearchResults, {
      selectionIndex: selectionIndex,
    })

    if (!alreadyClicked && selectedResult) {
      this.setState({ alreadyClicked: true })
      if (selectedResult.type === 'page') {
        history.push(selectedResult.link)
        console.log(`Go to Page: ${selectedResult.link}`)
      }
      if (selectedResult.type === 'homeopathy') {
        history.push(
          `/${language}/${_t('shop', 'routes')}/${_t(
            'homeopathy',
            'routes'
          )}/${_t('remedies', 'routes')}/~(a~${selectedResult.id})`
        )
        console.log(`Go to Remedy: ${selectedResult.nm}`)
      }
      if (selectedResult.type === 'phytotherapy') {
        console.log(`Go to Phytotherapy: ${selectedResult.nm}`)
        history.push(
          `/${language}/${_t('shop', 'routes')}/${_t(
            'phytotherapy',
            'routes'
          )}/~(a~${selectedResult.id})`
        )
      }
      if (selectedResult.type === 'gemmotherapy') {
        console.log(`Go to Gemmotherapy: ${selectedResult.nm}`)
        history.push(
          `/${language}/${_t('shop', 'routes')}/${_t(
            'gemmotherapy',
            'routes'
          )}/~(a~${selectedResult.id})`
        )
      }
      if (selectedResult.type === 'oligotherapy') {
        console.log(`Go to Oligotherapy: ${selectedResult.nm}`)
        history.push(
          `/${language}/${_t('shop', 'routes')}/${_t(
            'oligotherapy',
            'routes'
          )}/~(a~${selectedResult.id})`
        )
      }
      hideSearch()
    }
    // console.log('Go to Result')
    // console.log(selectionIndex)
  }

  componentGotVisible() {
    this.searchInput.focus()
    this.setState({ searchStr: '', alreadyClicked: false })
    document.body.addEventListener('keydown', this.onKeyDown)
  }

  componentGotInvisible() {
    document.body.removeEventListener('keydown', this.onKeyDown)
  }

  render() {
    const { searchStr, searchResults, selectionCursor } = this.state
    const { hideSearch, visible } = this.props
    return (
      <div className={`search_display ${visible ? 'visible' : ''}`}>
        <button
          type="button"
          className="home_link white"
          onClick={hideSearch}
        />
        <div className="search_bar">
          <input
            ref={(input) => {
              this.searchInput = input
            }}
            type="text"
            onChange={this.onChange}
            value={searchStr}
          />
        </div>
        <div className="search_results">
          {
            // <p>Cursor: {selectionCursor}</p>
            // <p>Nr Entries: {searchResults.nrEntries}</p>
          }
          {searchResults.pages.length > 0 && (
            <h2>
              <div className="icon white icon_pages" />
              {_t('Pages')}
            </h2>
          )}
          {searchResults.pages.map((element) => {
            const { title, slug, selectionIndex, link } = element
            return (
              <button
                className={`link ${
                  selectionIndex === selectionCursor ? 'focus' : ''
                }`}
                type="button"
                data-url={link}
                key={`page_${slug}`}
                onClick={this.goToResult.bind(this, selectionIndex)}
              >
                {title}
              </button>
            )
          })}

          {searchResults.homeopathy.length > 0 && (
            <h2>
              <div className="icon white icon_homeopathy" />
              {_t('Homeopathy')}
            </h2>
          )}
          {searchResults.homeopathy.map((element) => {
            const { nm, id, selectionIndex } = element
            return (
              <button
                className={`link ${
                  selectionIndex === selectionCursor ? 'focus' : ''
                }`}
                type="button"
                key={`homeo_${id}`}
                onClick={this.goToResult.bind(this, selectionIndex)}
              >
                {nm}
              </button>
            )
          })}
          {searchResults.phytotherapy.length > 0 && (
            <h2>
              <div className="icon white icon_phytotherapy" />
              {_t('Phytotherapy')}
            </h2>
          )}
          {searchResults.phytotherapy.map((element) => {
            const { nm, id, selectionIndex } = element
            return (
              <button
                className={`link ${
                  selectionIndex === selectionCursor ? 'focus' : ''
                }`}
                type="button"
                key={`phyto_${id}`}
                onClick={this.goToResult.bind(this, selectionIndex)}
              >
                {nm}
              </button>
            )
          })}

          {searchResults.gemmotherapy.length > 0 && (
            <h2>
              <div className="icon white icon_gemmotherapy" />
              {_t('Gemmotherapy')}
            </h2>
          )}
          {searchResults.gemmotherapy.map((element) => {
            const { nm, id, selectionIndex } = element
            return (
              <button
                className={`link ${
                  selectionIndex === selectionCursor ? 'focus' : ''
                }`}
                type="button"
                key={`gemmo_${id}`}
                onClick={this.goToResult.bind(this, selectionIndex)}
              >
                {nm}
              </button>
            )
          })}

          {searchResults.oligotherapy.length > 0 && (
            <h2>
              <div className="icon white icon_oligotherapy" />
              {_t('Oligotherapy')}
            </h2>
          )}
          {searchResults.oligotherapy.map((element) => {
            const { nm, id, mg, selectionIndex } = element
            return (
              <button
                className={`link ${
                  selectionIndex === selectionCursor ? 'focus' : ''
                }`}
                type="button"
                key={`oligo_${id}`}
                onClick={this.goToResult.bind(this, selectionIndex)}
              >
                {nm}
                {` ${mg} mg/l`}
              </button>
            )
          })}

          {searchStr.length > 0 && searchStr.length < 2 && (
            <p className="type_more">
              {_t('Type more to see results...', 'shop')}
            </p>
          )}
        </div>
        <button type="button" className="close" onClick={hideSearch}>
          <div className=" icon icon_close white" />
        </button>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  visible: state.ui.searchVisible,
  singleHomeopathy: state.singleRemedies.singleRemedies,
  singlePhytotherapy: state.singleRemedies.phytotherapy,
  singleGemmotherapy: state.singleRemedies.gemmotherapy,
  singleOligotherapy: state.singleRemedies.oligotherapy,
  singleRemediesLoading: state.singleRemedies.loading,
  language: state.ui.language,
  // pages: state.pages
})

export default connect(mapStateToProps, { hideSearch })(withRouter(Search))
