import React from 'react'
import PropTypes from 'prop-types'

const TeamMember = ({ apiElement }) => {
	return (
		<div className="team_member_wrapper">
			<div className={`team_member ${apiElement.layout}`}>
				<div
					className="image"
					style={{
						backgroundImage: `url(${apiElement.image.sizes.large})`
					}}
				/>
				<div className="info">
					<h2 className="name">{apiElement.name}</h2>
					<p className="description">{apiElement.description}</p>
				</div>
				<div className="clearfix" />
			</div>
		</div>
	)
}

TeamMember.defaultProps = {
	apiElement: {}
}
TeamMember.propTypes = {
	apiElement: PropTypes.object // eslint-disable-line
}

export default TeamMember
