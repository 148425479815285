import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const WebinarBanner = ({ apiElement }) => {
	let linkType = 'none'
	if (apiElement.button_text && apiElement.link_external)
		linkType = 'external'
	if (apiElement.button_text && !apiElement.link_external)
		linkType = 'internal'

	return (
		<div className="webinar_banner">
			<h3>{apiElement.heading}</h3>
			<p>{apiElement.text}</p>
			{linkType === 'external' && (
				<a
					className="button white outline hover_white"
					href={apiElement.link}
					rel="noopener noreferrer"
					target="_blank"
				>
					{apiElement.button_text}
				</a>
			)}
			{linkType === 'internal' && (
				<Link
					className="button white outline hover_white"
					to={apiElement.link}
				>
					{apiElement.button_text}
				</Link>
			)}
		</div>
	)
}

WebinarBanner.propTypes = {}

export default WebinarBanner
