// import normalizeString from './normalizeString'

const normalizeString = string => {
	console.log(string)
	console.log(string.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
	string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

const urlFormat = input => {
	if (input === null || input === undefined) return ''
	// const result = input
	const result = input
		.trim()
		.toLowerCase()
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '')
		// .replace(/[\u0300-\u036f]/g, '')
		.replace(/\s/g, '_')
		.replace(/[^a-z0-9_]/gi, '-')
	// console.log(result)
	return result

	// .toLowerCase()
}

export default urlFormat
