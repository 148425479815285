import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import _ from 'lodash'

import BlocksRenderer from './BlocksRenderer'
import Footer from './Footer'

const SinglePageRenderer = ({
  apiContent,
  loading,
  paddingTop,
  pageId,
  title,
}) => {
  // If loading, show a standard title
  let metaTitle = 'Laboratoire homéopathique Schmidt-Nagel'

  // If loading is finished and the page Title is found, use it in the title
  if (!loading && apiContent[pageId]?.title?.rendered) {
    metaTitle = `${apiContent[pageId]?.title?.rendered} - Laboratoire homéopathique Schmidt-Nagel`
  }

  // If there was a title set in yoast, use this title instead
  if (!loading && apiContent[pageId]?.yoast_meta) {
    const yoastMeta = _.find(
      apiContent[pageId]?.yoast_meta,
      (property) => property.property === 'og:title'
    )
    if (yoastMeta?.content) {
      metaTitle = yoastMeta.content
    }
  }

  let seoDescription = null

  // if (apiContent[pageId]?.acf?.seo_description) {
  // seoDescription = apiContent[pageId].acf.seo_description
  // }

  if (!loading && apiContent[pageId]?.yoast_meta) {
    // console.log('apiContent[pageId]?.yoast_meta')
    // console.log(apiContent[pageId]?.yoast_meta)
    const yoastMetaDescription = _.find(
      apiContent[pageId]?.yoast_meta,
      (property) => property?.name === 'description'
    )
    // console.log(yoastMetaDescription)
    if (yoastMetaDescription?.content) {
      seoDescription = yoastMetaDescription.content
    }
  }

  return (
    <div className={`page_content padding_top_${paddingTop}`}>
      <Helmet>
        <title>{metaTitle}</title>
        {seoDescription && <meta name="description" content={seoDescription} />}
      </Helmet>

      <BlocksRenderer apiContent={apiContent[pageId]} loading={loading} />
      <Footer />
    </div>
  )
}

SinglePageRenderer.defaultProps = {
  apiContent: { acf: {} },
  paddingTop: 140,
  title: 'SN',
}

SinglePageRenderer.propTypes = {
  apiContent: PropTypes.shape({ acf: PropTypes.object }), // eslint-disable-line
  loading: PropTypes.bool.isRequired,
  paddingTop: PropTypes.number,
  pageId: PropTypes.string.isRequired,
  title: PropTypes.string,
}

const mapStateToProps = (state) => ({
  loading: state.pages.loading,
  apiContent: state.pages,
})

export default connect(mapStateToProps)(SinglePageRenderer)
