import React from 'react'
import _ from 'lodash'

import findPriceCategory from './findPriceCategory'
import { store } from '../store/store'

const makeYourOwnCalcPrice = (state, debug = false) => {
  const debugInfo = []
  const { products } = store.getState().products
  const returnData = { price: null }
  const {
    data: { selectedForm, selectedCarrier, selectedSize, substances },
  } = state

  // ############################################
  // Get Separate Objects for each type
  // ############################################

  const finishedSubstances = _.filter(substances, { finished: true })

  const DhSubstances = _.filter(finishedSubstances, { scale: 'DH' })
  const ChSubstances = _.filter(finishedSubstances, { scale: 'CH' })
  // const KSubstances = _.filter(finishedSubstances, { scale: 'K' })
  const PhytoSubstances = _.filter(finishedSubstances, { type: 'Phytotherapy' })
  const GemmoSubstances = _.filter(finishedSubstances, { type: 'Gemmotherapy' })

  // ############################################
  // --- If form is not filled out completely abort price calculation ---
  // ############################################

  if (
    !selectedSize ||
    !selectedForm ||
    Object.keys(finishedSubstances).length === 0 ||
    (selectedForm === 'Drops' &&
      !selectedCarrier &&
      Object.keys(PhytoSubstances).length === 0 &&
      Object.keys(GemmoSubstances).length === 0)
  )
    return null

  // if (!selectedSize || !selectedForm || Object.keys(substances).length === 0)
  // 	return null

  // ############################################
  // --- 1. Check if substance is creme, trituration or supplement and if so find product ---
  // ############################################

  if (
    selectedForm !== 'Granules' &&
    selectedForm !== 'Globuli' &&
    selectedForm !== 'Drops' &&
    Object.keys(finishedSubstances).length > 0
  ) {
    // console.log('no CH / DH calculation needed: ')
    // console.log(substances)

    const slug = `combined-${selectedForm}-${selectedSize}`
      .split(' ')
      .join('-')
      .toLowerCase()

    const product = products.find((element) => {
      return element.slug === slug
    })

    returnData.price = product
      ? parseFloat(Math.round(product.price * 100) / 100)
      : null
    returnData.product = product
    returnData.slug = slug

    debugInfo.push(
      `Product is ${selectedForm}, therefore there is no further price calculation.`
    )
    debugInfo.push(
      `Using Price for ${selectedForm} ${selectedSize} which is: ${returnData.price}`
    )
    if (!debug) return returnData
  }

  // ############################################
  // --- If there is a Phytotherapy substance and the form is Drops calculate the price ---
  // ############################################

  let PhytoProduct = {}
  let PhytoSlug = ''

  console.log(selectedForm)
  console.log(Object.keys(PhytoSubstances))

  if (selectedForm === 'Drops' && Object.keys(PhytoSubstances).length > 0) {
    console.log('Phyto Present')
    PhytoSlug = `combined-phytotherapy-${selectedForm}-${selectedSize}`
      .split(' ')
      .join('-')
      .toLowerCase()
    console.log(PhytoSlug)
    PhytoProduct = products.find((element) => {
      return element.slug === PhytoSlug
    })

    const phytoPriceDebug = parseFloat(
      Math.round(PhytoProduct.price * 100) / 100
    )

    debugInfo.push(
      `There is a Phytotherapy Substance present. The Price for this in ${selectedForm} ${selectedSize} would be: ${phytoPriceDebug}`
    )

    console.log(PhytoSlug)
    console.log(PhytoProduct)
  }

  // ############################################
  // --- If there is a Gemmotherapy substance and the form is Drops calculate the price ---
  // ############################################

  let GemmoProduct = {}
  let GemmoSlug = ''

  if (selectedForm === 'Drops' && Object.keys(GemmoSubstances).length > 0) {
    // console.log('Gemmo Present')
    GemmoSlug = `combined-gemmotherapy-${selectedForm}-${selectedSize}`
      .split(' ')
      .join('-')
      .toLowerCase()

    GemmoProduct = products.find((element) => {
      return element.slug === GemmoSlug
    })

    // console.log(GemmoSlug)
    // console.log(GemmoProduct)

    const gemmoPriceDebug = parseFloat(
      Math.round(GemmoProduct.price * 100) / 100
    )

    debugInfo.push(
      `There is a Gemmotherapy Substance present. The Price for this in ${selectedForm} ${selectedSize} would be: ${gemmoPriceDebug}`
    )
  }

  // ############################################
  // --- 2. If Product is Granule, Globuli or Drops find Price Categories ---
  // ############################################

  let HomeoProduct = {}
  let HomeoSlug = ''

  if (
    Object.keys(DhSubstances).length > 0 ||
    Object.keys(ChSubstances).length > 0
  ) {
    debugInfo.push(`There is a Hoemoapathy Substance present. `)
    console.log(`There is a Hoemoapathy Substance present. `)
    // ############################################
    //  --- 2.1 - Find DH Price Category ---
    // ############################################

    let DhDilutions = []
    Object.values(DhSubstances).map((value) => {
      if (value.dilution)
        DhDilutions.push(parseInt(value.dilution.split('DH').join(''), 10))
      return null
    })

    const MaxDhDilution = Math.max(...DhDilutions)
    const DhPriceCategory = findPriceCategory('DH', `${MaxDhDilution}DH`)
    const DhNrSubstances = DhSubstances.length

    // ############################################
    // --- 2.2 - Find CH Price Category ---
    // ############################################

    let ChDilutions = []

    Object.values(ChSubstances).map((value) => {
      if (value.dilution)
        ChDilutions.push(parseInt(value.dilution.split('CH').join(''), 10))
      return null
    })

    const MaxChDilution = Math.max(...ChDilutions)
    const ChPriceCategory = findPriceCategory('CH', `${MaxChDilution}CH`)

    const HomeoPriceCategory = Math.max(ChPriceCategory, DhPriceCategory)
    // const HomeoKPriceCategory = KPriceCategory
    const ChNrSubstances = ChSubstances.length

    if (MaxChDilution > 0) debugInfo.push(`The CH Dilution is ${MaxChDilution}`)
    if (MaxDhDilution > 0) debugInfo.push(`The DH Dilution is ${MaxDhDilution}`)

    // ############################################
    // calcualte Prices with Form
    // ############################################
    const NrHomeoSubstances = DhNrSubstances + ChNrSubstances
    // const NrKSubstances = KSubstances.length

    let substancesSlug = ''
    if (NrHomeoSubstances === 1) {
      debugInfo.push(
        `The Nr of CH/DH Substances is ${NrHomeoSubstances}, Therfore the penalty category is: 1 Substance (same as single remedy)`
      )
    }
    if (NrHomeoSubstances > 1 && NrHomeoSubstances < 5) {
      debugInfo.push(
        `The Nr of CH/DH Substances is ${NrHomeoSubstances}, Therfore the penalty category is: 2-4 Substances`
      )
    }
    if (NrHomeoSubstances > 4 && NrHomeoSubstances < 11) {
      substancesSlug = '-6-substances' // keep 6 to avoid issues with backend

      debugInfo.push(
        `The Nr of CH/DH Substances is ${NrHomeoSubstances}, Therfore the penalty category is: 5-10 Substances`
      )
    }
    if (NrHomeoSubstances > 10) {
      substancesSlug = '-11-substances'
      debugInfo.push(
        `The Nr of CH/DH Substances is ${NrHomeoSubstances}, Therfore the penalty category is: more than 10 Substances`
      )
    }

    // ############################################
    // build Slug for single remedy
    // ############################################

    if (NrHomeoSubstances === 1)
      HomeoSlug = `single-homeopathy-CH-${selectedForm}-price-${HomeoPriceCategory}-${selectedSize}`
        .split(' ')
        .join('-')
        .toLowerCase()

    // ############################################
    // build Slug for combination
    // ############################################

    if (NrHomeoSubstances > 1)
      HomeoSlug = `combined-homeopathy-CH-${selectedForm}-price-${HomeoPriceCategory}-${selectedSize}${substancesSlug}`
        .split(' ')
        .join('-')
        .toLowerCase()

    // console.log(HomeoSlug)

    HomeoProduct = products.find((element) => {
      return element.slug === HomeoSlug
    })
    // console.log(HomeoProduct)

    if (HomeoProduct && 'price' in HomeoProduct) {
      const homeoPriceDebug = parseFloat(
        Math.round(HomeoProduct.price * 100) / 100
      )

      debugInfo.push(
        `The Price for this in ${selectedForm} ${selectedSize} would be: ${homeoPriceDebug}`
      )
    }
  }

  // // ############################################
  // // --- Find K Price Category ---
  // // ############################################

  // let KDilutions = []

  // Object.values(KSubstances).map((value) => {
  //   if (value.dilution)
  //     KDilutions.push(parseInt(value.dilution.split('K').join(''), 10))
  //   return null
  // })

  // // if (MaxKDilution > 0) console.log(`The K Dilution is ${MaxKDilution}`)

  // const KPriceCategories = Object.values(KSubstances).map((value) =>
  //   findPriceCategory('K', value.dilution)
  // )

  // const KPriceCategory = Math.max(...KPriceCategories)

  // if (KPriceCategory > 0)
  //   debugInfo.push(`The K Price Category is ${KPriceCategory}`)

  // const NrKSubstances = Object.values(KSubstances).length

  // let KSlug = ''

  // // ############################################
  // // build Slug for single remedy
  // // ############################################

  // if (NrKSubstances === 1) {
  //   debugInfo.push(
  //     `The Nr of K Substances is ${NrKSubstances}, Therfore the penalty category is: 1 Substance`
  //   )

  //   KSlug = `single-homeopathy-K-${selectedForm}-price-${KPriceCategory}-${selectedSize}`
  //     .split(' ')
  //     .join('-')
  //     .toLowerCase()
  // }

  // // ############################################
  // // build Slug for combination
  // // ############################################

  // let kSubstancesSlug = ''

  // if (NrKSubstances > 1 && NrKSubstances < 6) {
  //   debugInfo.push(
  //     `The Nr of K Substances is ${NrKSubstances}, Therfore the penalty category is: 2-5 Substances`
  //   )
  // }
  // if (NrKSubstances > 5 && NrKSubstances < 11) {
  //   debugInfo.push(
  //     `The Nr of K Substances is ${NrKSubstances}, Therfore the penalty category is: 6-10 Substances`
  //   )
  //   kSubstancesSlug = '-6-substances'
  // }
  // if (NrKSubstances > 10) {
  //   debugInfo.push(
  //     `The Nr of K Substances is ${NrKSubstances}, Therfore the penalty category is: more than 10 Substances`
  //   )
  //   kSubstancesSlug = '-11-substances'
  // }

  // if (NrKSubstances > 1)
  //   KSlug = `combined-homeopathy-K-${selectedForm}-price-${KPriceCategory}-${selectedSize}${kSubstancesSlug}`
  //     .split(' ')
  //     .join('-')
  //     .toLowerCase()

  // // ############################################
  // // --- Find K Product ---
  // // ############################################

  // console.log('KSlug:')
  // console.log(KSlug)

  // if (KPriceCategory > 0) debugInfo.push(`Looking for K product: ${KSlug}`)

  // const KProduct = products.find((element) => {
  //   return element.slug === KSlug
  // })

  // console.log(KProduct)

  // if (KProduct?.price) {
  //   const kPriceDebug = parseFloat(Math.round(KProduct.price * 100) / 100)

  //   debugInfo.push(
  //     `The Price for this in ${selectedForm} ${selectedSize} would be: ${kPriceDebug}`
  //   )
  // }

  // ############################################
  // --- Compare Homeopathy, Phytotherapy and Gemmotherapy prices and return highest ---
  // ############################################

  // let HomeoPrice
  // let PhytoPrice
  // let GemmoPrice

  console.log(HomeoProduct)
  let ProductsToCompare = []

  if (HomeoProduct?.price) {
    HomeoProduct.price = parseFloat(Math.round(HomeoProduct.price * 100) / 100)
    ProductsToCompare.push(HomeoProduct)
  }

  // if (KProduct?.price) {
  //   KProduct.price = parseFloat(Math.round(KProduct.price * 100) / 100)
  //   ProductsToCompare.push(KProduct)
  // }

  if (PhytoProduct?.price) {
    PhytoProduct.price = parseFloat(Math.round(PhytoProduct.price * 100) / 100)
    ProductsToCompare.push(PhytoProduct)
  }
  if (GemmoProduct?.price) {
    GemmoProduct.price = parseFloat(Math.round(GemmoProduct.price * 100) / 100)
    ProductsToCompare.push(GemmoProduct)
  }

  const ComparedProductsArray = _.sortBy(ProductsToCompare, ['price']).reverse()

  // console.log(ComparedProductsArray)
  // console.log(
  // 	ComparedProductsArray.length > 0 ? ComparedProductsArray[0].price : ''
  // )

  // if (HomeoProduct !== '' && PhytoProduct !== '' && GemmoProduct !== '') {
  // 	if (
  // 		HomeoProduct.price > PhytoProduct.price &&
  // 		HomeoProduct.price > GemmoProduct.price
  // 	) {
  // 		returnData.price = HomeoPrice
  // 		returnData.product = HomeoProduct
  // 		returnData.slug = HomeoSlug
  // 	}

  // 	// if (PhytoPrice )
  // }

  if (ComparedProductsArray.length > 0) {
    returnData.price = ComparedProductsArray[0].price
    returnData.product = ComparedProductsArray[0]
    returnData.slug = ComparedProductsArray[0].slug

    debugInfo.push(
      `After comparing the  products the most expensive option is: ${returnData.slug}`
    )
    debugInfo.push(`The final price is: ${returnData.price}`)

    console.log(returnData.slug)
    console.log(returnData.price)

    if (!debug) return returnData
  }

  // ############################################
  // --- RETURN DEBUG INFORMATION ---
  // ############################################

  if (debug !== false) {
    return (
      <React.Fragment>
        {debugInfo.map((o, n) => (
          <p key={`debuginfo_${n}`}>{o}</p>
        ))}
      </React.Fragment>
    )
  }

  // ############################################
  // --- If form is not completely filled return null ---
  // ############################################

  return null
}

export default makeYourOwnCalcPrice
