import {
  HIDE_ALL_POPUPS,
  SHOW_MESSAGE,
  SHOW_LANGUAGE_CHOOSER,
  HIDE_MESSAGE,
  SHOW_SEARCH,
  HIDE_SEARCH,
  SHOW_MENU,
  HIDE_MENU,
  ALLOW_CHECKOUT,
  DISABLE_CHECKOUT,
} from './types'

export const hideAllPopups = () => (dispatch) => {
  dispatch({
    type: HIDE_ALL_POPUPS,
  })
}

export const hideMessage = () => (dispatch) => {
  dispatch({
    type: HIDE_MESSAGE,
  })
}

export const showMessage = (text, type, duration = 10000) => (dispatch) => {
  dispatch({
    type: SHOW_MESSAGE,
    payload: { text, type, duration },
  })
}

export const showLanguageChooser = () => (dispatch) => {
  dispatch({
    type: SHOW_LANGUAGE_CHOOSER,
  })
}

export const showSearch = () => (dispatch) => {
  dispatch({
    type: SHOW_SEARCH,
  })
}

export const hideSearch = () => (dispatch) => {
  dispatch({
    type: HIDE_SEARCH,
  })
}

export const showMenu = () => (dispatch) => {
  dispatch({
    type: SHOW_MENU,
  })
}

export const hideMenu = () => (dispatch) => {
  dispatch({
    type: HIDE_MENU,
  })
}

export const allowCheckout = () => (dispatch) => {
  console.log('allowCheckout')
  dispatch({
    type: ALLOW_CHECKOUT,
  })
}

export const disableCheckout = () => (dispatch) => {
  dispatch({
    type: DISABLE_CHECKOUT,
  })
}
