import React from 'react'
import JuicerFeed from '../JuicerFeed'
import { BrowserView, MobileView } from 'react-device-detect'

const SocialFeed = (apiElement) => {
  return (
    <div className="social_feed">
      <div className="social_feed_wrapper">
        {apiElement?.title && apiElement?.title !== '' && (
          <h1>{apiElement.title}</h1>
        )}

        <BrowserView>
          <JuicerFeed feedId="sn" nrPosts={6} />
        </BrowserView>

        <MobileView>
          <JuicerFeed feedId="sn" nrPosts={2} />
        </MobileView>
      </div>
    </div>
  )
}

export default SocialFeed
