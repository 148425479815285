import { SET_LANGUAGE_MANUALLY } from '../actions/types'

const initialState = {
    chosenLanguage: null
}

export default function(state = initialState, action) {
    switch (action.type) {
        case SET_LANGUAGE_MANUALLY:
            return { ...state, chosenLanguage: action.payload }
        default:
            return state
    }
}
