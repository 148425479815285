import React from 'react'
// import PropTypes from 'prop-types'
// import { Form, Button, Message } from 'semantic-ui-react'
import { connect } from 'react-redux'

// import InlineError from '../messages/InlineError'

import { updateUserData } from '../actions/userActions'
import SingleField from './SingleFormField'
import PasswordField from './PasswordField'
// import { Link } from 'react-router-dom'
// import _t from '../utils/translate'

import { showMessage } from '../actions/uiActions'

class SecurityForm extends React.Component {
	state = {
		loading: false
	}

	constructor(props) {
		super(props)
		this.onSave = this.onSave.bind(this)
	}

	// componentDidMount() {
	// 	const { userData } = this.props
	// 	this.setState({
	// 		data: {
	// 			...userData
	// 		}
	// 	})
	// }

	// onChange = e =>
	// 	this.setState({
	// 		data: { ...this.state.data, [e.target.name]: e.target.value },
	// 		errors: { ...this.state.errors, [e.target.name]: false }
	// 	})

	onSave(data) {
		// this.props.showLoading()
		const { updateUserData, showMessage } = this.props

		updateUserData(data)
			.then(res => {
				console.log(res)
			})
			.catch(err => showMessage(err.response.data.message, 'error'))
	}

	render() {
		const {
			onSave,
			props: { userData }
		} = this
		return (
			<div className="security_form account_form">
				<SingleField
					field={{
						name: 'login',
						data: userData.login,
						label: 'Email'
					}}
					onSave={onSave}
				/>
				<SingleField
					field={{
						name: 'billingPhone',
						data: userData.billingPhone,
						label: 'Phone'
					}}
					onSave={onSave}
				/>
				<PasswordField
					field={{
						name: 'password',
						label: 'Password'
					}}
					passwordLength={userData.passwordLength}
					onSave={onSave}
				/>

				<div className="clearfix" />
			</div>
		)
	}
}

SecurityForm.propTypes = {
	// submit: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
	userData: state.user
})

export default connect(mapStateToProps, { updateUserData, showMessage })(
	SecurityForm
)
