import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import shortid from 'shortid'
import _ from 'lodash'

import Spinner from '../Spinner'
import OtcSingle from './OtcSingle'
import _t from "../../utils/translate";

const OtcDisplay = ({ apiElement, products, language }) => {
    // const sortedSchussler = _.sortBy(schusslerSalts, 'srt')
    if (products.loading) {
        return <Spinner />
    }

    const linkBase = `/${language}/${_t('shop', 'routes')}/otc`;

    if (apiElement.otc_category === 'homeopathy') apiElement.otc_category = 'otchomeo';

    const filteredProducts = Object.values(products.products).filter(
        element => {
            return element.categories && element.categories.find(cat => cat.slug === 'otc') && element.categories.find(cat => cat.slug === apiElement.otc_category);
        }
    )

    // console.log(filteredProducts)
    const sortedProducts = _.orderBy(filteredProducts, e =>
        parseInt(e.acf.order_index, 10)
    )
    return (
        <div className="otc_holder">
            {!products.loading &&
                sortedProducts.map(product => (
                    <OtcSingle
                        linkBase={linkBase}
                        key={shortid.generate()}
                        product={product}
                    />
                ))}
            <div className="clearfix" />
        </div>
    )
}
//

const mapStateToProps = state => ({
    products: state.products,
    language: state.ui.language
})

export default connect(mapStateToProps)(withRouter(OtcDisplay))
