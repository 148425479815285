import React from 'react'
// import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Spinner from '../Spinner'
import _t from '../../utils/translate'

const Slide = ({ product, style, language, index, activeSlide }) => {
	const shortDescription = product.acf[`description_short_${language}`]
	const title = product.acf[`title_${language}`]
	const link = `/${language}/${_t('shop', 'routes')}/${_t(
		'homeopathy',
		'routes'
	)}/${_t('products', 'routes')}/${product.slug}`
	return (
		<div className="slide" style={style}>
			<Link
				to={link}
				className="topic_image"
				style={{
					// eslint-disable-next-line
					backgroundImage: `url(${product.acf &&
						product.acf.hasOwnProperty('topic_image') &&
						product.acf.topic_image.hasOwnProperty('sizes') &&
						product.acf.topic_image.sizes.large})`
				}}
			/>
			<Link
				to={link}
				className={`info ${index === activeSlide ? 'active' : ''}`}
			>
				<div
					className="product_image"
					style={{
						// eslint-disable-next-line
						backgroundImage: `url(${product.acf &&
							product.acf.hasOwnProperty('product_image') &&
							product.acf.product_image.hasOwnProperty('sizes') &&
							product.acf.product_image.sizes.large})`
					}}
				/>
				<div className="title_box">
					<span className="title">{title}</span>
					{product.acf && product.acf.number_remedies && (
						<span className="nr_remedies">
							{product.acf.number_remedies} {_t('Remedies')}
						</span>
					)}
				</div>

				<div className="description">
					<p>{shortDescription}</p>
				</div>
			</Link>
		</div>
	)
}

// const Info = ({ activeSlide, product, index, language }) => {
// 	// console.log(language)
// 	// console.log(product)

// 	const shortDescription = product.acf[`description_short_${language}`]
// 	const title = product.acf[`title_${language}`]

// 	// console.log('title')
// 	// console.log(title)
// 	return null
// 	//    return (
// 	//        <div className={`info ${index === activeSlide ? 'active' : ''}`}>
// 	// 	<div className="title_box">
// 	// 		<Link
// 	// 			className="title h2"
// 	// 			to={`/${language}/${_t('shop','routes')}/${_t('homeopathy','routes')}/${_t('products','routes')}/${product.slug}`}
// 	// 		>
// 	// 			{title}
// 	// 		</Link>
// 	// 		{product.acf && product.acf.number_remedies && (
// 	// 			<span className="nr_remedies">
// 	// 				{product.acf.number_remedies} {_t('Remedies')}
// 	// 			</span>
// 	// 		)}
// 	// 	</div>

// 	// 	<div className="description">
// 	// 		<p>{shortDescription}</p>
// 	// 	</div>
// 	// 	<div
// 	// 		className="product_image"
// 	// 		style={{
// 	// 			// eslint-disable-next-line
// 	// 			backgroundImage: `url(${product.acf &&
// 	// 				product.acf.hasOwnProperty('product_image') &&
// 	// 				product.acf.product_image.hasOwnProperty('sizes') &&
// 	// 				product.acf.product_image.sizes.large})`
// 	// 		}}
// 	// 	/>
// 	// </div>
// 	//    )
// }

class KitsSlider extends React.Component {
	constructor(props) {
		super(props)
		this.nextSlide = this.nextSlide.bind(this)
		this.lastSlide = this.lastSlide.bind(this)
		this.state = {
			activeSlide: 1
		}
	}

	nextSlide() {
		const { activeSlide } = this.state
		const { products } = this.props
		const newSlide = activeSlide < products.length ? activeSlide + 1 : 1

		this.setState({
			activeSlide: newSlide
		})
	}

	lastSlide() {
		const { activeSlide } = this.state
		const { products } = this.props
		const newSlide = activeSlide > 1 ? activeSlide - 1 : products.length
		this.setState({
			activeSlide: newSlide
		})
	}

	render() {
		const { products, language, loading } = this.props
		const { activeSlide } = this.state
		// console.log(language)
		return (
			<div className="kits_slider_element">
				<div className="kits_slider_holder">
					<div
						className="kits_slider"
						style={{
							width: `${products.length * 100}%`,
							left: `-${(activeSlide - 1) * 100}%`
						}}
					>
						{products.map((product, index) => (
							<Slide
								key={`slide_${product.slug}`}
								style={{ width: `${100 / products.length}%` }}
								product={product}
								index={index + 1}
								activeSlide={activeSlide}
								language={language}
							/>
						))}
						<div className="clearFix" />
					</div>
					<div className="nav_button_holder left">
						<button
							className="nav_button left"
							onClick={this.lastSlide}
							type="button"
						>
							<div className="icon icon_back" />
							<div className="icon icon_back active" />
						</button>
					</div>

					<div className="nav_button_holder right">
						<button
							className="nav_button right"
							onClick={this.nextSlide}
							type="button"
						>
							<div className="icon icon_forward" />
							<div className="icon icon_forward active" />
						</button>
					</div>
				</div>

				<div className={`loading ${loading ? 'active' : ''}`}>
					<Spinner size="small" />
				</div>
			</div>
		)
	}
}
KitsSlider.propTypes = {}

const mapStateToProps = state => ({
	language: state.ui.language
})

export default connect(mapStateToProps)(KitsSlider)
