import React from 'react'
import shortid from 'shortid'
import _ from 'lodash'
import { connect } from 'react-redux'

import _t from '../../utils/translate'
import QuantityField from '../../utils/QuantityField'

import SelectionButton from '../SelectionButton'
import MakeYourOwnSingleSubstance from './MakeYourOwnSingleSubstance'
import Spinner from '../Spinner'

import makeYourOwnCalcPrice from '../../utils/makeYourOwnCalcPrice'
import formatPrice from '../../utils/formatPrice'
import urlFormat from '../../utils/urlFormat'
import { addToCart } from '../../actions/cartActions'

// turn on and off Debug info in Summary
const DEBUG = false

class MakeYourOwnDisplay extends React.Component {
  static propTypes = {
    // name: React.PropTypes.string
  }

  constructor(props) {
    super(props)
    this.addToCart = this.addToCart.bind(this)
    this.addSubstance = this.addSubstance.bind(this)
    this.changeSubstance = this.changeSubstance.bind(this)
    this.setForm = this.setForm.bind(this)
    // this.setDilution = this.setDilution.bind(this)
    this.setCarrier = this.setCarrier.bind(this)
    this.changeName = this.changeName.bind(this)
    this.calcPrice = this.calcPrice.bind(this)
    this.updateUi = this.updateUi.bind(this)
    this.changeQuantity = this.changeQuantity.bind(this)

    this.state = {
      product: null,
      error: null,
      data: {
        name: null,
        selectedForm: null,
        selectedCarrier: null,
        selectedSize: null,
        substances: {},
        quantity: 1,
      },
      granulesEnabled: false,
      globuliEnabled: false,
      dropsEnabled: false,
      triturationEnabled: false,
      suppositoriesEnabled: false,
      cremeEnabled: false,
      dropsMessage: '',
      granulesMessage: '',
      globuliMessage: '',
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.data !== prevState.data) {
      this.calcPrice()
      this.updateUi()
    }
  }

  calcPrice = () => {
    this.setState((oldState) => ({
      product: makeYourOwnCalcPrice(oldState),
      debugInfo: makeYourOwnCalcPrice(oldState, 'debug'),
    }))
  }

  updateUi = () => {
    const {
      data: { substances, selectedForm, selectedCarrier },
    } = this.state

    const finishedSubstances = _.filter(substances, { finished: true })

    const DhSubstances = _.filter(finishedSubstances, { scale: 'DH' })
    const ChSubstances = _.filter(finishedSubstances, { scale: 'CH' })
    const PhytoSubstances = _.filter(finishedSubstances, {
      type: 'Phytotherapy',
    })
    const GemmoSubstances = _.filter(finishedSubstances, {
      type: 'Gemmotherapy',
    })

    let ChDilutions = []

    Object.values(ChSubstances).map((value) => {
      if (value.dilution)
        ChDilutions.push(parseInt(value.dilution.split('CH').join(''), 10))
      return null
    })

    const MaxChDilution = Math.max(...ChDilutions)

    let uiStatus = {
      granulesEnabled: true,
      globuliEnabled: true,
      dropsEnabled: true,
      triturationEnabled: true,
      carrierEnabled: true,
      suppositoriesEnabled: true,
      cremeEnabled: true,
      dropsMessage: '',
    }
    if (Object.keys(substances).length < 1) {
      uiStatus = {
        granulesEnabled: false,
        globuliEnabled: false,
        dropsEnabled: false,
        triturationEnabled: false,
        suppositoriesEnabled: false,
        cremeEnabled: false,
      }
    }
    if (
      Object.keys(DhSubstances).length + Object.keys(ChSubstances).length <
      1
    ) {
      uiStatus.granulesEnabled = false
      uiStatus.globuliEnabled = false

      uiStatus.granulesMessage = _t(
        'Granules are only available if there is at least one Homeopathy substance'
      )
      uiStatus.globuliMessage = _t(
        'Globuli are only available if there is at least one Homeopathy substance'
      )

      if (selectedForm === 'Granules' || selectedForm === 'Globuli') {
        this.setForm(null)
      }
    }

    if (MaxChDilution >= 200) {
      uiStatus.dropsEnabled = false
      uiStatus.dropsMessage = _t('Drops are only available up to 30CH')
      if (selectedForm === 'Drops') {
        this.setForm(null)
      }
    }

    if (
      Object.keys(GemmoSubstances).length > 0 ||
      Object.keys(PhytoSubstances).length > 0
    ) {
      uiStatus.granulesEnabled = false
      uiStatus.globuliEnabled = false
      uiStatus.cremeEnabled = false
      uiStatus.triturationEnabled = false
      uiStatus.suppositoriesEnabled = false
      uiStatus.carrierEnabled = false

      uiStatus.granulesMessage = _t(
        'Granules are not available if there is a Phyto- or Gemmotherapy substance'
      )
      uiStatus.globuliMessage = _t(
        'Globuli are not available if there is a Phyto- or Gemmotherapy substance'
      )

      if (selectedForm === 'Granules' || selectedForm === 'Globuli') {
        this.setForm(null)
      }
      if (selectedCarrier) this.setCarrier(null)
    }

    // console.log('UI STATUS:')
    // console.log(uiStatus)
    this.setState({ ...uiStatus })
  }

  changeName = (e) => {
    const { value } = e.target
    console.log(value)
    this.setState((oldState) => ({
      data: {
        ...oldState.data,
        name: value,
      },
    }))
  }

  changeQuantity = (quantity) =>
    this.setState((oldState) => ({ data: { ...oldState.data, quantity } }))

  addSubstance = () => {
    this.setState((oldState) => ({
      data: {
        ...oldState.data,
        substances: {
          ...oldState.data.substances,
          [shortid.generate()]: {
            type: null,
            substanceName: '',
            scale: null,
            dilution: null,
            finished: false,
          },
        },
      },
    }))
  }

  changeSubstance = (substance) => {
    this.setState((oldState) => ({
      data: {
        ...oldState.data,
        substances: { ...oldState.data.substances, ...substance },
      },
    }))
  }

  deleteSubstance = (key) => {
    this.setState((oldState) => ({
      data: {
        ...oldState.data,
        substances: { ..._.omit(oldState.data.substances, [key]) },
      },
    }))
  }

  setCarrier = (carrier) => {
    this.setState((oldState) => ({
      data: {
        ...oldState.data,
        selectedCarrier: carrier,
        selectedSize: null,
      },
    }))
  }

  setForm = (form) => {
    this.setState((oldState) => ({
      data: {
        ...oldState.data,
        selectedCarrier: null,
        selectedForm: form,
        selectedSize: null,
      },
    }))
  }

  setSize = (size) => {
    this.setState((oldState) => ({
      data: {
        ...oldState.data,
        selectedSize: size,
      },
    }))
  }

  addToCart = () => {
    const {
      product: {
        price: currentPrice,
        product: { id: currentWcId },
      },
      data: {
        name: currentName,
        substances,
        selectedForm,
        selectedCarrier,
        selectedSize,
        quantity,
      },
    } = this.state

    const substancesForCart = Object.values(substances)
      .map(
        (substance) =>
          `${substance.type}: ${substance.substanceName} ${
            substance.dilution !== null ? substance.dilution : ''
          }`
      )
      .join(`\n`)

    const { addToCart } = this.props
    const newProduct = {
      WcId: currentWcId,
      price: currentPrice,
      name: currentName ? currentName : _t('Custom Remedy', 'shop'),
      icon: 'package_bottle',
      info: {
        substances: substancesForCart,
        form: selectedForm,
        carrier: selectedCarrier,
        size: selectedSize,
      },
      quantity,
    }
    addToCart(newProduct)
  }

  getSubstanceNames = (substances) => {
    let substaceNames = ''
    Object.values(substances).map((substance) => {
      substaceNames = substaceNames.concat(substance.substanceName + ', ')
      return null
    })
    return substaceNames.slice(0, -2)
  }

  render() {
    const {
      product,
      data: {
        selectedForm,
        selectedCarrier,
        selectedSize,
        substances,
        name,
        quantity,
      },
      granulesEnabled,
      globuliEnabled,
      dropsEnabled,
      triturationEnabled,
      suppositoriesEnabled,
      cremeEnabled,
      carrierEnabled,
      dropsMessage,
      granulesMessage,
      globuliMessage,
      debugInfo,
    } = this.state

    const { changeQuantity } = this
    const finishedSubstances = _.filter(substances, { finished: true })

    const DhSubstances = _.filter(finishedSubstances, { scale: 'DH' })
    const ChSubstances = _.filter(finishedSubstances, { scale: 'CH' })
    const PhytoSubstances = _.filter(finishedSubstances, {
      type: 'Phytotherapy',
    })
    const GemmoSubstances = _.filter(finishedSubstances, {
      type: 'Gemmotherapy',
    })

    const { loading } = this.props

    const formSizeClass = `${urlFormat(selectedForm)} ${urlFormat(
      selectedSize?.replace('12', 'twelve')
    )}`

    if (loading)
      return (
        <div className="make_your_own_display_wrapper">
          <Spinner />
        </div>
      )
    return (
      <div className="make_your_own_display_wrapper">
        <div className={`icon icon_make_your_own icon_background`} />
        <div className={`product_display product_preview ${formSizeClass}`} />
        <div className="configurator">
          <h1 className="left">{_t('Substances', 'shop')}</h1>
          {Object.keys(substances).map((key, index) => (
            <MakeYourOwnSingleSubstance
              key={key}
              keyx={key}
              substance={substances[key]}
              index={index}
              changeSubstance={this.changeSubstance}
              deleteSubstance={this.deleteSubstance}
            />
          ))}
          <button type="button" onClick={this.addSubstance}>
            {_t('add')}
          </button>
          <h1 className="left">{_t('General', 'shop')}</h1>

          <div className="form section">
            <div className="label">{_t('Form', 'shop')}</div>
            <SelectionButton
              attribute={selectedForm}
              method={this.setForm}
              disabled={!granulesEnabled}
              message={granulesMessage}
              value="Granules"
              icon="granules"
            />
            <SelectionButton
              attribute={selectedForm}
              method={this.setForm}
              disabled={!globuliEnabled}
              message={globuliMessage}
              value="Globuli"
              icon="globuli"
            />
            <SelectionButton
              attribute={selectedForm}
              method={this.setForm}
              disabled={!dropsEnabled}
              message={dropsMessage}
              value="Drops"
              icon="drops"
            />
            <SelectionButton
              attribute={selectedForm}
              method={this.setForm}
              disabled={!cremeEnabled}
              value="Creme"
              icon="creme"
            />
            <SelectionButton
              attribute={selectedForm}
              method={this.setForm}
              disabled={!triturationEnabled}
              value="Trituration"
              icon="package_unknown"
            />
            <SelectionButton
              attribute={selectedForm}
              method={this.setForm}
              disabled={!suppositoriesEnabled}
              value="Suppository"
              icon="package_unknown"
            />
          </div>
          {// ----- CARRIER SECTION -----

          !loading && selectedForm === 'Drops' && carrierEnabled && (
            <div className="carrier section">
              <div className="label">{_t('Carrier', 'shop')}</div>

              <React.Fragment>
                <SelectionButton
                  attribute={selectedCarrier}
                  method={this.setCarrier}
                  value="25° AETH."
                  icon="drops"
                />
                <SelectionButton
                  attribute={selectedCarrier}
                  method={this.setCarrier}
                  value="NACL"
                  icon="drops"
                />
                <SelectionButton
                  attribute={selectedCarrier}
                  method={this.setCarrier}
                  value="E DIST."
                  icon="drops"
                />
              </React.Fragment>
            </div>
          )}

          {// ----- SIZE SECTION -----

          !loading &&
            selectedForm &&
            (selectedForm !== 'Drops' ||
              selectedCarrier ||
              !carrierEnabled) && (
              <div className="size section">
                <div className="label">{_t('Size', 'shop')}</div>
                {// GLOBULI CH/DH
                selectedForm === 'Globuli' &&
                  ChSubstances.length + DhSubstances.length > 0 && (
                    <React.Fragment>
                      <SelectionButton
                        attribute={selectedSize}
                        method={this.setSize}
                        value="Tube 1g"
                        icon="package_unknown"
                      />
                      <SelectionButton
                        attribute={selectedSize}
                        method={this.setSize}
                        value="Bottle 15g"
                        icon="package_unknown"
                      />
                    </React.Fragment>
                  )}

                {// GRANULES CH/DH
                selectedForm === 'Granules' && (
                  <React.Fragment>
                    <SelectionButton
                      attribute={selectedSize}
                      method={this.setSize}
                      value="Tube 4g"
                      icon="package_unknown"
                    />
                    <SelectionButton
                      attribute={selectedSize}
                      method={this.setSize}
                      value="Bottle 15g"
                      icon="package_unknown"
                    />
                  </React.Fragment>
                )}

                {// DROPS CH/DH
                selectedForm === 'Drops' && (
                  <React.Fragment>
                    <SelectionButton
                      attribute={selectedSize}
                      method={this.setSize}
                      value="Bottle 30ml"
                      icon="package_unknown"
                    />
                    <SelectionButton
                      attribute={selectedSize}
                      method={this.setSize}
                      value="Bottle 60ml"
                      icon="package_unknown"
                    />
                    <SelectionButton
                      attribute={selectedSize}
                      method={this.setSize}
                      value="Bottle 125ml"
                      icon="package_unknown"
                    />
                    <SelectionButton
                      attribute={selectedSize}
                      method={this.setSize}
                      value="Bottle 250ml"
                      icon="package_unknown"
                    />
                  </React.Fragment>
                )}

                {// CREME CH/DH
                selectedForm === 'Creme' && (
                  <React.Fragment>
                    <SelectionButton
                      attribute={selectedSize}
                      method={this.setSize}
                      value="50g"
                      icon="package_unknown"
                    />
                  </React.Fragment>
                )}

                {// Trituration
                selectedForm === 'Trituration' && (
                  <React.Fragment>
                    <SelectionButton
                      attribute={selectedSize}
                      method={this.setSize}
                      value="Lactose Bottle 60g"
                      icon="package_unknown"
                    />
                    <SelectionButton
                      attribute={selectedSize}
                      method={this.setSize}
                      value="Mannit Bottle 60g"
                      icon="package_unknown"
                    />
                  </React.Fragment>
                )}

                {// Suppositories
                selectedForm === 'Suppository' && (
                  <React.Fragment>
                    <SelectionButton
                      attribute={selectedSize}
                      method={this.setSize}
                      value="12 Pieces Kids"
                      icon="package_unknown"
                    />
                    <SelectionButton
                      attribute={selectedSize}
                      method={this.setSize}
                      value="12 Pieces Adults"
                      icon="package_unknown"
                    />
                  </React.Fragment>
                )}
              </div>
            )}

          {// ----- PRICE && ADD TO CART -----

          product && (
            <div className="add_to_cart">
              <h1 className="left">{_t('Summary', 'shop')}</h1>
              <div
                className={`product_picture product_preview ${formSizeClass}`}
              />
              <div className="product_info">
                <h2>{name ? name : _t('Custom Remedy', 'shop')}</h2>
                <p>{_t(selectedForm, 'shop')}</p>
                <p>{_t(selectedCarrier, 'shop')}</p>
                <p>{_t(selectedSize, 'shop')}</p>
                <p>
                  {Object.keys(finishedSubstances).length}
                  {Object.keys(finishedSubstances).length > 1
                    ? _t(' substances')
                    : _t(' substance')}
                </p>
                <p className="substances">
                  {this.getSubstanceNames(finishedSubstances)}
                </p>
                {DEBUG && (
                  <div className="debug_info">
                    <h2>Price calculation:</h2>
                    {debugInfo}
                    <p>.</p>
                  </div>
                )}
                <span className="price">
                  {product.price
                    ? formatPrice(product.price)
                    : `'${product.slug}' not found`}
                </span>
                <QuantityField quantity={quantity} onChange={changeQuantity} />
                <button
                  type="button"
                  className="buy highlight"
                  onClick={this.addToCart}
                >
                  {_t('Add to Cart', 'shop')}
                </button>
                <p>{JSON.stringify(product.PricingInfo)}</p>
              </div>
            </div>
          )}
        </div>
        <div className="saved_remedies" />
        <div className="clearfix" />
      </div>
    )
  }
}

export default connect(null, { addToCart })(MakeYourOwnDisplay)
