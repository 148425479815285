import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Validator from 'validator'

import { submitWebinarFormRequest } from '../../actions/userActions'
import { showMessage } from '../../actions/uiActions'
import Spinner from '../Spinner'
import _t from '../../utils/translate'

class WebinarForm extends React.Component {
  constructor(props) {
    super(props)
    const { user } = this.props

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

    // console.log(user)

    this.state = {
      loading: false,
      success: false,
      data: {
        name: '',
        phone: '',
        profession: '',
        name_pharmacy: '',
        fph_gln_number: '',
        email: '',
        message: '',
        agb: false,
        newsletter: true,
        ...user,
      },
      errors: {},
    }
  }

  onChange = (e) => {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      data: { ...this.state.data, [name]: value },
      errors: { ...this.state.errors, [name]: false },
    })
  }

  onSubmit = (e) => {
    const { showMessage } = this
    const { submitWebinarFormRequest, language, options } = this.props
    const { data } = this.state
    const errors = this.validate(data)
    const contactFormOptions = options.data[language].contact_form

    e.preventDefault()

    this.setState({ errors })
    if (Object.keys(errors).length !== 0) {
      showMessage(errors)
    }
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true })
      // submit(data)
      submitWebinarFormRequest({ ...data, language })
        .then((res) => {
          // console.log(res)
          this.setState({ loading: false, success: true })
          return res
        })
        .catch((err) => {
          this.setState({ loading: false })
          this.props.showMessage(
            err.response && err.response.data && err.response.data.message
              ? _t(err.response.data.message)
              : contactFormOptions?.problem_sending ||
                  _t('There was a problem sending your message.'),
            'error'
          )
        })
    }
  }

  validate = (data) => {
    const { language, options } = this.props
    const contactFormOptions = options.data[language].contact_form
    const errors = {}
    if (!data.name)
      errors.name =
        contactFormOptions?.name_missing ||
        _t(`Please enter your name.`, 'account')
    // if (!data.phone)
    //     errors.phone = _t(
    //         `Bitte geben Sie Ihre Telefonnummer an.`,
    //         'account'
    //     )
    if (!Validator.isEmail(data.email))
      errors.email =
        contactFormOptions?.email_wrong ||
        _t('Please correct your email address.', 'account')
    if (!data.email)
      errors.email =
        contactFormOptions?.email_missing ||
        _t(`Please enter your email address.`, 'account')
    if (!data.agb)
      errors.agb =
        contactFormOptions?.accept_terms ||
        _t(`You have to accept the terms and conditions.`, 'account')

    return errors
  }

  showMessage = (message) => {}

  render() {
    if (this.props.options.loading) {
      return null
    }

    const {
      language,
      apiElement: { is_background_color, anchor_name },
    } = this.props
    const contactFormOptions = this.props.options.data[language].contact_form

    // console.log(language)
    // console.log(this.props.options.data[language])

    if (!contactFormOptions || contactFormOptions.length < 1) {
      return <span>Options not found!</span>
    }
    const { data, errors, loading, success } = this.state
    const { onChange, onSubmit } = this
    return (
      <div
        className={`contact_form_wrapper ${
          is_background_color ? 'background_color' : 'background_white'
        }`}
      >
        <div className="contact_form" id={`${anchor_name}_anchor`}>
          <div className="text">
            {contactFormOptions.title?.length > 0 && (
              <h1>{contactFormOptions.title}</h1>
            )}
            {contactFormOptions?.text && <p>{contactFormOptions.text}</p>}
          </div>
          <div className="form">
            <div
              className={`form_holder ${loading ? 'loading' : ''} ${
                success ? 'success' : ''
              }`}
            >
              <form onSubmit={onSubmit}>
                <div className={`field ${errors.name ? 'error' : ''}`}>
                  <span className="error_msg">{errors.name}</span>
                  <span className="required">
                    <input
                      className={is_background_color ? 'white' : ''}
                      name="name"
                      value={data.name}
                      placeholder={`${_t('First and last name')}*`}
                      onChange={onChange}
                      type="text"
                    />
                  </span>
                </div>
                <div className={`field ${errors.email ? 'error' : ''}`}>
                  <span className="error_msg">{errors.email}</span>
                  <span className="required">
                    <input
                      className={is_background_color ? 'white' : ''}
                      name="email"
                      value={data.email}
                      placeholder={`${contactFormOptions.text_email ||
                        'Email'}*`}
                      onChange={onChange}
                      type="email"
                    />
                  </span>
                </div>
                <div className={`field ${errors.profession ? 'error' : ''}`}>
                  <span className="error_msg">{errors.profession}</span>
                  <span className="required">
                    <input
                      className={is_background_color ? 'white' : ''}
                      name="profession"
                      value={data.profession}
                      placeholder={`${_t('Profession')}*`}
                      onChange={onChange}
                      type="text"
                    />
                  </span>
                </div>

                <div className={`field ${errors.name_pharmacy ? 'error' : ''}`}>
                  <span className="error_msg">{errors.name_pharmacy}</span>
                  <span className="required">
                    <input
                      className={is_background_color ? 'white' : ''}
                      name="name_pharmacy"
                      value={data.name_pharmacy}
                      placeholder={`${_t(
                        'Name of pharmacy and customer number'
                      )}*`}
                      onChange={onChange}
                      type="text"
                    />
                  </span>
                </div>

                <div className={`field ${errors.phone ? 'error' : ''}`}>
                  <span className="error_msg">{errors.phone}</span>
                  <span className="required">
                    <input
                      className={is_background_color ? 'white' : ''}
                      name="phone"
                      value={data.phone}
                      placeholder={`${_t('Phone number')}*`}
                      onChange={onChange}
                      type="text"
                    />
                  </span>
                </div>

                <div
                  className={`field ${errors.fph_gln_number ? 'error' : ''}`}
                >
                  <span className="error_msg">{errors.fph_gln_number}</span>
                  <span className="required">
                    <input
                      className={is_background_color ? 'white' : ''}
                      name="fph_gln_number"
                      value={data.fph_gln_number}
                      placeholder={`${_t('FPH or GLN number for credits')}*`}
                      onChange={onChange}
                      type="text"
                    />
                  </span>
                </div>
                {/*
                1. Name und Vorname
                2. E-Mail-Adresse
                3. Beruf
                4. Name der Apotheke / Drogerie und ggf. Kundennummer.
                5. Telefonnummer
                6. FPH- oder GLN-Nummer für Kredit
                7. Nachricht 
                */}
                <div className={`field ${errors.message ? 'error' : ''}`}>
                  <span className="error_msg">{errors.message}</span>
                  <textarea
                    className={is_background_color ? 'white' : ''}
                    name="message"
                    value={data.message}
                    style={{}}
                    onChange={onChange}
                    rows={5}
                    placeholder={`${_t('Message')}*`}
                  />
                </div>
                <div className={`field ${errors.agb ? 'error' : ''} `}>
                  <span className="error_msg">{errors.agb}</span>
                  <input
                    type="checkbox"
                    id="agb"
                    name="agb"
                    className={is_background_color ? 'white' : ''}
                    value="agb_read"
                    checked={data.agb}
                    onChange={onChange}
                  />
                  <label
                    htmlFor="agb"
                    className={is_background_color ? 'white agb' : 'agb'}
                  >
                    {contactFormOptions.text_agb_checkbox ||
                      'I accept the terms and conditions. '}{' '}
                    (
                    <Link to={contactFormOptions.agb_link} target="_blank">
                      {contactFormOptions.agb_link_text ||
                        'Terms and Conditions'}
                    </Link>
                    )*
                  </label>
                </div>
                <input
                  type="checkbox"
                  id="newsletter"
                  name="newsletter"
                  value="newsletter"
                  checked={data.newsletter}
                  onChange={onChange}
                  className={is_background_color ? 'white' : ''}
                />
                <label
                  htmlFor="newsletter"
                  className={is_background_color ? 'white' : ''}
                >
                  {contactFormOptions.text_newsletter_checkbox ||
                    'Subscribe to newsletter'}
                </label>
                <button
                  className={`primary ${is_background_color ? 'white' : ''}`}
                  type="submit"
                >
                  {contactFormOptions.text_submit || 'Submit'}
                </button>
                <div className="form_overlay"></div>
              </form>
              <div className="loading_holder">
                {loading && (
                  <Spinner
                    status={`${loading ? 'loading' : ''}${
                      success ? 'success' : ''
                    }`}
                    color="primary"
                  />
                )}
                <div className="messages">
                  <span className="loading_message">
                    {contactFormOptions.text_loading || _t('Loading...')}
                  </span>
                  <span className="success_message">
                    {_t('Your request has been sent.')}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  options: state.options,
  language: state.ui.language,
})

WebinarForm.propTypes = {
  lanugage: PropTypes.string.isRequired,
  apiElement: PropTypes.shape({
    anchor_name: PropTypes.string,
    is_background_color: PropTypes.bool.isRequired,
  }).isRequired,
  showMessage: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {
  submitWebinarFormRequest,
  showMessage,
})(WebinarForm)
