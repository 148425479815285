/* eslint-disable */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import GemmotherapyDisplay from './GemmotherapyDisplay'
import HomeopathyDisplay from './HomeopathyDisplay'
import PhytotherapyDisplay from './PhytotherapyDisplay'
import OligotherapyDisplay from './OligotherapyDisplay'
import MakeYourOwnDisplay from './MakeYourOwnDisplay'

class RemedyDisplay extends React.Component {
	render() {
		const {
			singleRemedies,
			products,
			apiElement,
			matchElement,
			locationElement
		} = this.props

		let category = ''
		switch (apiElement.type) {
			case 'gemmotherapy':
				category = 'gtp'
				// category = 'hpt'
				break
			case 'homeopathy':
				category = 'hpt'
				break
			case 'oligotherapy':
				category = 'otp'
				break
			default:
				category = ''
				break
		}

		// console.log(category)
		let categoryRemedies = []
		// console.log(singleRemedies.singleRemedies)
		if (!singleRemedies.loading)
			Object.values(singleRemedies.singleRemedies).map(element => {
				if (element.ct === category) {
					categoryRemedies.push(element)
				}
				return null
			})
		// console.log(categoryRemedies)
		if (apiElement.type === 'gemmotherapy')
			return (
				<GemmotherapyDisplay
					loading={!singleRemedies.loading && !products.loading}
					remedies={categoryRemedies}
					products={products.products}
					apiElement={apiElement}
				/>
			)
		if (apiElement.type === 'homeopathy')
			return (
				<HomeopathyDisplay
					loading={!singleRemedies.loading && !products.loading}
					remedies={categoryRemedies}
					products={products.products}
					apiElement={apiElement}
					matchElement={matchElement}
					locationElement={locationElement}
				/>
			)
		if (apiElement.type === 'phytotherapy')
			return (
				<PhytotherapyDisplay
					loading={!singleRemedies.loading && !products.loading}
					remedies={categoryRemedies}
					products={products.products}
					apiElement={apiElement}
				/>
			)
		if (apiElement.type === 'oligotherapy')
			return (
				<OligotherapyDisplay
					loading={!singleRemedies.loading && !products.loading}
					remedies={categoryRemedies}
					products={products.products}
					apiElement={apiElement}
				/>
			)
		if (apiElement.type === 'make_your_own')
			return (
				<MakeYourOwnDisplay
					loading={singleRemedies.loading || products.loading}
					// remedies={categoryRemedies}
					products={products.products}
					apiElement={apiElement}
				/>
			)
		return <p>Category not found</p>
	}
}

RemedyDisplay.defaultProps = {}

RemedyDisplay.propTypes = {}

const mapStateToProps = state => ({
	singleRemedies: state.singleRemedies,
	products: state.products
})

export default connect(mapStateToProps)(RemedyDisplay)
