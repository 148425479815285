import React from 'react'
import Helmet from 'react-helmet'
import Reveal from 'react-reveal/Reveal'

import SignupForm from '../../forms/SignupForm'

import _t from '../../utils/translate'

const SignupPage = () => (
	<div
		className="page_content"
		style={{
			paddingTop: '70px',
			position: 'relative',
			paddingBottom: '50px'
		}}
	>
		<Helmet>
			<title>SN - Sign Up</title>
		</Helmet>

		<Reveal effect="custom_fade_in">
			<div className="title_with_icon">
				<div className="icon icon_user active" />
				<h1>{_t('Sign Up')}</h1>
			</div>
		</Reveal>

		<Reveal effect="custom_fade_in">
			<div className="text_block align_left narrow">
				<SignupForm />
			</div>
		</Reveal>
	</div>
)

export default SignupPage
