import React from 'react'
import { connect } from 'react-redux'

const SocialIcons = ({ loading, options, language }) => {
	if (loading) return null
	const footerOptions = options[language].footer
	return (
		<div className="social_icons">
			<a
				target="_blank"
				rel="noopener noreferrer"
				href={footerOptions.facebook_url}
				alt="facebook link"
			>
				<div className="icon icon_facebook" />
				<div className="icon active icon_facebook" />
			</a>
			<a
				target="_blank"
				rel="noopener noreferrer"
				href={footerOptions.instagram_url}
				alt="instagram link"
			>
				<div className="icon icon_instagram" />
				<div className="icon active icon_instagram" />
			</a>
			<a
				target="_blank"
				rel="noopener noreferrer"
				href={footerOptions.linkedin_url}
			>
				<div className="icon icon_linkedin" />
				<div className="icon active icon_linkedin" />
			</a>
			<a
				target="_blank"
				rel="noopener noreferrer"
				href={footerOptions.youtube_url}
			>
				<div className="icon icon_youtube" />
				<div className="icon active icon_youtube" />
			</a>
			<a
				target="_blank"
				rel="noopener noreferrer"
				href={footerOptions.tiktok_url ?? 'https://www.tiktok.com/@schmidtnagel'}
			>
				<div className="icon icon_tiktok" />
				<div className="icon active icon_tiktok" />
			</a>
		</div>
	)
}

const mapStateToProps = state => ({
	options: state.options.data,
	loading: state.options.loading,
	language: state.ui.language
})

export default connect(mapStateToProps)(SocialIcons)
