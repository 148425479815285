export const it = {
  schussler_salts: [
    [`pl_6`, `Compresse 6DH`],
    [`pl_12`, `Compresse 12DH`],
    [`p_6`, `Triturazione 6DH`],
    [`p_12`, `Triturazione 6DH`],
    [`p`, `Triturazione`],
  ],
  professions: [
    ['pharmacist', 'farmacista'],
    ['therapeut', 'terapeutico'],
    [
      'You can only sign up as a professional.',
      'Puoi iscriverti solo come professionista.',
    ],
    [
      'Please specify your Pharma Id.',
      'Si prega di specificare il vostro Pharma Id.',
    ],
  ],
  general: [
    [`Profession`, `Professione`],
    [`First and last name`, `Nome e cognome`],
    [
      `Name of pharmacy and customer number`,
      `Nome della farmacia/drogheria e numero del cliente, se applicabile`,
    ],
    [`Phone number`, `Numero telefonico`],
    [`FPH or GLN number for credits`, `Numero FPH o GLN per i crediti`],
    [`Message`, `Messaggio`],
    [
      `Unfortunately you cannot order in the shop. Please use the order form that you can download on your account page.`,
      `Purtroppo non è possibile ordinare nel negozio. Per favore, usa il modulo d'ordine che puoi scaricare dalla pagina del tuo account.`,
    ],
    [
      `Unfortunately you cannot order in the shop. Please contact us to enable this function.`,
      `Purtroppo non è possibile ordinare nel negozio. Contattateci per abilitare questa funzione.`,
    ],
    [
      `Please log in or register to buy products.`,
      `Per favore, accedi o registrati per acquistare i prodotti.`,
    ],
    [
      `The login information you provided is wrong.`,
      `I dati di accesso non sono corretti. Si prega di riprovare.`,
    ],
    [`Other Resources`, `Altre Risorse`],
    [
      `Thank you for signing up. We'll inform you once your account has been activated.`,
      `Grazie per esserti iscritto. Ti informeremo quando il tuo account sarà attivato.`,
    ],
    [`Sign Up`, `Iscrizione`],
    [`Emails are not the same`, `Gli indirizzi e-mail non sono gli stessi`],
    [`Search {type}...`, `Cerca in {type}…`],
    [`add`, `aggiungi`],
    [`Back to News`, `Torna a News`],
    [`Back to Resources`, `Torna a Risorse`],
    ['Back', 'Torna'],
    ['Account', 'Conto'],
    ['Substances', 'Sostanze'],
    ['OTC:', 'OTC:'],
    ['Change File', 'Cambiare il file'],
    [`or`, `o`],
    [`Learn More`, `Per saperne di più`],
    [`Loading...`, `Caricamento in corso…`],
    [`Edit`, `Modifica`],
    [`Shipping Address`, `Indirizzo di spedizione`],
    [`Billing Address`, `Indirizzo di fatturazione`],
    [`View Item`, `Vedi prodotto`],
    [`Back to Food Supplements`, `Torna agli integratori alimentari`],
    [`Back to Schussler Salts`, `Torna ai Sali di Schüssler`],
    [`Back to OTCs`, `Torna agli OTCs`],
    [`Back to Health`, `Torna a Salute`],
    [`Read more`, `Leggi di più`],
    [`Phone`, `Telefono`],
    [`Email`, `E-mail`],
    [`Password`, `Password`],
    [`Save`, `Salva`],
    [`Loading...`, `Caricamento in corso…`],
    [`Shop`, `Shop`],
    [`Close`, `Chiudi`],
    [`Delete`, `Annulla`],
    [
      `Do you want to delete this substance?`,
      `Volete eliminare questa sostanza?`,
    ],
    [`Cancel`, `Annulla`],
    [
      `Granules are only available if there is at least one Homeopathy substance`,
      `I granuli sono unicamente disponibili se c'è almeno una sostanza omeopatica`,
    ],
    [
      `Globuli are only available if there is at least one Homeopathy substance`,
      `I globuli sono unicamente disponibili se c'è almeno una sostanza omeopatica`,
    ],
    [
      `Drops are only available up to 30CH`,
      `Le gocce sono unicamente disponibili fino a 30CH`,
    ],
    [`Please enter your profession`, `Inserisci la sua professione`],
    [`Address 1`, `Indirizzo 1`],
    [`Shipping Address is different`, `L'indirizzo di spedizione è diverso`],
    [`State`, `Cantone`],
    [`Postcode`, `Codice postale`],
    [`Last Name`, `Cognome`],
    [`Repeat Email`, `Ripetere l'e-mail`],
    [`First Name`, `Nome`],
    [`Sign up`, `Registrazione`],
    [`Choose File`, `Scegli un documento`],
    [`Repeat Password`, `Ripetere la password`],
    [`Company`, `Azienda`],
    [`Address 2`, `Indirizzo 2`],
    [`City`, `Città`],
    [`Certificate`, `Autorizzazione cantonale per l'esercizio della professione / Diploma`],
    [`General`, `Generale`],
    [`substances`, `Sostanze`],
    [`Remedies`, `Rimedi`],
    [`Complexe`, `Complesso`],
    [
      `Please note, that by law we are not allowed to sell our products directly to end customers. Therefore Sign Up is only available to swiss therapists with practice authorization from the canton. This has to be proven by uploading a document that verifies you as a professional.`,
      `Si noti che per legge non siamo autorizzati a vendere ai clienti privati. L'ordine online nell'e-shop non è disponibile per le farmacie. La vendita dei nostri medicamenti ai terapisti dipende dalla normativa in vigore nei diversi cantoni svizzeri. Vi preghiamo di fornirci la vostra autorizzazione cantonale per l'esercizio della professione e il vostro diploma.`
    ],
    [`Topic`, `Tema`],
    [`Composition`, `Composizione`],
    [`Posology`, `Posologia`],
    [`Back to Complexes`, `Torna ai Complessi`],
    [`Indication`, `Indicazioni`],
    [
      `Item has ben added to Cart!`,
      `Il prodotto è stato aggiunto nel Carrello!`,
    ],
    [`Successfully logged out!`, `Disconnessione riuscita!`],
    [`Successfully logged in!`, `Accesso riuscito!`],
    [`Good Afternoon`, `Buongiorno`],
    [`Good Morning`, `Buongiorno`],
    [`Good Evening`, `Buonasera`],
    [`Good Night`, `Buonanotte`],
    [`Pages`, `Pagine`],
    [`Gemmotherapy`, `Gemmoterapia`],
    [`Phytotherapy`, `Fitoterapia`],
    [`Homeopathy`, `Omeopatia`],
    [`Oligotherapy`, `Oligoterapia`],
    [`Shipping Address is the same`, `L'indirizzo di spedizione è lo stesso`],
    [`Invalid email`, `Indirizzo e-mail non valido`],
    [`Can't be blank`, `Non può essere lasciato vuoto`],
    [
      `Your password has to be at least eight characters long`,
      `La password deve essere composta da min. 8 caratteri`,
    ],
    [`Please select a certificate.`, `Si prega di selezionare un certificato`],
    [
      `There are errors in the form, please check and try again.`,
      `Ci sono degli errori nel formulario. Si prega di controllare e riprovare.`,
    ],
    [`Passwords are not the same`, `Le password sono diverse`],
    [`Where to find our remedies`, `Dove trovare i nostri rimedi`],
    [`Loading maps`, `Caricamento mappe`],
    [`Pharmacy`, `Farmacia`],
    [`Drugstore`, `Farmacia`],
    [`Doctor`, `Medico`],
    [`Homeopath`, `Omeopata`],
    [`Naturopath`, `Naturopata`],
    [`Dentist`, `Dentista`],
    [`Midwife`, `Ostetrica`],
    [`Veterinarian`, `Veterinario`],
    [`Other therapist`, `Altro terapeuta`],
  ],
  health: [[`Other Health Topics`, `Altri temi di Salute`]],
  ui: [
    [`ok`, `ok`],
    [`Add to Cart`, `Aggiungi al Carrello`],
    [`Search Phytotherapy...`, `Cerca Fitoterapia…`],
    [`Search Gemmotherapy...`, `Cerca Gemmoterapia…`],
    [`Search Oligotherapy...`, `Cerca Oligoterapia…`],
    [`Search Homeopathy...`, `Cerca Omeopatia…`],
    [`Hide all {nr} remedies`, `Nascondi tutti i {nr} rimedi`],
    [`Show all {nr} remedies`, `Mostra tutti i {nr} rimedi`],
    [`Loading...`, `Caricamento in corso…`],
  ],
  cart: [
    ['{percent}% Discount:', '{percent}% di sconto:'],
    [`Cart`, `Carrello`],
    [`Your cart is empty.`, `Il suo carrello è vuoto.`],
    [`Total`, `Totale`],
    [`Granules`, `Granuli`],
  ],
  account: [
    [
      `To reset your password, please enter your email address.`,
      `Per reimpostare la tua password, inserisci il tuo indirizzo e-mail.`,
    ],
    [
      `Ok, great! Let's set a new password:`,
      `Perfetto! Imposta una nuova password`,
    ],
    [`Reset Password`, `Ripristinare la password`],
    [`Repeat password`, `Ripeti password`],
    [`Repeat Password`, `Ripeti password`],
    [`Customer`, `Cliente`],
    [`Continue`, `Continua`],
    [`Logout`, `Disconnesso`],
    [`Set password`, `Imposta password`],
    [`Address Line 1`, `Indirizzo linea 1`],
    [`Address Line 2`, `Indirizzo linea 2`],
    [`City`, `Città`],
    [`Postcode`, `Codice postale`],
    [`State`, `Cantone`],
    [`Country`, `Paese`],
    [`First Name`, `Nome`],
    [`Company`, `Azienda`],
    [`Last Name`, `Cognome`],
    [`Old Password`, `Password precedente`],
    [`New Password`, `Nuova password`],
    [`Repeat New Password`, `Ripetere la nuova password`],
    [`Administrator`, `Amministratore`],
    [`Good Afternoon`, `Buongiorno`],
    [`Change Status`, `Cambiare lo stato`],
    [`You're registered as a`, `È registrato come`],
    [`Back to Account`, `Torna al Conto`],
    [`Account Orders`, `Ordini del conto`],
    [`Orders`, `Ordini`],
    [`Good Evening`, `Buonasera`],
    [`Account Security`, `Sicurezza del conto`],
    [`Security`, `Sicurezza del conto`],
    [`Account Addresses`, `Indirizzi del conto`],
    [`Addresses`, `Indirizzi`],
    [`Shipping Address`, `Indirizzo di spedizione`],
    [`Billing Address`, `Indirizzo di fatturazione`],
    [`unknown`, `sconosciuto`],
    [`Email`, `E-mail`],
    [`Password`, `Password`],
    [`Forgot Password?`, `Password dimenticata?`],
    [`Log In`, `Connessione`],
    [`Sign Up`, `Iscrizione`],
  ],
  menu: [
    [`Cart`, `Carrello`],
    [`Search`, `Cerca`],
    [`Start`, `Inizio`],
    [`News`, `News`],
    [`Health`, `La vostra salute`],
    [`Food Supplements`, `Integratori alimentari`],
    [`Homeopathy`, `Omeopatia`],
    [`Phytotherapy`, `Fitoterapia`],
    [`Oligotherapy`, `Oligoterapia`],
    [`Schussler Salts`, `Sali di Schüssler`],
    [`Gemmotherapy`, `Gemmoterapia`],
    [`Story`, `Storia`],
    [`Garden`, `Giardino botanico`],
    [`Values`, `Valori`],
    [`Quality`, `Qualità`],
    [`Team`, `Team`],
    [`Lab`, `Laboratorio`],
    [`Make your Own`, `Crealo da te`],
    [`Your Orders`, `I suoi ordini`],
    [`Addresses`, `Indirizzi`],
    [`Medicine`, `Nostri farmaci naturali`],
    [`Company`, `Nostri laboratori`],
    [`Resources`, `Risorse`],
    [`Shop`, `Nostri prodotti`],
    [`Imprint`, `Impressum`],
    [`Contact`, `Contatto`],
    [`Security`, `Sicurezza`],
    [`Login / Register`, `Connessione / Iscrizione`],
    [`Language`, `Language`],
    [`French`, `French`],
    [`SN Canada`, `SN Canada`],
    [`English`, `English`],
    [`German`, `German`],
    [`Italian`, `Italian`],
    [`Where to find our remedies`, `Dove trovare i nostri rimedi`],
    [`Training`, `Formazione`],
    [`Research`, `Pubblicazioni scientifiche`],
    [`Foundation`, `Fondazione`],
    [`Associations`, `Associazioni`],
  ],
  countries: [
    [`Kyrgyzstan`, `Kirghizistan`],
    [`Rwanda`, `Ruanda`],
    [`Equatorial Guinea`, `Guinea Equatoriale`],
    [`Marshall Islands`, `Isole Marshall`],
    [`Iraq`, `Iraq`],
    [`Italy`, `Italia`],
    [`Ivory Coast`, `Costa d'Avorio`],
    [`Georgia`, `Georgia`],
    [`Germany`, `Germania`],
    [`Falkland Islands`, `Isole Falkland`],
    [`Guinea`, `Guinea`],
    [`Guyana`, `Guyana francese`],
    [`Sri Lanka`, `Sri Lanka`],
    [`Turks and Caicos Islands`, `Isole Turks e Caicos`],
    [`Turkmenistan`, `Turkmenistan`],
    [`Jamaica`, `Giamaica`],
    [`Tuvalu`, `Tuvalu`],
    [`Lebanon`, `Libano`],
    [`Latvia`, `Lettonia`],
    [`Lesotho`, `Lesotho`],
    [`Liberia`, `Liberia`],
    [`South Africa`, `Sud Africa`],
    [`Mongolia`, `Mongolia`],
    [`Montenegro`, `Montenegro`],
    [`Norway`, `Norvegia`],
    [`France`, `Francia`],
    [`Romania`, `Romania`],
    [`Iran`, `Iran`],
    [`Saint Martin (Dutch part)`, `Sint Maarten`],
    [`Peru`, `Perù`],
    [`Saint Barthélemy`, `Saint-Barthélémy`],
    [`Austria`, `Austria`],
    [`Laos`, `Laos`],
    [`Antigua and Barbuda`, `Antigua e Barbuda`],
    [`Wallis and Futuna`, `Wallis e Futuna`],
    [`Bosnia and Herzegovina`, `Bosnia ed Erzegovina`],
    [`Tajikistan`, `Tagikistan`],
    [`Myanmar`, `Myanmar`],
    [`Saint Lucia`, `Saint Lucia`],
    [`CuraÇao`, `Curaçao`],
    [`Saint Martin (French part)`, `Saint-Martin`],
    [`Russia`, `Russia`],
    [`Serbia`, `Serbia`],
    [`Belau`, `Belau`],
    [`São Tomé and Príncipe`, `São Tomé e Príncipe`],
    [`Pakistan`, `Pakistan`],
    [`Australia`, `Australia`],
    [`Bahrain`, `Bahrain`],
    [`Senegal`, `Senegal`],
    [`Trinidad and Tobago`, `Trinidad e Tobago`],
    [`Namibia`, `Namibia`],
    [`Belarus`, `Bielorussia`],
    [`British Virgin Islands`, `Isole Vergini Britanniche`],
    [`Congo (Brazzaville)`, `Congo`],
    [`Nauru`, `Nauru`],
    [`Barbados`, `Barbados`],
    [`Nicaragua`, `Nicaragua`],
    [`Papua New Guinea`, `Papua Nuova Guinea`],
    [`Lithuania`, `Lituania`],
    [`Reunion`, `Riunione`],
    [`Cuba`, `Cuba`],
    [`Afghanistan`, `Afghanistan`],
    [`Paraguay`, `Paraguay`],
    [`Finland`, `Finlandia`],
    [`Bangladesh`, `Bangladesh`],
    [`Brazil`, `Brasile`],
    [`Eritrea`, `Eritrea`],
    [`Congo (Kinshasa)`, `Repubblica Democratica del Congo`],
    [`Kuwait`, `Kuwait`],
    [`Norfolk Island`, `Isola Norfolk`],
    [`Niger`, `Niger`],
    [`North Korea`, `Corea del Nord`],
    [`Oman`, `Oman`],
    [`South Sudan`, `Sudan del Sud`],
    [
      `South Georgia/Sandwich Islands`,
      `Georgia del Sud / Isole Sandwich Australi`,
    ],
    [`United Arab Emirates`, `Emirati Arabi Uniti`],
    [`Svalbard and Jan Mayen`, `Svalbard e Jan Mayen`],
    [`South Korea`, `Coreal del Sud`],
    [`Western Samoa`, `Samoa`],
    [`Angola`, `Angola`],
    [`Aruba`, `Aruba`],
    [`Azerbaijan`, `Azerbaigian`],
    [`Bahamas`, `Bahamas`],
    [`Egypt`, `Egitto`],
    [`Bouvet Island`, `Isola Bouvet`],
    [`Heard Island and McDonald Islands`, `Isola Heard e Isole McDonald`],
    [`Hong Kong`, `Hong Kong`],
    [`Kenya`, `Kenya`],
    [`Haiti`, `Haiti`],
    [`Kazakhstan`, `Kazakistan`],
    [`Libya`, `Libia`],
    [`Mayotte`, `Mayotte`],
    [`Saint Helena`, `Sant'Elena`],
    [`Martinique`, `Martinica`],
    [`Tonga`, `Tonga`],
    [`Faroe Islands`, `Isole Faroe`],
    [`Mexico`, `Messico`],
    [`Malaysia`, `Malaysia`],
    [`Cocos (Keeling) Islands`, `Isole Cocos`],
    [`Palestinian Territory`, `Territorio Palestinese`],
    [`Panama`, `Panama`],
    [`Slovakia`, `Slovacchia`],
    [`Cameroon`, `Camerun`],
    [`Estonia`, `Estonia`],
    [`Dominica`, `Dominica`],
    [`Czech Republic`, `Repubblica Ceca`],
    [`Fiji`, `Figi`],
    [`Guatemala`, `Guatemala`],
    [`India`, `India`],
    [`Indonesia`, `Indonesia`],
    [`Liechtenstein`, `Liechtenstein`],
    [`Madagascar`, `Madagascar`],
    [`Malawi`, `Malawi`],
    [`Macao S.A.R., China`, `Macao`],
    [`Qatar`, `Qatar`],
    [`Mauritius`, `Maurizio`],
    [`Cyprus`, `Cipro`],
    [`Luxembourg`, `Lussemburgo`],
    [`Solomon Islands`, `Isole Salomone`],
    [`Netherlands Antilles`, `Antille Olandesi`],
    [`Netherlands`, `Paesi Bassi`],
    [`Spain`, `Spagna`],
    [`Thailand`, `Tailandia`],
    [`Guernsey`, `Guernsey`],
    [`Zambia`, `Zambia`],
    [`Jersey`, `Jersey`],
    [`Honduras`, `Honduras`],
    [`Algeria`, `Algeria`],
    [`Brunei`, `Brunei`],
    [`Bhutan`, `Bhutan`],
    [`Costa Rica`, `Costa Rica`],
    [`Benin`, `Benin`],
    [`French Polynesia`, `Polinesia Francese`],
    [`Dominican Republic`, `Repubblica Dominicana`],
    [`Burundi`, `Burundi`],
    [`Antarctica`, `Antartide`],
    [`Bulgaria`, `Bulgaria`],
    [`Albania`, `Albania`],
    [`Republic of Ireland`, `Repubblica d'Irlanda`],
    [`Croatia`, `Croatia`],
    [`Djibouti`, `Gibuti`],
    [`Iceland`, `Islanda`],
    [`Gabon`, `Gabon`],
    [`Guinea-Bissau`, `Guinea-Bissau`],
    [`Hungary`, `Ungheria`],
    [`Micronesia`, `Micronesia`],
    [`Moldova`, `Moldavia`],
    [`Saint Vincent and the Grenadines`, `Saint Vincent e Grenadine`],
    [`Saudi Arabia`, `Arabia Saudita`],
    [`Belize`, `Belize`],
    [`Andorra`, `Andorra`],
    [`Bolivia`, `Bolivia`],
    [`Bonaire, Saint Eustatius and Saba`, `Bonaire, Sint Eustatius e Saba`],
    [`French Southern Territories`, `Territori australi francesi`],
    [`Gambia`, `Gambia`],
    [`Sierra Leone`, `Sierra Leone`],
    [`Togo`, `Togo`],
    [`Niue`, `Niue`],
    [`Somalia`, `Somalia`],
    [`Tokelau`, `Tokelau`],
    [`Uruguay`, `Uruguay`],
    [`Venezuela`, `Venezuela`],
    [`Uzbekistan`, `Uzbekistan`],
    [`Åland Islands`, `Isole Åland`],
    [`Denmark`, `Danimarca`],
    [`Chad`, `Ciad`],
    [`Belgium`, `Belgio`],
    [`China`, `Cina`],
    [`Seychelles`, `Seicelle`],
    [`Yemen`, `Yemen`],
    [`Israel`, `Israele`],
    [`New Zealand`, `Nuova Zelanda`],
    [`Isle of Man`, `Isola di Man`],
    [`Montserrat`, `Montserrat`],
    [`Guadeloupe`, `Guadalupa`],
    [
      `British Indian Ocean Territory`,
      `Territorio Britannico dell'Oceano Indiano`,
    ],
    [`Monaco`, `Monaco`],
    [`Greece`, `Grecia`],
    [`Portugal`, `Portogallo`],
    [`El Salvador`, `El Salvador`],
    [`Christmas Island`, `Isola di Natale`],
    [`Nigeria`, `Nigeria`],
    [`Switzerland`, `Svizzera`],
    [`Sweden`, `Svezia`],
    [`Ecuador`, `Ecuador`],
    [`San Marino`, `San Marino`],
    [`Kiribati`, `Kiribati`],
    [`Mali`, `Mali`],
    [`Mauritania`, `Mauritania`],
    [`Mozambique`, `Mozambico`],
    [`Tanzania`, `Tanzania`],
    [`Timor-Leste`, `Timor Est`],
    [`Tunisia`, `Tunisia`],
    [`Uganda`, `Uganda`],
    [`United Kingdom (UK)`, `Regno Unito`],
    [`Ukraine`, `Ucraina`],
    [`United States (US)`, `Stati Uniti`],
    [`Zimbabwe`, `Zimbabwe`],
    [`Suriname`, `Suriname`],
    [`Singapore`, `Singapore`],
    [`Syria`, `Siria`],
    [`Swaziland`, `Swaziland`],
    [`Argentina`, `Argentina`],
    [`Western Sahara`, `Sahara Occidentale`],
    [`Vatican`, `Città del Vaticano`],
    [`Comoros`, `Comore`],
    [`Cook Islands`, `Isole Cook`],
    [`Vanuatu`, `Vanuatu`],
    [`Gibraltar`, `Gibilterra`],
    [`Ghana`, `Ghana`],
    [`Greenland`, `Groenlandia`],
    [`Canada`, `Canada`],
    [`Taiwan`, `Taiwan`],
    [`Chile`, `Cile`],
    [`Cape Verde`, `Capo Verde`],
    [`Anguilla`, `Anguilla`],
    [`Pitcairn`, `Isole Pitcairn`],
    [`Cambodia`, `Cambogia`],
    [`Saint Pierre and Miquelon`, `Saint Pierre e Miquelon`],
    [`Cayman Islands`, `Isole Cayman`],
    [`Jordan`, `Giordania`],
    [`Poland`, `Pologna`],
    [`Central African Republic`, `Repubblica Centrafricana`],
    [`Japan`, `Giappone`],
    [`Bermuda`, `Bermuda`],
    [`Malta`, `Malta`],
    [`Slovenia`, `Slovenia`],
    [`Philippines`, `Filippine`],
    [`Saint Kitts and Nevis`, `Saint Kitts e Nevis`],
    [`Maldives`, `Maldive`],
    [`Macedonia`, `Macedonia`],
    [`Nepal`, `Nepal`],
    [`Morocco`, `Marocco`],
    [`Vietnam`, `Vietnam`],
    [`Sudan`, `Sudan`],
    [`Colombia`, `Colombia`],
    [`Armenia`, `Armenia`],
    [`Turkey`, `Turchia`],
    [`Botswana`, `Botswana`],
    [`Burkina Faso`, `Burkina Faso`],
    [`New Caledonia`, `Nuova Caledonia`],
    [`Ethiopia`, `Etiopia`],
    [`French Guiana`, `Guyana Francese`],
    [`Grenada`, `Grenada`],
  ],
  pages: [
    [`Company Values`, `Valori dell'azienda`],
    [`Company Lab`, `Laboratorio dell'azienda`],
    [`Medicine Food Supplements`, `Medicina Integratori alimentari`],
    [`Shop Homeopathy`, `Shop Omeopatia`],
    [`Medicine Phytotherapy`, `Shop Fitoterapia`],
    [`Shop Gemmotherapy`, `Shop Gemmoterapia`],
    [`Shop Seasonal`, `Shop Stagioni`],
    [`Shop Phytotherapy`, `Shop Fitoterapia`],
    [`Medicine Homeopathy`, `Medicina Omeopatia`],
    [`Shop Make your Own`, `Shop Crealo da te`],
    [`Company Story`, `Storia dell'azienda`],
    [`Shop Schussler Salts`, `Shop Sali di Schüssler`],
    [`Shop Oligotherapy`, `Shop Oligoterapia`],
    [`Medicine Gemmotherapy`, `Medicina Gemmoterapia`],
    [`Medicine Schussler Salts`, `Medicina Sali di Schüssler`],
    [`Home`, `Home`],
    [`Shop Homeopathy Remedies`, `Shop Rimedi omeopatici`],
    [`Health`, `Salute`],
    [`Shop Food Supplements`, `Shop Integratori alimentari`],
    [`Medicine Oligotherapy`, `Medicina Oligoterapia`],
    [`Science`, `Scienza`],
    [`Shop Homepathy Complexes`, `Shop Complessi omeopatici`],
    [`Company Quality`, `Qualità dell'azienda`],
    [`Company Team`, `Team dell'azienda`],
  ],
  shop: [
    [`Avaliable in {nr} variations`, `Disponibile in {nr} varianti`],
    [`150ml`, `150ml`],
    [`250ml`, `250ml`],
    [`60ml`, `60ml`],
    [`General information`, `Informazioni generali`],
    [`Shipping`, `Spedizione`],
    [`Select Option`, `Scegliere un'opzione`],
    [`Add to Cart`, `Aggiungi al Carrello`],
    [`Granules`, `Granuli`],
    [`Globuli`, `Globuli`],
    [`General`, `Generale`],
    [`Form`, `Forma galenica`],
    [`Drops`, `Gocce`],
    [`Creme`, `Crema`],
    [`Trituration`, `Triturazione`],
    [`Suppository`, `Supposte`],
    [`Type`, `Tipo`],
    [`Phytotherapy`, `Fitoterapia`],
    [`Homeopathy`, `Omeopatia`],
    [`Gemmotherapy`, `Gemmoterapia`],
    [`Substance`, `Sostanza`],
    [`Type more to see results...`, `Digita di più per vedere i risultati…`],
    [`Scale`, `Tipo di diluizione`],
    [`DH`, `DH`],
    [`CH`, `CH`],
    [`Dilution`, `Diluizione`],
    [`variations`, `variazioni`],
    [`Avaliable in {nr} variations`, `Disponibile in {nr} forme galeniche`],
    [`Avaliable in {nr} variation`, `Disponibile in {nr} forma galenica`],
    [`Substances`, `Sostanze`],
    [`Tube 4g`, `Tubo 4g`],
    [`Bottle 15g`, `Flacone 15g`],
    [`Size`, `Confezione`],
    [`Tube 1g`, `Tubo 1g`],
    [`LM`, `LM / Q`],
    [`K`, `K`],
    [`Bottle 10g`, `Flacone 10g`],
    [`Mannit Bottle 60g`, `Flacone 60g base mannitolo`],
    [`Carrier`, `Liquido`],
    [`NACL`, `NACL`],
    [`Bottle 30ml`, `Flacone 30ml`],
    [`Bottle 10ml`, `Flacone 10ml`],
    [`25° AETH.`, `25° AETH.`],
    [`12 Pieces Adults`, `12 Pezzi adulti`],
    [`Water`, `Acqua distillata`],
    [`variation`, `variazione`],
    [`12 Pieces Kids`, `12 Pezzi bambini`],
    [`Lactose Bottle 60g`, `Flacone 60g base lattosio`],
    [`E DIST.`, `E DIST.`],
    [`50g`, `50g`],
    [`Bottle 125ml`, `Flacone 125ml`],
    [`Bottle 60ml`, `Flacone 60ml`],
    [`Bottle 250ml`, `Flacone 250ml`],
    [`Custom Remedy`, `Rimedio personalizzato`],
    [`Summary`, `Riassunto`],
    [`Total: {nr} items`, `Totale: {nr} prodotti`],
    [`Total: {nr} item`, `Totale: {nr} prodotto`],
    [`Cart`, `Carrello`],
    [`Checkout`, `Pagare`],
    [`Pills 6DH`, `Pastiglia 6DH`],
  ],
  routes: [
    [`payment`, `payments`],
    [`otcs`, `otcs`],
    [`shop`, `shop`],
    [`products`, `products`],
    [`company`, `azienda`],
    [`medecine`, `medicina`],
    [`health`, `salute`],
    [`resources`, `risorse`],
    [`story`, `storia`],
    ['garden', 'giardino'],
    [`homeopathy`, `omeopatia`],
    [`gemmotherapy`, `gemmoterapia`],
    [`phytotherapy`, `fitoterapia`],
    [`oligotherapy`, `oligoterapia`],
    [`values`, `valori`],
    [`quality`, `qualità`],
    [`team`, `team`],
    [`lab`, `laboratorio`],
    [`schussler_salts`, `sali_di_schussler`],
    [`food_supplements`, `integratori_alimentari`],
    [`make_your_own`, `crealo_da_te`],
    [`remedies`, `rimedi`],
    [`seasonal`, `prodotti_stagionali`],
    [`account`, `conto`],
    [`security`, `sicurezza`],
    [`addresses`, `indirizzi`],
    [`orders`, `ordini`],
    [`complexes`, `complessi`],
    [`medicine`, `medicina`],
    [`contact`, `contatto`],
    [`login`, `connessione`],
    [`imprint`, `impressum`],
    [`news`, `news`],
    [`checkout`, `checkout`],
    [`signup`, `signup`],
    [`where`, `dove_trovare_i_nostri_rimedi`],
    [`training`, `formazione`],
    [`research`, `ricerca`],
    [`foundation`, `fondazione`],
    [`associations`, `associazioni`],
  ],
  message: [
    [`loading...`, `Caricamento in corso…`],
    [
      'Your password reset email has been sent.',
      'La tua email di reimpostazione della password è stata inviata.',
    ],
    [`Successfully logged in!`, `Accesso riuscito!`],
    [
      'Your session expired, please log in again.',
      'La tua sessione è scaduta, per favore accedi di nuovo.',
    ],
  ],
}
