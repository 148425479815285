import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import SinglePageRenderer from '../SinglePageRenderer'

import _t from '../../utils/translate'

const CompanyPage = ({ language }) => {
    return (
        <React.Fragment>
			<Route
				path={`/${language}/${_t('company','routes')}`}
				exact
				component={() => <Redirect to={`/${language}/${_t('company','routes')}/${_t('story','routes')}`} />}
			/>
			<Route
				path={`/${language}/${_t('company','routes')}/${_t('garden','routes')}`}
				component={() => <SinglePageRenderer pageId="botanical-garden" />}
			/>
			<Route
				path={`/${language}/${_t('company','routes')}/${_t('story','routes')}`}
				component={() => <SinglePageRenderer pageId="company_story" />}
			/>
			<Route
				path={`/${language}/${_t('company','routes')}/${_t('lab','routes')}`}
				component={() => <SinglePageRenderer pageId="company_lab" />}
			/>
			<Route
				path={`/${language}/${_t('company','routes')}/${_t('values','routes')}`}
				component={() => <SinglePageRenderer pageId="company_values" />}
			/>
			<Route
				path={`/${language}/${_t('company','routes')}/${_t('quality','routes')}`}
				component={() => (
					<SinglePageRenderer pageId="company_quality" />
				)}
			/>
			<Route
				path={`/${language}/${_t('company','routes')}/${_t('team','routes')}`}
				component={() => <SinglePageRenderer pageId="company_team" />}
			/>
		</React.Fragment>
    )
}
export default CompanyPage
