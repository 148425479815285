import React from 'react'
import SinglePageRenderer from '../SinglePageRenderer'

const ImprintPage = () => (
  <SinglePageRenderer
    paddingTop={70}
    title="SN - Privacy Policy"
    pageId="privacy-policy"
  />
)

export default ImprintPage
