import React from 'react'
import { connect } from 'react-redux'

const ProductsDisplay = ({ products }) => {
	// return <div>{JSON.stringify(products)}</div>
	return <div />
}

const mapStateToProps = state => ({
	loading: state.products.loading,
	products: state.products.products
})

export default connect(mapStateToProps)(ProductsDisplay)
