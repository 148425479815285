const pricesK = [
  ['1', 1],
  ['3', 1],
  ['4', 1],
  ['5', 1],
  ['6', 1],
  ['7', 1],
  ['8', 1],
  ['9', 1],
  ['10', 1],
  ['12', 1],
  ['14', 1],
  ['15', 1],
  ['16', 1],
  ['18', 1],
  ['20', 1],
  ['22', 1],
  ['24', 1],
  ['30', 1],
  ['34', 2],
  ['35', 2],
  ['40', 2],
  ['50', 2],
  ['60', 2],
  ['90', 2],
  ['100', 2],
  ['200', 2],
  ['250', 2],
  ['300', 2],
  ['400', 2],
  ['500', 2],
  ['600', 2],
  ['900', 2],
  ['M', 3],
  ['1200', 3],
  ['2M', 3],
  ['2500', 3],
  ['3M', 3],
  ['4M', 3],
  ['5M', 3],
  ['6M', 3],
  ['9M', 3],
  ['XM', 4],
  ['12M', 4],
  ['20M', 4],
  ['LM', 5],
  ['CM', 6],
  ['DM', 7],
  ['MM', 8],
]

const findPriceCategory = (scale, dilution) => {
  if (scale === 'CH') {
    const dilutionNr = parseInt(dilution.split('CH').join(''), 10)
    switch (true) {
      case dilutionNr <= 9:
        return 1
      case dilutionNr <= 29:
        return 2
      case dilutionNr === 30:
        return 3
      case dilutionNr === 200:
        return 4
      case dilutionNr === 1000:
        return 5
      default:
        return 0
    }
  }
  if (scale === 'DH') {
    const dilutionNr = parseInt(dilution.split('DH').join(''), 10)
    switch (true) {
      case dilutionNr <= 18:
        return 1
      case dilutionNr <= 59:
        return 2
      case dilutionNr === 60:
        return 3
      default:
        return 0
    }
  }
  if (scale === 'K') {
    const dilutionNr = dilution.split('K').join('')
    const priceCategory = pricesK.filter((val) => val[0] === dilutionNr)
    if (!priceCategory) console.error(`Price Category ${dilution} not found`)
    return priceCategory?.[0]?.[1]
  }
  if (scale === 'LM') {
    const dilutionNr = parseInt(dilution.split('LM').join(''), 10)
    switch (true) {
      case dilutionNr <= 30:
        return 1
      case dilutionNr <= 60:
        return 2
      case dilutionNr >= 61:
        return 3
      default:
        return 0
    }
  }
  return 0
}

export default findPriceCategory
