import React from 'react'
import _t from '../utils/translate'

const SelectionButton = ({
	attribute,
	method,
	value,
	icon,
	disabled = false,
	message = ''
}) => {
	return (
		<button
			type="button"
			disabled={disabled}
			className={`${attribute === value ? 'active' : ''} ${
				disabled ? 'disabled' : ''
			}`}
			onClick={method.bind(this, value)}
			title={message}
		>
			{icon !== undefined && (
				<div className="icon_holder">
					<div className={`icon active icon_${icon || 'unknown'}`} />
					<div className={`icon icon_${icon || 'unknown'}`} />
				</div>
			)}
			<span>{_t(value, 'shop')}</span>
		</button>
	)
}

export default SelectionButton
