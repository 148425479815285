import React from 'react'
import { withRouter } from 'react-router-dom'

import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { login, loadUserData, resetPassword } from '../actions/userActions'
import { showMessage } from '../actions/uiActions'
import { hidePopups } from '../actions/popupActions'

import _t from '../utils/translate'
import LoginForm from '../forms/LoginForm'
import ResetPasswordForm from '../forms/ResetPasswordForm'

class LoginPopup extends React.Component {
  constructor(props) {
    super(props)
    // this.onSubmit = this.onSubmit.bind(this)
    this.submit = this.submit.bind(this)
    this.resetPasswordSubmit = this.resetPasswordSubmit.bind(this)
    this.state = {
      activePage: 'login',
    }
  }

  submit = (data) =>
    this.props
      .login(data)
      .then(() => this.props.loadUserData())
      .then(() =>
        this.props.showMessage(_t('Successfully logged in!'), 'success')
      )
      .then(() =>
        this.props.history.push(
          `/${this.props.language}/${_t('account', 'routes')}/`
        )
      ) // eslint-disable-line

  resetPasswordSubmit = (data) =>
    this.props
      .resetPassword(data)
      .then((res) => {
        this.props.showMessage(
          _t('Your password reset email has been sent.', 'messages'),
          'success'
        )
        return res
      })
      .then(() => {
        this.closePopup()
      })

  closePopup = () => {
    this.props.hidePopups()
    setTimeout(() => this.showPage('login'), 1000)
  }

  showPage = (page) => {
    this.setState({ activePage: page })
  }

  showSignup = () => {
    const { language } = this.props
    this.props.hidePopups()
    this.props.history.push(`/${language}/${_t('signup', 'routes')}`) // eslint-disable-line
  }

  render() {
    const { visible } = this.props
    const { activePage } = this.state
    // const data = { email: 'adsf' }
    return (
      <div className={`popup login ${visible ? 'visible' : ''}`}>
        <button
          type="button"
          className="close_popup "
          onClick={this.closePopup}
        >
          <div className="icon icon_close active" />
        </button>
        {activePage === 'login' && (
          <LoginForm
            submit={this.submit}
            showSignup={this.showSignup}
            showForgotPassword={() => this.showPage('forgot_password')}
          />
        )}
        {activePage === 'forgot_password' && (
          <ResetPasswordForm submit={this.resetPasswordSubmit} />
        )}
      </div>
    )
  }
}
LoginPopup.defaultProps = {
  children: [],
}

LoginPopup.propTypes = {
  children: PropTypes.array, // eslint-disable-line
  visible: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => ({
  visible: state.popup.login,
  language: state.ui.language,
})

export default connect(mapStateToProps, {
  login,
  hidePopups,
  loadUserData,
  showMessage,
  resetPassword,
})(withRouter(LoginPopup))
