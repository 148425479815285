// import 'react-app-polyfill/ie11'
import 'core-js/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import WebFont from 'webfontloader'
import $ from 'jquery'

// import setAuthorizationHeader from './utils/setAuthorizationHeader'
// import { store } from './store/store'
// import { userLoggedIn, userDataLoaded } from './actions/userActions'
// import { pagesLoaded } from './actions/pagesActions'
// import { cartLoaded } from './actions/cartActions'

import * as serviceWorker from './serviceWorker'
import App from './App'
import Settings from './Settings'

console.log(
  `${process.env.REACT_APP_GIT_BRANCH} // Nr: ${process.env.REACT_APP_GIT_COUNT} // ${process.env.REACT_APP_GIT_SHA}`
)

WebFont.load({
  google: {
    families: ['Nunito:extra-light,light,regular', 'sans-serif'],
  },
})

/* eslint-disable */
$.fn.scrollGuard = function() {
  return this.on('wheel', function(e) {
    var $this = $(this)
    if (e.originalEvent.deltaY < 0) {
      /* scrolling up */
      return $this.scrollTop() > 0
    } else {
      /* scrolling down */
      return $this.scrollTop() + $this.innerHeight() < $this[0].scrollHeight
    }
  })
}

/* eslint-enable */

// if (localStorage.snJWT && localStorage.snUser) {
//  const payload = decode(localStorage.snJWT)
//  console.log(payload)
//  const userAuth = { token: localStorage.snJWT }
//  const userData = JSON.parse(localStorage.snUser)
//  setAuthorizationHeader(localStorage.snJWT)
//  ReactGA.set({ userId: userData.login })
//  store.dispatch(userLoggedIn(userAuth))
//  store.dispatch(userDataLoaded(userData))
// }

// if (localStorage.snPages) {
//  const pages = JSON.parse(localStorage.snPages)
//  store.dispatch(pagesLoaded(pages))
// }

// if (localStorage.snCart) {
//  const cart = JSON.parse(localStorage.snCart)
//  store.dispatch(cartLoaded(cart))
// }
//
// console.log(`${Settings.server}${Settings.baseName}`)

ReactDOM.render(
  <BrowserRouter basename={Settings.baseName}>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
)
// <Analytics id="UA-140713645-1" debug>
// </Analytics>

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
