export const LOAD_PAGES = 'LOAD_PAGES'
export const LOAD_SINGLE_REMEDIES = 'LOAD_SINGLE_REMEDIES'

export const SCHUSSLER_LOADED = 'SCHUSSLER_LOADED'
export const GEMMOTHERAPY_LOADED = 'GEMMOTHERAPY_LOADED'
export const PHYTOTHERAPY_LOADED = 'PHYTOTHERAPY_LOADED'
export const OLIGOTHERAPY_LOADED = 'OLIGOTHERAPY_LOADED'

export const LOAD_BROWSER_LANGUAGES = 'LOAD_BROWSER_LANGUAGES'
export const USER_LOGGED_IN = 'USER_LOGGED_IN'
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'
export const USER_DATA_LOADED = 'USER_DATA_LOADED'

export const SHOW_LOGIN_POPUP = 'SHOW_LOGIN_POPUP'
export const SHOW_SIGNUP_POPUP = 'SHOW_SIGNUP_POPUP'
export const HIDE_POPUPS = 'HIDE_POPUPS'

export const SHOW_CART = 'SHOW_CART'
export const HIDE_CART = 'HIDE_CART'
export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const EMPTY_CART = 'EMPTY_CART'

export const LOAD_PRODUCTS = 'LOAD_PRODUCTS'
export const LOAD_PRODUCTS_ACF = 'LOAD_PRODUCTS_ACF'

export const SET_LOADING = 'SET_LOADING'

export const SHOW_LOADING = 'SHOW_LOADING'
export const HIDE_LOADING = 'HIDE_LOADING'

export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const HIDE_ALL_POPUPS = 'HIDE_ALL_POPUPS'

export const SET_LANGUAGE = 'SET_LANGUAGE'
export const SET_LANGUAGE_MANUALLY = 'SET_LANGUAGE_MANUALLY'
export const LANGUAGE_LOADED = 'LANGUAGE_LOADED'

export const SHOW_LANGUAGE_CHOOSER = 'SHOW_LANGUAGE_CHOOSER'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'

export const SHOW_SEARCH = 'SHOW_SEARCH'
export const HIDE_SEARCH = 'HIDE_SEARCH'

export const SHOW_MENU = 'SHOW_MENU'
export const HIDE_MENU = 'HIDE_MENU'

export const SHOW_COMPLETE_RESET_PASSWORD_POPUP =
  'SHOW_COMPLETE_RESET_PASSWORD_POPUP'
export const OPTIONS_LOADED = 'OPTIONS_LOADED'
export const SET_ERROR = 'SET_ERROR'

export const POSTS_LOADED = 'POSTS_LOADED'

export const ALLOW_CHECKOUT = 'ALLOW_CHECKOUT'
export const DISABLE_CHECKOUT = 'DISABLE_CHECKOUT'
