import parseDate from './parseDate';

const formatDate = (rawDate, showTime = false) => {
	const date = parseDate(rawDate);

	// console.log(date)

	return `${date.day}.${date.month}.${date.year}${
		date.hour !== '' && showTime ? ` ${date.hour}` : ''
	}${date.minute !== '' && showTime ? `:${date.minute}` : ''}`;
};

export default formatDate;
