import _ from 'lodash'
import { LOAD_PRODUCTS, LOAD_PRODUCTS_ACF, SET_LOADING } from '../actions/types'

const initialState = {
  loading: true,
}

let result

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD_PRODUCTS:
      return {
        ...state,
        loading: false,
        products: [...action.payload],
      }
    // case LOAD_PRODUCTS_ACF:
    //   if (state.products.loading) {
    //     return {
    //       ...state,
    //       products: [...action.payload],
    //     }
    //   }
    //   // merges current state and new acf info with ID
    //   result = state.products.map((element) => ({
    //     ...element,
    //     ..._.find(action.payload, ['id', element.id]),
    //   }))
    //   return { ...state, loading: false, products: [...result] }
    case SET_LOADING:
      return initialState
    default:
      return state
  }
}
