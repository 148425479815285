import React from 'react'
import Validator from 'validator'

import _t from '../utils/translate'

class SingleField extends React.Component {
    state = { edit: false, data: {} }

    constructor(props) {
        super(props)
        this.toggleEdit = this.toggleEdit.bind(this)
        this.validate = this.validate.bind(this)
        this.resetError = this.resetError.bind(this)
        this.input = React.createRef()
    }

    componentDidMount() {
        const { field } = this.props
        this.setState({
            data: {
                [field.name]: field.data
            }
        })
        // console.log(field)
    }

    validate = data => {
        let error = false
        if (data.login) {
            if (!Validator.isEmail(data.login)) error = 'Invalid email'
        }
        // if (!data.password) errors.password = "Can't be blank"

        return error
    }

    toggleEdit(e) {
        e.preventDefault()
        const { field, onSave } = this.props
        const { input, validate } = this
        // if (!edit)
        this.setState(prevState => ({ edit: !prevState.edit }))
        // else {
        // console.log(input.current.value)
        const newData = {
            [field.name]: input.current.value
        }
        const error = validate(newData)
        if (!error) onSave(newData)
        else this.setState({ error })
        // }
    }

    resetError() {
        this.setState({ error: false })
    }

    render() {
        const { field } = this.props
        const { edit, error } = this.state
        const { toggleEdit, resetError } = this
        return (
            <div className={`field email ${edit ? 'edit' : ''}`}>
                <span className="label">{_t(field.label)}</span>
                <span className="content">{field.data}</span>
                <form onSubmit={toggleEdit}>
                    {error ? <span className="error">{error}</span> : null}
                    <input
                        className="edit"
                        name="login"
                        defaultValue={field.data}
                        onChange={resetError}
                        ref={this.input}
                    />
                </form>
                <button type="button" className="change" onClick={toggleEdit}>
                    {_t(edit ? 'Save' : 'Edit')}
                </button>
            </div>
        )
    }
}

export default SingleField
