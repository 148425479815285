/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { loadLanguage } from '../actions/pagesActions'
import { setLanguageManually } from '../actions/languageActions'
import { hideAllPopups } from '../actions/uiActions'
import Settings from '../Settings'

class LanguageChooser extends React.Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.goToLanguage = this.goToLanguage.bind(this)
  }

  goToCanada = () => {
    const { hideAllPopups } = this.props
    console.log('goToCanada')

    window.open(Settings.linkCanada, '_blank') ||
      window.location.replace(Settings.linkCanada)

    hideAllPopups()
  }

  goToFrance = () => {
    const { hideAllPopups } = this.props
    console.log('goToFrance')

    window.open(Settings.linkFrance, '_blank') ||
    window.location.replace(Settings.linkFrance)

    hideAllPopups()
  }

  goToLanguage = (language) => {
    const { history, setLanguageManually } = this.props
    setLanguageManually(language)
    // history.push(`/${language}/`)
    window.location.href = `/${language}/`
    // console.log(history)
    // history.push(`/${language}/`)
  }

  render() {
    const {
      languageChooserVisible,
      loadLanguage,
      hideAllPopups,
      language,
    } = this.props

    const { goToLanguage, goToCanada, goToFrance } = this

    return (
      <div
        className={`popup language_chooser ${
          languageChooserVisible ? 'visible' : ''
        }`}
      >
        <button type="button" className="close_popup" onClick={hideAllPopups}>
          <div className="icon icon_close active"/>
        </button>
        <div className="icon icon_language active"/>
        <h2 className="heading">Choose a Region</h2>
        <div className="region switzerland" href="#">
          <button type="button" className="active">
            Switzerland
          </button>
        </div>
        <div className="region france" href="#">
          <button onClick={goToFrance} type="button">
            France
          </button>
        </div>
        <div className="region canada" href="#">
          <button onClick={goToCanada} type="button">
            Canada
          </button>
        </div>
        <h2 className="heading">Choose a Language</h2>
        <div className="language_holder">
          <button
            type="button"
            className={`language en ${language === 'en' ? 'active' : ''}`}
            onClick={() => {
              // console.log(language)
              if (language !== 'en') {
                goToLanguage('en')
                // loadLanguage('en')
                hideAllPopups()
              }
            }}
          >
            <div className="icon icon_en"/>
          </button>
          <button
            type="button"
            className={`language fr ${language === 'fr' ? 'active' : ''}`}
            onClick={() => {
              if (language !== 'fr') {
                goToLanguage('fr')
                // loadLanguage('fr')
                hideAllPopups()
              }
            }}
          >
            <div className="icon icon_fr"/>
          </button>
          <button
            type="button"
            className={`language de ${language === 'de' ? 'active' : ''}`}
            onClick={() => {
              if (language !== 'de') {
                goToLanguage('de')
                // loadLanguage('de')
                hideAllPopups()
              }
            }}
          >
            <div className="icon icon_de"/>
          </button>
          <button
            type="button"
            className={`language it ${language === 'it' ? 'active' : ''}`}
            onClick={() => {
              if (language !== 'it') {
                goToLanguage('it')
                // loadLanguage('it')
                hideAllPopups()
              }
            }}
          >
            <div className="icon icon_it"/>
          </button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  languageChooserVisible: state.ui.languageChooserVisible,
  language: state.ui.language,
})

export default connect(mapStateToProps, {
  loadLanguage,
  hideAllPopups,
  setLanguageManually,
})(withRouter(LanguageChooser))

/* eslint-enable */
// <button
// 					type="button"
// 					className="language_button en"
// 					onClick={() => {
// 						loadLanguage('en')
// 						hideAllPopups()
// 					}}
// 				>
// 					EN
// 				</button>
// 				<button
// 					type="button"
// 					className="language_button fr"
// 					onClick={() => {
// 						loadLanguage('fr')
// 						hideAllPopups()
// 					}}
// 				>
// 					FR
// 				</button>
// 				<button
// 					type="button"
// 					className="language_button de"
// 					onClick={() => {
// 						loadLanguage('de')
// 						hideAllPopups()
// 					}}
// 				>
// 					DE
// 				</button>
