import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'

import Reveal from 'react-reveal/Reveal'
import Footer from '../Footer'
import BackLink from '../BackLink'

import { logUserOut } from '../../actions/userActions'
import { showMessage } from '../../actions/uiActions'
import Greeting from '../../utils/Greeting'

import AddressForm from '../../forms/AddressForm'
import SecurityForm from '../../forms/SecurityForm'

import _t from '../../utils/translate'

// import SinglePageRenderer from '../SinglePageRenderer'

function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  )

  return bytes.toFixed(dp) + ' ' + units[u]
}

const MainPage = ({ firstName, lastName, roleNiceName, logOutHandler }) => (
  <React.Fragment>
    <Helmet>
      <title>SN - {_t('Account')}</title>
    </Helmet>
    <div className="title_with_icon_wrapper">
      <div className="title_with_icon">
        <div className="icon icon_user active" />
        <h1>
          {Greeting()},<br />
          {firstName} {lastName}!
        </h1>
      </div>
    </div>
    <div className="role_display text_block">
      <h3 className="green">
        {_t("You're registered as a", 'account')} {roleNiceName}
      </h3>
      <br />
      {false && (
        <button className="blk" type="button">
          {_t('Change Status', 'account')}
        </button>
      )}
      <button className="blk" onClick={logOutHandler} type="button">
        {_t('Logout', 'account')}
      </button>
    </div>
  </React.Fragment>
)

const SecurityPage = ({ language }) => (
  <Reveal effect="custom_fade_in">
    <Helmet>
      <title>SN - {_t('Account Security', 'account')}</title>
    </Helmet>
    <BackLink to={`/${language}/${_t('account', 'routes')}/`}>
      {_t('Back to Account', 'account')}
    </BackLink>
    <div className="title_with_icon">
      <div className="icon icon_security active" />
      <h1>{_t('Security', 'account')}</h1>
      <SecurityForm />
    </div>
  </Reveal>
)

const AddressesPage = ({ language }) => (
  <Reveal effect="custom_fade_in">
    <Helmet>
      <title>SN - {_t('Account Addresses', 'account')}</title>
    </Helmet>
    <BackLink to={`/${language}/${_t('account', 'routes')}/`}>
      {_t('Back to Account', 'account')}
    </BackLink>
    <div className="title_with_icon">
      <div className="icon icon_addresses active" />
      <h1>{_t('Addresses', 'account')}</h1>
      <AddressForm />
    </div>
  </Reveal>
)
const PaymentPage = ({ language }) => (
  <Reveal effect="custom_fade_in">
    <Helmet>
      <title>SN - {_t('Account Payment', 'account')}</title>
    </Helmet>
    <BackLink to={`/${language}/${_t('account', 'routes')}/`}>
      {_t('Back to Account', 'account')}
    </BackLink>
    <div className="title_with_icon">
      <div className="icon icon_payment active" />
      <h1>{_t('Payment', 'account')}</h1>
    </div>
  </Reveal>
)
const OrdersPage = ({ language }) => (
  <Reveal effect="custom_fade_in">
    <Helmet>
      <title>SN - {_t('Account Orders', 'account')}</title>
    </Helmet>
    <BackLink to={`/${language}/${_t('account', 'routes')}/`}>
      {_t('Back to Account', 'account')}
    </BackLink>
    <div className="title_with_icon">
      <div className="icon icon_orders active" />
      <h1>{_t('Orders', 'account')}</h1>
    </div>
  </Reveal>
)
const DownloadPage = ({ language, user }) => {
  const downloadPage = user?.download_page?.[language]
  const downloadOrderForm = user?.download_order_form?.[language]
  return (
    <Reveal effect="custom_fade_in">
      <Helmet>
        <title>SN - {_t('Downloads', 'account')}</title>
      </Helmet>
      <BackLink to={`/${language}/${_t('account', 'routes')}/`}>
        {_t('Back to Account', 'account')}
      </BackLink>
      <div className="title_with_icon download_page">
        <div className="icon icon_downloads active" />
        <h1>{downloadPage?.title || 'Downloads'}</h1>
        <div
          className={`text_block align_center`} // eslint-disable-next-line
          dangerouslySetInnerHTML={{
            __html: downloadPage?.text || null,
          }}
        />
        {downloadPage?.documents?.map((document, i) => (
          <div
            className="document_holder text_block"
            key={`file_id_${document?.file?.id}_${i}`}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="file_link"
              href={document?.file?.url}
            >
              <div className="icon icon_document active" />
              <div className="right">
                <span className="document_name">{document?.label}</span>
                <br />
                <span className="filesize">
                  {humanFileSize(document?.file?.filesize)}
                </span>
              </div>
            </a>
          </div>
        ))}
        {user?.user_can_order_with_form && downloadOrderForm?.title ? (
          <>
            <h2>{downloadOrderForm?.title || 'Order Form'}</h2>
            <div
              className={`text_block align_center`} // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: downloadOrderForm?.text || null,
              }}
            />
            <div
              className="document_holder text_block"
              key={`file_id_${downloadOrderForm?.file?.id}_order_form`}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="file_link"
                href={downloadOrderForm?.file?.url}
              >
                <div className="icon icon_document active" />
                <div className="right">
                  <span className="document_name">
                    {downloadOrderForm?.label}
                  </span>
                  <br />
                  <span className="filesize">
                    {humanFileSize(downloadOrderForm?.file?.filesize)}
                  </span>
                </div>
              </a>
            </div>
          </>
        ) : null}
      </div>
    </Reveal>
  )
}

class AccountPage extends React.Component {
  constructor(props) {
    super(props)
    this.logOutHandler = this.logOutHandler.bind(this)
  }

  componentDidUpdate() {
    const {
      user: { loggedIn },
      language,
      history,
    } = this.props
    if (!loggedIn) {
      history.push(`/${language}/`)
    }
  }

  logOutHandler() {
    const { logUserOut, showMessage } = this.props
    showMessage(_t('Successfully logged out!'), 'success')
    logUserOut()
  }

  render() {
    const { user, language } = this.props
    const { firstName, lastName, roles } = user
    let roleNiceName
    switch (roles) {
      case 'customer':
        roleNiceName = _t('Customer', 'account')
        break
      case 'administrator':
        roleNiceName = _t('Administrator', 'account')
        break
      default:
        roleNiceName = _t('unknown', 'account')
    }

    let debug = '' // eslint-disable-line
    // debug = JSON.stringify(user)
    return (
      <React.Fragment>
        <div style={{ paddingTop: '140px' }}>
          <Route
            exact
            path={`/${language}/${_t('account', 'routes')}/`}
            component={() => (
              <Reveal effect="custom_fade_in">
                <MainPage
                  firstName={firstName}
                  lastName={lastName}
                  roleNiceName={roleNiceName}
                  logOutHandler={this.logOutHandler}
                />
              </Reveal>
            )}
          />

          <Route
            exact
            path={`/${language}/${_t('account', 'routes')}/${_t(
              'security',
              'routes'
            )}/`}
            component={() => <SecurityPage language={language} />}
          />
          <Route
            exact
            path={`/${language}/${_t('account', 'routes')}/${_t(
              'addresses',
              'routes'
            )}/`}
            component={() => <AddressesPage language={language} />}
          />
          <Route
            exact
            path={`/${language}/${_t('account', 'routes')}/${_t(
              'payment',
              'routes'
            )}/`}
            component={() => <PaymentPage language={language} />}
          />
          <Route
            exact
            path={`/${language}/${_t('account', 'routes')}/${_t(
              'orders',
              'routes'
            )}/`}
            component={() => <OrdersPage language={language} />}
          />
          <Route
            exact
            path={`/${language}/${_t('account', 'routes')}/${_t(
              'downloads',
              'routes'
            )}/`}
            component={() => <DownloadPage language={language} user={user} />}
          />
        </div>
        <Footer />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  language: state.ui.language,
})

export default withRouter(
  connect(mapStateToProps, { logUserOut, showMessage })(AccountPage)
)

// <Link to="/account/security">Security</Link>
// 	<Link to="/account/addresses">Addresses</Link>
// 	<Link to="/account/payment">Payment</Link>
// 	<Link to="/account/orders">Orders</Link>

// <React.Fragment>
// 			<Route
// 				exact
// 				path="/health"
// 				component={() => (
// 					<SinglePageRenderer paddingTop={70} pageId="health" />
// 				)}
// 			/>
// 			<Route path="/health/:topic" component={SingleAccountPage} />
// 		</React.Fragment>
