import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import _t from '../../utils/translate'
import Settings from '../../Settings'

class TwoImages extends React.Component {
	// ({ apiElement }) => {

	constructor(props) {
		super(props)
		this.goToLink = this.goToLink.bind(this)
	}

	goToLink = link => {
		// alert(`go to link: ${link}`)
	}

	render() {
		const {
			props: { apiElement },
			goToLink
		} = this

		const isLink1 = apiElement.link_1 !== ''
		const isLink2 = apiElement.link_2 !== ''

		// console.log(apiElement)

		const innerContainer1 = (
			<React.Fragment>
				<div
					className="image_holder"
					style={{
						backgroundImage: `url(${apiElement.image_1.sizes.large})`
					}}
				>
					<div className="green_overlay" />
				</div>
				<div className="text_container">
					{apiElement.title_1 !== '' && <h2>{apiElement.title_1}</h2>}
					<div
						className="text"
						// eslint-disable-next-line
						dangerouslySetInnerHTML={{
							__html: apiElement.text_1 || null
						}}
					/>
					{apiElement.show_button_1 &&
						(apiElement.button_1_download ? (
							<a
								className="button"
								target="_blank"
								rel="noopener noreferrer"
								href={apiElement.button_1_download.url}
							>
								{apiElement.button_1_text}
							</a>
						) : (
							<button
								type="button"
								className="button"
								to={apiElement.button_1_link}
							>
								{apiElement.button_1_text}
							</button>
						))}
					{!apiElement.show_button_1 && apiElement.link_1 !== '' && (
						<button
							type="button"
							className="button mobile_only"
							to={apiElement.link_1}
						>
							{_t(Settings.readMore)}
						</button>
					)}
				</div>
				<div className="clearfix" />
			</React.Fragment>
		)
		const innerContainer2 = (
			<React.Fragment>
				<div
					className="image_holder"
					style={{
						backgroundImage: `url(${apiElement.image_2.sizes.large})`
					}}
				>
					<div className="green_overlay" />
				</div>
				<div className="text_container">
					{apiElement.title_2 !== '' && <h2>{apiElement.title_2}</h2>}
					<div
						className="text"
						// eslint-disable-next-line
						dangerouslySetInnerHTML={{
							__html: apiElement.text_2 || null
						}}
					/>
					{apiElement.show_button_2 &&
						(apiElement.button_2_download ? (
							<a
								className="button"
								target="_blank"
								rel="noopener noreferrer"
								href={apiElement.button_2_download.url}
							>
								{apiElement.button_2_text}
							</a>
						) : (
							<button
								type="button"
								className="button"
								to={apiElement.button_2_link}
							>
								{apiElement.button_2_text}
							</button>
						))}
					{!apiElement.show_button_2 && apiElement.link_2 !== '' && (
						<button
							type="button"
							className="button mobile_only"
							onClick={() => goToLink(apiElement.link_2)}
							to={apiElement.link_2}
						>
							{_t(Settings.readMore)}
						</button>
					)}
				</div>
				<div className="clearfix" />
			</React.Fragment>
		)

		const container1 = isLink1 ? (
			<Link to={apiElement.link_1} className="container_1">
				{innerContainer1}
			</Link>
		) : (
			<div className="container_1">{innerContainer1}</div>
		)
		const container2 = isLink2 ? (
			<Link to={apiElement.link_2} className="container_2">
				{innerContainer2}
			</Link>
		) : (
			<div className="container_2">{innerContainer2}</div>
		)

		return (
			<div className="two_images_wrapper">
				<div className={`two_images ${apiElement.layout}`}>
					{apiElement.layout === 'layout_1' ? (
						<React.Fragment>
							{container1}
							{container2}
						</React.Fragment>
					) : (
						<React.Fragment>
							{container2}
							{container1}
						</React.Fragment>
					)}
				</div>
			</div>
		)
	}
}

TwoImages.defaultProps = {
	apiElement: {}
}
TwoImages.propTypes = {
	apiElement: PropTypes.object // eslint-disable-line
}

export default TwoImages
