import { store } from '../store/store'
import { LOAD_PAGES, LANGUAGE_LOADED, SET_LANGUAGE } from './types'
import { api } from '../api'

export const pagesLoaded = pages => ({ type: LOAD_PAGES, payload: pages })
export const langLoaded = pages => ({ type: LANGUAGE_LOADED, payload: pages })

function handleErrors(response) {
	// console.log(response)
	if (response.status !== 200) {
		throw Error(JSON.stringify(response))
	} else return response
}

export const loadPages = () => dispatch => {
	api.pages
		.getPages()
		.then(res => handleErrors(res))
		.then(res => res.data)
		// .then(res => res.json())
		.then(res => {
			return res.reduce((obj, item) => {
				obj[item.slug] = item
				return obj
			}, {})
		})
		.then(res => {
			// console.log(res)
			// localStorage.snPages = JSON.stringify(res)
			return res
		})
		.then(res => {
			// console.log(res)
			return res
		})
		.then(pages => dispatch(pagesLoaded(pages)))
		.catch(err => {
			console.log(err)
		})
}

export const loadLanguage = (language, pageJustLoaded = false) => dispatch => {
	if (store.getState().ui.language !== language || pageJustLoaded) {
		let languageToLoad = language
		if (pageJustLoaded) {
			languageToLoad = store.getState().ui.language
				? store.getState().ui.language
				: 'en'
		}
		dispatch({ type: SET_LANGUAGE, payload: languageToLoad })
		api.pages
			.getPagesLang(languageToLoad)
			.then(res => handleErrors(res))
			.then(res => res.data)
			.then(res => {
				return res.reduce((obj, item) => {
					obj[item.slug] = item // eslint-disable-line
					return obj
				}, {})
			})
			.then(pages =>
				dispatch(langLoaded({ ...pages, language: languageToLoad }))
			)
			.catch(err => {
				console.log(err)
			})
	}
}

export const test = 'test'
