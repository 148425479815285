import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
// import _ from 'lodash';

import { showMenu, hideMenu, showSearch } from '../actions/uiActions'
import { showLoginPopup } from '../actions/popupActions'
import { showCart } from '../actions/cartActions'
import Settings from '../Settings'
import _t from '../utils/translate'

const mobileMenuPages = [
  { title: 'Search', action: 'show_search', icon: 'search' },
  { title: 'Start', action: 'go_to_link', url: [] },

  {
    title: 'Shop',
    action: 'show_subpage',
    subpage: [
      {
        title: 'Homeopathy',
        action: 'go_to_link',
        url: ['shop', 'homeopathy'],
      },
      {
        title: 'Gemmotherapy',
        action: 'go_to_link',
        url: ['shop', 'gemmotherapy'],
      },
      {
        title: 'Phytotherapy',
        action: 'go_to_link',
        url: ['shop', 'phytotherapy'],
      },
      {
        title: 'Oligotherapy',
        action: 'go_to_link',
        url: ['shop', 'oligotherapy'],
      },
      {
        title: 'Schussler Salts',
        action: 'go_to_link',
        url: ['shop', 'schussler_salts'],
      },
      {
        title: 'Food Supplements',
        action: 'go_to_link',
        url: ['shop', 'food_supplements'],
      },
      {
        title: 'Make your Own',
        action: 'go_to_link',
        url: ['shop', 'make_your_own'],
      },
    ],
  },
  {
    title: 'Company',
    action: 'show_subpage',
    subpage: [
      { title: 'Story', action: 'go_to_link', url: ['company', 'story'] },
      { title: 'Garden', action: 'go_to_link', url: ['company', 'garden'] },
      { title: 'Lab', action: 'go_to_link', url: ['company', 'lab'] },
      {
        title: 'Values',
        action: 'go_to_link',
        url: ['company', 'values'],
      },
      {
        title: 'Quality',
        action: 'go_to_link',
        url: ['company', 'quality'],
      },
      { title: 'Team', action: 'go_to_link', url: ['company', 'team'] },
    ],
  },
  {
    title: 'Medicine',
    action: 'show_subpage',
    subpage: [
      {
        title: 'Homeopathy',
        action: 'go_to_link',
        url: ['medicine', 'homeopathy'],
      },
      {
        title: 'Gemmotherapy',
        action: 'go_to_link',
        url: ['medicine', 'gemmotherapy'],
      },
      {
        title: 'Phytotherapy',
        action: 'go_to_link',
        url: ['medicine', 'phytotherapy'],
      },
      {
        title: 'Oligotherapy',
        action: 'go_to_link',
        url: ['medicine', 'oligotherapy'],
      },
      {
        title: 'Schussler Salts',
        action: 'go_to_link',
        url: ['medicine', 'schussler_salts'],
      },
      {
        title: 'Food Supplements',
        action: 'go_to_link',
        url: ['medicine', 'food_supplements'],
      },
    ],
  },
  { title: 'Health', action: 'go_to_link', url: ['health'] },
  {
    title: 'Resources',
    action: 'show_subpage',
    subpage: [
      {
        title: 'Where to find our remedies',
        action: 'go_to_link',
        url: ['resources', 'where'],
      },
      {
        title: 'Training',
        action: 'go_to_link',
        url: ['resources', 'training'],
      },
      {
        title: 'Research',
        action: 'go_to_link',
        url: ['resources', 'research'],
      },
      {
        title: 'Foundation',
        action: 'go_to_link',
        url: ['resources', 'foundation'],
      },
      {
        title: 'Associations',
        action: 'go_to_link',
        url: ['resources', 'associations'],
      }
    ]
  },
  { title: 'News', action: 'go_to_link', url: ['news'] },
  {
    title: 'Language',
    action: 'show_subpage',
    icon: 'language',
    subpage: [
      {
        title: 'SN Canada',
        action: 'go_to_external_link',
        url: Settings.linkCanada,
      },
      {
        title: 'French',
        action: 'go_to_language',
        url: ['fr'],
      },
      {
        title: 'English',
        action: 'go_to_language',
        url: ['en'],
      },
      {
        title: 'German',
        action: 'go_to_language',
        url: ['de'],
      },
      {
        title: 'Italian',
        action: 'go_to_language',
        url: ['it'],
      },
    ],
  },
]

class MobileMenu extends React.Component {
  constructor(props) {
    super(props)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.state = { activeMenuPage: 'main' }
    this.menuAction = this.menuAction.bind(this)
    this.accountButtonHandler = this.accountButtonHandler.bind(this)
  }

  menuAction(menuItem) {
    const { history, language } = this.props
    // console.log(menuItem)
    if (menuItem.action === 'show_subpage')
      this.setState({ activeMenuPage: menuItem.title })
    if (menuItem.action === 'go_to_link') {
      if (!menuItem.url || menuItem.url.lenght < 1) {
        console.error('Please specify URL')
      } else {
        history.push(
          `/${language}/${menuItem.url
            .map((urlPart) => `${_t(urlPart, 'routes')}/`)
            .join('')}`
        )
        this.setState({ activeMenuPage: 'main' })
        this.toggleMenu()
      }
    }
    if (menuItem.action === 'go_to_language') {
      // console.log('Show Language')
      history.push(`/${menuItem.url[0]}/`)
      this.setState({ activeMenuPage: 'main' })
      this.toggleMenu()
    }

    if (menuItem.action === 'show_search') {
      this.props.showSearch()
      setTimeout(() => this.toggleMenu(), 500)
    }

    if (menuItem.action === 'show_cart') {
      this.props.showCart()
      setTimeout(() => this.toggleMenu(), 500)
    }

    if (menuItem.action === 'go_to_external_link') {
      window.open(menuItem.url, '_blank') ||
        window.location.replace(menuItem.url)
      this.toggleMenu()
    }
  }

  accountButtonHandler() {
    const { showLoginPopup, loggedIn, history, language } = this.props // eslint-disable-line
    // CHANGE SHOP ONLINE

    // window.open(Settings.shopLink, '_blank') ||
    //   window.location.replace(Settings.shopLink)

    // history.push(`/${language}/`)

    loggedIn
      ? history.push(`/${language}/${_t('account', 'routes')}/`)
      : showLoginPopup() // eslint-disable-line

    this.toggleMenu()
  }

  toggleMenu() {
    const { menuVisible, showMenu, hideMenu } = this.props

    if (!menuVisible) {
      showMenu()
    } else {
      hideMenu()
    }
  }

  render() {
    const {
      menuVisible,
      language,
      loggedIn,
      options,
      optionsLoading,
      showCart,
    } = this.props
    const { menuAction, accountButtonHandler } = this
    const { activeMenuPage } = this.state
    // const menuItem = _.find(mobileMenuPages, { title: activeMenuPage })
    
      const phoneNr = options?.[language]?.general?.contact_phone_number

    return (
      <React.Fragment>
        <div className={`mobile_menu ${menuVisible ? 'open' : ''}`}>
          <div
            className={`mobile_menu_overlay ${menuVisible && 'visible'} ${
              activeMenuPage !== 'main' &&
              activeMenuPage !== 'search' &&
              activeMenuPage !== 'language'
                ? 'subpage_active'
                : ''
            }`}
          >
            <div className="link_holder">
              {mobileMenuPages.map((menuItem) => {
                let icon =
                  menuItem.subpage && menuItem.subpage.length > 0
                    ? 'icon_forward'
                    : ''
                if (menuItem.icon !== undefined) icon = `icon_${menuItem.icon}`

                return (
                  <button
                    className="menu_entry"
                    type="button"
                    key={`mobile_menu_${menuItem.title}`}
                    onClick={() => {
                      menuAction(menuItem)
                    }}
                  >
                    <span className="child">{_t(menuItem.title, 'menu')}</span>
                    <div className={`child icon ${icon} white`} />
                  </button>
                )
              })}
              {loggedIn && (
                <button
                  className="menu_entry"
                  type="button"
                  key="mobile_menu_cart"
                  onClick={() => {
                    showCart()
                    this.toggleMenu()
                  }}
                >
                  <span className="child">{_t('Cart', 'menu')}</span>
                  <div className={`child icon icon_cart white`} />
                </button>
              )}

              <button
                className="menu_entry"
                type="button"
                key="mobile_menu_login"
                onClick={() => {
                  accountButtonHandler()
                }}
              >
                <span className="child">
                  {loggedIn
                    ? _t('Account', 'menu')
                    : _t('Login / Register', 'menu')}
                </span>
              </button>
            </div>
            {mobileMenuPages.map((menuItem) => {
              if (!menuItem.subpage || menuItem.subpage.length < 1) return null
              return (
                <div
                  key={`subpage_${menuItem.title}`}
                  className={`subpage ${
                    activeMenuPage === menuItem.title ? 'active' : ''
                  }`}
                >
                  <button
                    className="back"
                    type="button"
                    onClick={() => {
                      menuAction({
                        title: 'main',
                        action: 'show_subpage',
                      })
                    }}
                  >
                    <div className="icon icon_back white" />
                  </button>
                  <div className="links">
                    {menuItem.subpage.map((item) => (
                      <button
                        key={`${menuItem.title}_${item.title}`}
                        onClick={() => {
                          menuAction(item)
                        }}
                        className="menu_entry"
                        type="button"
                      >
                        {_t(item.title, 'menu')}
                      </button>
                    ))}
                  </div>
                </div>
              )
            })}

            {null && (
              <div
                key="subpage_language"
                className={`subpage ${
                  activeMenuPage === 'Language' ? 'active' : ''
                }`}
              >
                <button
                  className="back"
                  type="button"
                  onClick={() => {
                    menuAction({
                      title: 'main',
                      action: 'show_subpage',
                    })
                  }}
                >
                  <div className="icon icon_back white" />
                </button>
                <div className="links">
                  <button
                    key="lang_button_en"
                    onClick={() => {}}
                    className="menu_entry"
                    type="button"
                  >
                    English
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className={`mobile_header ${menuVisible ? 'menu_open' : ''}`}>
            {phoneNr && <a href={`tel:${phoneNr}`} aria-label="phone" className="phone">
              <div className="icon" />
            </a>}
            <NavLink className="mobile_logo" to={`/${language}/`} />
            <button
              type="button"
              className="mobile_menu_button"
              onClick={this.toggleMenu}
            >
              <div className="bar_holder">
                <div className="bar" />
                <div className="bar" />
                <div className="bar" />
              </div>
            </button>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  language: state.ui.language,
  menuVisible: state.ui.menuVisible,
  loggedIn: state.user.loggedIn,
  optionsLoading: state.options.loading,
  options: state.options.data,
})

export default withRouter(
  connect(mapStateToProps, {
    showMenu,
    hideMenu,
    showSearch,
    showLoginPopup,
    showCart,
  })(MobileMenu)
)
