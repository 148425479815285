import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const ThreeColumns = ({ apiElement }) => {
	return (
		<div className="three_columns">
			<div
				className={`column ${
					apiElement.text_button_one === '' ? 'no_button' : ''
				}`}
			>
				<div
					className={`icon icon_${apiElement.icon_name_one} active`}
				/>
				<h2>{apiElement.title_one}</h2>
				<p>{apiElement.column_one}</p>
				{apiElement.text_button_one !== '' && (
					<Link
						className="button"
						to={
							apiElement.link_button_one === ''
								? '#'
								: apiElement.link_button_one
						}
					>
						{apiElement.text_button_one}
					</Link>
				)}
			</div>
			<div
				className={`column ${
					apiElement.text_button_two === '' ? 'no_button' : ''
				}`}
			>
				<div
					className={`icon icon_${apiElement.icon_name_two} active`}
				/>
				<h2>{apiElement.title_two}</h2>
				<p>{apiElement.column_two}</p>
				{apiElement.text_button_two !== '' && (
					<Link
						className="button"
						to={
							apiElement.link_button_two === ''
								? '#'
								: apiElement.link_button_two
						}
					>
						{apiElement.text_button_two}
					</Link>
				)}
			</div>
			<div
				className={`column ${
					apiElement.text_button_three === '' ? 'no_button' : ''
				}`}
			>
				<div
					className={`icon icon_${apiElement.icon_name_three} active`}
				/>
				<h2>{apiElement.title_three}</h2>
				<p>{apiElement.column_three}</p>
				{apiElement.text_button_three !== '' && (
					<Link
						className="button"
						to={
							apiElement.link_button_three === ''
								? '#'
								: apiElement.link_button_three
						}
					>
						{apiElement.text_button_three}
					</Link>
				)}
			</div>
		</div>
	)
}

ThreeColumns.defaultProps = {
	apiElement: ''
}
ThreeColumns.propTypes = {
	apiElement: PropTypes.object // eslint-disable-line
}

export default ThreeColumns
