import { store } from '../store/store'
import { POSTS_LOADED, SET_ERROR } from './types'
import { api } from '../api'
import Settings from '../Settings'

export const postsLoaded = posts => ({ type: POSTS_LOADED, payload: posts })

function handleErrors(responses) {
    // console.log(response)

    responses.map(response => {
        if (response.status !== 200) {
            throw Error(JSON.stringify(response))
        } else return response
    })
    return responses
}

export const loadPosts = () => {
    // console.log('LOAD OPTIONS')
    const promises = []
    Settings.supportedLanguages.map(lang => {
        promises.push(api.posts.getPostsLang(lang))
        return null
    })
    return Promise.all(promises)
        .then(res => handleErrors(res))
        .then(res => {
            const allLanguagesPosts = {}
            res.map((singleRes, x) => {
                allLanguagesPosts[Settings.supportedLanguages[x]] =
                    singleRes.data
                return null
            })
            // console.log(allLanguagesOptions)
            store.dispatch(postsLoaded({ data: { ...allLanguagesPosts } }))
        })
        .catch(function(err) {
            // console.error(`Failed to load pages: ${err.message}`); // some coding error in handling happened
            store.dispatch({ type: SET_ERROR, payload: err.message })

            window.setTimeout(() => {
                loadPosts()
                console.error('Loading Posts failed; Try to load posts again.')
            }, 3000)
        })
}
