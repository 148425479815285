import React from 'react'
// import PropTypes from 'prop-types'
// import shortid from 'shortid'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import JSURL from 'jsurl'
// import lzString from 'lz-string'

import formatPrice from '../../utils/formatPrice'

import { addToCart } from '../../actions/cartActions'

import _t from '../../utils/translate'
import QuantityField from '../../utils/QuantityField'
import urlFormat from '../../utils/urlFormat'
import findPriceCategory from '../../utils/findPriceCategory'

import DilutionSlider from '../DilutionSlider'
import SelectionButton from '../SelectionButton'

class HomeopathyDisplay extends React.Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.findPrice = this.findPrice.bind(this)
    this.addToCart = this.addToCart.bind(this)
    this.setScale = this.setScale.bind(this)
    this.setForm = this.setForm.bind(this)
    this.setDilution = this.setDilution.bind(this)
    this.setCarrier = this.setCarrier.bind(this)
    this.updateURL = this.updateURL.bind(this)
    this.changeQuantity = this.changeQuantity.bind(this)

    this.state = {
      data: {
        searchStr: '',
        showAll: false,
        selectedRemedy: null,
        selectedScale: null,
        selectedDilution: null,
        selectedForm: null,
        selectedCarrier: null,
        selectedSize: null,
        currentPrice: null,
        currentWcId: null,
        selectedRemedyName: null,
        error: null,
        priceCategory: null,
        slug: null,
        quantity: 1,
      },
      updated: false,
    }
  }

  componentDidMount() {
    const { products, singleRemediesLoading } = this.props
    // console.log('Homeopathy Display Loaded!')
    // console.log(matchElement.path.split(':')[0])
    // this.props.match.params
    // console.log(this.props.match.params)
    if (
      this.props.match.params.remedyCode != null &&
      !products.loading &&
      !singleRemediesLoading
    ) {
      this.setSelectionFromUrl()
    }
  }

  componentDidUpdate(oldProps, oldState) {
    if (
      oldProps.products.loading !== this.props.products.loading &&
      !this.props.singleRemediesLoading &&
      this.props.match.params.remedyCode != null
    ) {
      this.setSelectionFromUrl()
    }

    if (
      oldProps.singleRemediesLoading !== this.props.singleRemediesLoading &&
      !this.props.products.loading &&
      this.props.match.params.remedyCode != null
    ) {
      this.setSelectionFromUrl()
    }
  }

  // **************************
  // **************************
  // **************************
  // **************************
  // **************************

  setSelectionFromUrl = () => {
    const { remedies } = this.props

    // Still using one letter keys to shorten url
    const RawUrlData = JSURL.parse(this.props.match.params.remedyCode)

    const UrlData = {
      selectedRemedy: RawUrlData.a,
      selectedScale: RawUrlData.b,
      selectedDilution: RawUrlData.c,
      selectedForm: RawUrlData.d,
      selectedCarrier: RawUrlData.e,
      selectedSize: RawUrlData.f,
      quantity: RawUrlData?.g || 1,
    }

    let newStateData = {}

    // ---------- SET REMEDY ----------

    newStateData.selectedRemedy = UrlData.selectedRemedy
    const activeRemedy = remedies.find((element) => {
      return element.id === UrlData.selectedRemedy
    })

    newStateData.activeRemedy = activeRemedy

    // ---------- SET SCALE, DILUTION & FORM ----------

    newStateData = {
      ...newStateData,
      selectedScale: UrlData.selectedScale,
      selectedDilution: UrlData.selectedDilution,
      priceCategory: findPriceCategory(
        UrlData.selectedScale,
        UrlData.selectedDilution
      ),
      selectedForm: UrlData.selectedForm,
      selectedCarrier: UrlData.selectedCarrier,
      quantity: UrlData.quantity,
    }

    // ---------- SET SIZE & PRODUCT ----------

    const product = this.findPrice(
      UrlData.selectedSize,
      UrlData.selectedForm,
      UrlData.selectedScale,
      newStateData.priceCategory
    )

    newStateData = {
      ...newStateData,
      selectedSize: UrlData.selectedSize,
      currentPrice: product.price,
      currentWcId: product.id,
      error: product.error,
    }

    this.setState((oldState) => ({
      ...oldState,
      data: { ...oldState.data, ...newStateData },
    }))

    // console.log(newStateData)
  }

  // **************************
  // **************************
  // **************************
  // **************************
  // **************************

  findPrice = (size, selectedForm, selectedScale, priceCategory) => {
    const { products } = this.props.products

    let priceCategoryFormated = ''
    if (
      selectedForm !== 'Creme' &&
      selectedForm !== 'Trituration' &&
      selectedForm !== 'Suppository'
    )
      priceCategoryFormated = `price-${priceCategory}-`
    const slug = `single-homeopathy-${
      selectedScale === 'DH' ? 'CH' : selectedScale
    }-${selectedForm}-${priceCategoryFormated}${size}`
      .split(' ')
      .join('-')
      .toLowerCase()
    const product = products.find((element) => {
      console.log(slug)

      return (
        element.slug === slug

        // element.slug === 'single-homeopathy-ch-granules-price-1-tube-4g'
      )
      // return (
      //  element.slug ===
      // `'single-homeopathy-${
      //  selectedScale === 'DH' ? 'CH' : selectedScale
      // }-${selectedForm}-price-${priceCategory}-${size}'`
      //  .split(' ')
      //  .join('-')
      //  .toLowerCase()
      // )
    })

    this.setState((oldState) => ({
      data: { ...oldState.data, slug },
    }))

    // console.log(slug)

    if (product) {
      return {
        id: product.id,
        price: parseFloat(Math.round(product.price * 100) / 100),
        error: null,
      }
    }
    return {
      // price: 'not found!',
      error: 'not found',
      id: 0,
    }
  }

  changeQuantity = (quantity) =>
    this.setState(
      (oldState) => ({ data: { ...oldState.data, quantity } }),
      () => {
        this.updateURL()
      }
    )

  onChange = (e) =>
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
      errors: { ...this.state.errors, [e.target.name]: false },
    })

  toggleShowAll = () => {
    if (this.state.data.showAll)
      this.setState({
        ...this.state,
        data: { ...this.state.data, showAll: false },
      })
    else
      this.setState({
        ...this.state,
        data: { ...this.state.data, showAll: true },
      })
  }

  setRemedy = (id) => {
    const { remedies } = this.props

    // console.log(categoryRemedies)
    const activeRemedy = remedies.find((element) => {
      return element.id === id
    })

    this.setState(
      {
        ...this.state,
        data: {
          ...this.state.data,
          selectedRemedy: id,
          activeRemedy,
          // selectedRemedyName: activeRemedy.nm
        },
      },
      () => {
        this.updateURL()
      }
    )
  }

  setCarrier = (carrier) => {
    this.setState((oldState) => ({
      data: {
        ...oldState.data,
        selectedCarrier: carrier,
        selectedSize: null,
      },
    }))
  }

  setScale = (scale) => {
    this.setState((oldState) => ({
      data: {
        ...oldState.data,
        selectedScale: scale,
        selectedForm: null,
        selectedDilution: null,
        selectedCarrier: null,
        selectedSize: null,
      },
    }))
  }

  setForm = (form) => {
    this.setState((oldState) => ({
      data: {
        ...oldState.data,
        selectedCarrier: null,
        selectedForm: form,
        selectedSize: null,
      },
    }))
  }

  setDilution = (dilution, selectedScale = null) => {
    const { selectedSize, selectedForm } = this.state.data
    if (!selectedScale) selectedScale = this.state.data.selectedScale

    // --- If CH 200 / 1000 and drops are selected, reset Form
    if (
      (dilution === '200CH' || dilution === '1000CH') &&
      selectedForm === 'Drops'
    )
      this.setState((oldState) => ({
        data: {
          ...oldState.data,
          selectedCarrier: null,
          selectedForm: null,
          selectedSize: null,
        },
      }))
    // console.log('reset form')

    // console.log('priceCategory: ' + priceCategory)
    this.setState((oldState) => ({
      data: {
        ...oldState.data,
        selectedDilution: dilution,
        priceCategory: findPriceCategory(selectedScale, dilution),
        // priceCategory
      },
    }))

    // --- If Form and Size are already selected, update Price Catogory

    if (selectedSize) {
      const product = this.findPrice(selectedSize)

      this.setState(
        (oldState) => ({
          data: {
            ...oldState.data,
            selectedSize,
            currentPrice: product.price,
            currentWcId: product.id,
            error: product.error,
          },
        }),
        () => {
          this.updateURL()
        }
      )
    }
  }

  setSize = (size) => {
    this.setState((oldState) => ({
      data: {
        ...oldState.data,
        selectedSize: size,
      },
    }))

    const { selectedForm, selectedScale, priceCategory } = this.state.data

    const product = this.findPrice(
      size,
      selectedForm,
      selectedScale,
      priceCategory
    )

    const newData = {
      selectedSize: size,
      currentPrice: product.price,
      currentWcId: product.id,
      error: product.error,
    }

    this.setState(
      (oldState) => {
        return {
          data: { ...oldState.data, ...newData },
        }
      },
      () => {
        this.updateURL()
      }
    )
  }

  unSetRemedy = () => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        selectedRemedy: null,
        searchStr: '',
        selectedSize: null,
        selectedRemedyName: null,
        selectedScale: null,
        selectedDilution: null,
        selectedForm: null,
      },
    })
  }

  addToCart = () => {
    const {
      currentWcId,
      currentPrice,
      selectedScale,
      selectedDilution,
      selectedForm,
      selectedCarrier,
      selectedSize,
      activeRemedy,
      quantity,
    } = this.state.data

    const info = {
      scale: selectedScale,
      dilution: selectedDilution,
      form: selectedForm,
      size: selectedSize,
      type: 'homeopathy',
    }
    if (selectedCarrier) {
      info.carrier = selectedCarrier
    }

    const { addToCart } = this.props
    const newProduct = {
      WcId: currentWcId,
      price: currentPrice,
      name: `${activeRemedy.nm} ${selectedDilution}`,
      icon: 'package_bottle',
      info,
      quantity,
    }
    addToCart(newProduct)
  }

  updateURL = () => {
    const { data } = this.state

    const dataToSerialize = {
      a: data.selectedRemedy,
      b: data.selectedScale,
      c: data.selectedDilution,
      d: data.selectedForm,
      e: data.selectedCarrier,
      f: data.selectedSize,
      g: data?.quantity || 1,
    }

    // console.log(dataToSerialize)
    // console.log(JSURL.stringify(dataToSerialize))

    delete dataToSerialize.searchStr
    delete dataToSerialize.showAll

    // ------------ DELETE EMPTY ATTRIBUTES ------------ //

    const cleanDataToSerialize = {}
    Object.entries(dataToSerialize).map((entry) => {
      if (entry[1] != null) cleanDataToSerialize[entry[0]] = entry[1]
      return null
    })
    console.log(cleanDataToSerialize)

    this.props.history.replace(
      `${this.props.match.path.split(':')[0]}${JSURL.stringify(
        cleanDataToSerialize
      )}`
    )
  }

  render() {
    const {
      // apiElement,
      // singleRemedies,
      loading,
      products,
      remedies,
      singleRemediesLoading,
      language,
    } = this.props

    const {
      searchStr,
      showAll,
      selectedRemedy,
      selectedSize,
      currentPrice,
      activeRemedy,
      selectedScale,
      selectedDilution,
      selectedForm,
      selectedCarrier,
      // error,
      slug,
      quantity,
    } = this.state.data // eslint-disable-line

    const { changeQuantity } = this

    // const formSizeClasses = []

    // formSizeClasses.push(urlFormat(selectedForm))
    // formSizeClasses.push(urlFormat(selectedSize))

    const formSizeClass = `${urlFormat(selectedForm)} ${urlFormat(
      selectedSize?.replace('12', 'twelve')
    )}`

    return (
      <div
        className={`single_homeopathy_display_wrapper ${
          products.loading || singleRemediesLoading ? 'loading' : ''
        }`}
      >
        <div className={`icon icon_homeopathy icon_background `} />
        <div className={`product_display product_preview ${formSizeClass}`} />
        <div className="remedy_display">
          {/* <h2>{apiElement.title}</h2>
                    <div
                        className={`
                icon icon_$ {
                    apiElement.icon
                }
                icon_background `}
                    /> */}
          <div className="search section">
            <div className="label">{_t('Substance', 'shop')}</div>
            <input
              type="text"
              name="searchStr"
              value={selectedRemedy ? activeRemedy?.nm : searchStr}
              onChange={selectedRemedy ? this.unSetRemedy : this.onChange}
              onClick={selectedRemedy ? this.unSetRemedy : undefined}
              placeholder={
                loading
                  ? _t('Loading...', 'general')
                  : _t('Search Homeopathy...', 'ui')
              }
              readOnly={loading || products.loading}
            />
            {!loading && !selectedRemedy && (
              <button
                type="button"
                onClick={this.toggleShowAll}
                className="show_all link"
              >
                {showAll
                  ? _t('Hide all {nr} remedies', 'ui', {
                      nr: remedies.length,
                    })
                  : _t('Show all {nr} remedies', 'ui', {
                      nr: remedies.length,
                    })}
              </button>
            )}
            <div className="clearfix" />
            <div className="remedies">
              {!singleRemediesLoading &&
                !products.loading &&
                (remedies.length < 500 || searchStr.length > 1 || showAll) &&
                remedies.map((element) => {
                  const index = element.nm
                    .toLowerCase()
                    .indexOf(searchStr.toLowerCase())
                  if (
                    ((index !== -1 && searchStr !== '') ||
                      (showAll && searchStr === '')) &&
                    !selectedRemedy
                  ) {
                    const { id, nm } = element
                    return (
                      <button
                        type="button"
                        className="remedy"
                        key={`remedy_${id}`}
                        onClick={this.setRemedy.bind(this, id)}
                      >
                        {nm}
                      </button>
                    )
                  }
                  return null
                })}

              {!loading && searchStr.length > 0 && searchStr.length < 2 && (
                <p>{_t('Type more to see results...', 'shop')}</p>
              )}
            </div>
          </div>
          {selectedRemedy && (
            <div className="scale section">
              <div className="label">{_t('Scale', 'shop')}</div>
              <div
                className={`button_group ${
                  activeRemedy?.availableDilutions['K']?.length < 1
                    ? 'sort_left'
                    : ''
                }`}
              >
                {activeRemedy?.availableDilutions['DH']?.length > 0 && (
                  <SelectionButton
                    attribute={selectedScale}
                    method={this.setScale}
                    value="DH"
                  />
                )}
                {activeRemedy?.availableDilutions['CH']?.length > 0 && (
                  <SelectionButton
                    attribute={selectedScale}
                    method={this.setScale}
                    value="CH"
                  />
                )}
                {activeRemedy?.availableDilutions['K']?.length > 0 && (
                  <SelectionButton
                    attribute={selectedScale}
                    method={this.setScale}
                    value="K"
                  />
                )}
                {activeRemedy?.availableDilutions['LM']?.length > 0 && (
                  <SelectionButton
                    attribute={selectedScale}
                    method={this.setScale}
                    value="LM"
                  />
                )}
              </div>
            </div>
          )}
          {selectedRemedy && selectedScale && (
            <div className="dilution section">
              <div className="label">{_t('Dilution', 'shop')}</div>
              <DilutionSlider
                key={`slider_${selectedScale}`}
                dilutions={activeRemedy.availableDilutions[selectedScale]}
                selectedScale={selectedScale}
                onChange={this.setDilution}
                selectedDilution={selectedDilution}
              />
            </div>
          )}

          {// ----- FORM SECTION -----

          !loading && selectedDilution && (
            <div className="form section">
              <div className="label">{_t('Form', 'shop')}</div>
              {// ----- FORMS CH/DH ------
              (selectedScale === 'CH' || selectedScale === 'DH') && (
                <div className="button_group">
                  <SelectionButton
                    attribute={selectedForm}
                    method={this.setForm}
                    value="Granules"
                    icon="granules"
                  />
                  <SelectionButton
                    attribute={selectedForm}
                    method={this.setForm}
                    value="Globuli"
                    icon="globuli"
                  />
                  {selectedDilution !== '200CH' &&
                    selectedDilution !== '1000CH' && (
                      <SelectionButton
                        attribute={selectedForm}
                        method={this.setForm}
                        value="Drops"
                        icon="drops"
                      />
                    )}
                  <SelectionButton
                    attribute={selectedForm}
                    method={this.setForm}
                    value="Creme"
                    icon="creme"
                  />
                  <SelectionButton
                    attribute={selectedForm}
                    method={this.setForm}
                    value="Trituration"
                    icon="package_schussler"
                  />
                  <SelectionButton
                    attribute={selectedForm}
                    method={this.setForm}
                    value="Suppository"
                    icon="package_unknown"
                  />
                </div>
              )}
              {// ----- FORMS K ------
              selectedScale === 'K' && (
                <div className="button_group  sort_left">
                  <SelectionButton
                    attribute={selectedForm}
                    method={this.setForm}
                    value="Granules"
                    icon="granules"
                  />
                  <SelectionButton
                    attribute={selectedForm}
                    method={this.setForm}
                    value="Globuli"
                    icon="globuli"
                  />
                </div>
              )}
              {// ----- FORMS LM ------
              selectedScale === 'LM' && (
                <div className="button_group sort_left">
                  <SelectionButton
                    attribute={selectedForm}
                    method={this.setForm}
                    value="Granules"
                    icon="granules"
                  />
                  <SelectionButton
                    attribute={selectedForm}
                    method={this.setForm}
                    value="Globuli"
                    icon="globuli"
                  />
                  <SelectionButton
                    attribute={selectedForm}
                    method={this.setForm}
                    value="Drops"
                    icon="drops"
                  />
                </div>
              )}
            </div>
          )}

          {// ----- CARRIER SECTION -----

          !loading && selectedForm === 'Drops' && (
            <div className="carrier section">
              <div className="label">{_t('Carrier', 'shop')}</div>

              <div className="button_group">
                <SelectionButton
                  attribute={selectedCarrier}
                  method={this.setCarrier}
                  value="25° AETH."
                  icon="drops"
                />
                <SelectionButton
                  attribute={selectedCarrier}
                  method={this.setCarrier}
                  value="NACL"
                  icon="drops"
                />
                <SelectionButton
                  attribute={selectedCarrier}
                  method={this.setCarrier}
                  value="Water"
                  icon="drops"
                />
              </div>
            </div>
          )}

          {// ----- SIZE SECTION -----

          !loading &&
            selectedForm &&
            (selectedForm !== 'Drops' || selectedCarrier) && (
              <div className="size section">
                <div className="label">{_t('Size', 'shop')}</div>
                {// GLOBULI CH/DH
                selectedForm === 'Globuli' &&
                  (selectedScale === 'CH' || selectedScale === 'DH') && (
                    <div className="button_group">
                      <SelectionButton
                        attribute={selectedSize}
                        method={this.setSize}
                        icon="package_dose"
                        value="Tube 1g"
                      />
                      <SelectionButton
                        attribute={selectedSize}
                        method={this.setSize}
                        icon="package_bottle"
                        value="Bottle 15g"
                      />
                    </div>
                  )}

                {// GRANULES CH/DH
                selectedForm === 'Granules' &&
                  (selectedScale === 'CH' || selectedScale === 'DH') && (
                    <div className="button_group">
                      <SelectionButton
                        attribute={selectedSize}
                        method={this.setSize}
                        icon="package_dose"
                        value="Tube 4g"
                      />
                      <SelectionButton
                        attribute={selectedSize}
                        method={this.setSize}
                        icon="package_bottle"
                        value="Bottle 15g"
                      />
                    </div>
                  )}

                {// GLOBULI K
                selectedForm === 'Globuli' &&
                  (selectedScale === 'K' || selectedScale === 'LM') && (
                    <div className="button_group">
                      <SelectionButton
                        attribute={selectedSize}
                        method={this.setSize}
                        icon="package_dose"
                        value="Tube 1g"
                      />
                      <SelectionButton
                        attribute={selectedSize}
                        method={this.setSize}
                        icon="package_bottle"
                        value="Bottle 10g"
                      />
                    </div>
                  )}

                {// GRANULES K
                selectedForm === 'Granules' &&
                  (selectedScale === 'K' || selectedScale === 'LM') && (
                    <div className="button_group">
                      <SelectionButton
                        attribute={selectedSize}
                        method={this.setSize}
                        icon="package_dose"
                        value="Tube 4g"
                      />
                      <SelectionButton
                        attribute={selectedSize}
                        method={this.setSize}
                        icon="package_bottle"
                        value="Bottle 10g"
                      />
                    </div>
                  )}

                {// DROPS CH/DH
                selectedForm === 'Drops' &&
                  (selectedScale === 'CH' || selectedScale === 'DH') && (
                    <div className="button_group">
                      <SelectionButton
                        attribute={selectedSize}
                        method={this.setSize}
                        icon="package_bottle"
                        value="Bottle 30ml"
                      />
                      <SelectionButton
                        attribute={selectedSize}
                        method={this.setSize}
                        icon="package_bottle"
                        value="Bottle 60ml"
                      />
                      <SelectionButton
                        attribute={selectedSize}
                        method={this.setSize}
                        icon="package_bottle"
                        value="Bottle 125ml"
                      />
                      <SelectionButton
                        attribute={selectedSize}
                        method={this.setSize}
                        icon="package_bottle"
                        value="Bottle 250ml"
                      />
                    </div>
                  )}

                {// CREME CH/DH
                selectedForm === 'Creme' && selectedScale && (
                  <div className="button_group">
                    <SelectionButton
                      attribute={selectedSize}
                      method={this.setSize}
                      icon="package_unknown"
                      value="50g"
                    />
                  </div>
                )}

                {// Trituration
                selectedForm === 'Trituration' && selectedScale && (
                  <div className="button_group">
                    <SelectionButton
                      attribute={selectedSize}
                      method={this.setSize}
                      icon="package_unknown"
                      value="Lactose Bottle 60g"
                    />
                    <SelectionButton
                      attribute={selectedSize}
                      method={this.setSize}
                      icon="package_unknown"
                      value="Mannit Bottle 60g"
                    />
                  </div>
                )}

                {// Suppositories
                selectedForm === 'Suppository' && selectedScale && (
                  <div className="button_group">
                    <SelectionButton
                      attribute={selectedSize}
                      method={this.setSize}
                      icon="package_unknown"
                      value="12 Pieces Kids"
                    />
                    <SelectionButton
                      attribute={selectedSize}
                      method={this.setSize}
                      icon="package_unknown"
                      value="12 Pieces Adults"
                    />
                  </div>
                )}

                {// DROPS LM
                selectedForm === 'Drops' &&
                  selectedScale === 'LM' &&
                  selectedCarrier && (
                    <div className="button_group">
                      <SelectionButton
                        attribute={selectedSize}
                        method={this.setSize}
                        icon="package_unknown"
                        value="Bottle 10ml"
                      />
                      <SelectionButton
                        attribute={selectedSize}
                        method={this.setSize}
                        icon="package_unknown"
                        value="Bottle 30ml"
                      />
                    </div>
                  )}
              </div>
            )}

          {// ----- PRICE && ADD TO CART -----

          !loading &&
            selectedRemedy &&
            selectedScale &&
            selectedDilution &&
            selectedForm &&
            selectedSize && (
              <div className="add_to_cart">
                <h1 className="left">{_t('Summary', 'shop')}</h1>
                <div
                  className={`product_picture product_preview ${formSizeClass}`}
                />
                <div className="product_info">
                  <h2>{activeRemedy.nm}</h2>
                  <p>{_t(selectedForm, 'shop')}</p>
                  <p>{_t(selectedCarrier, 'shop')}</p>
                  <p>{_t(selectedSize, 'shop')}</p>
                  <p></p>
                  <span className="price">
                    {currentPrice
                      ? formatPrice(currentPrice)
                      : `
                '${slug}'
                not found `}
                  </span>
                  <QuantityField
                    quantity={quantity}
                    onChange={changeQuantity}
                  />
                  <button
                    type="button"
                    className="buy highlight"
                    onClick={this.addToCart}
                  >
                    {_t('Add to Cart', 'shop')}
                  </button>
                </div>
              </div>
            )}

          { activeRemedy && selectedRemedy && activeRemedy[`description_${language}`] !== '' && (
              <div className="description"
                   dangerouslySetInnerHTML={{
                     __html: activeRemedy[`description_${language}`],
                   }}
              />
          )}
        </div>
        <div className="clearfix" />
        <div className="loading">
          <span>{_t('Loading...')}</span>
        </div>
      </div>
    )
  }
}

// HomeopathyDisplay.defaultProps = {
//  apiElement: {}
// }
// HomeopathyDisplay.propTypes = {
//  apiElement: PropTypes.object // eslint-disable-line
// }

const mapStateToProps = (state) => ({
  loading: state.singleRemedies.loading,
  singleRemedies: state.singleRemedies.singleRemedies,
  singleRemediesLoading: state.singleRemedies.loading,
  products: state.products,
  language: state.ui.language,
})

export default connect(mapStateToProps, { addToCart })(
  withRouter(HomeopathyDisplay)
)
