import {
	SHOW_LOGIN_POPUP,
	SHOW_COMPLETE_RESET_PASSWORD_POPUP,
	HIDE_POPUPS
} from './types'

export const hidePopups = () => dispatch => {
	dispatch({
		type: HIDE_POPUPS
	})
}
export const showLoginPopup = () => dispatch => {
	dispatch({
		type: HIDE_POPUPS
	})
	dispatch({
		type: SHOW_LOGIN_POPUP
	})
}

export const showCompleteResetPasswordPopup = token => dispatch => {
	dispatch({
		type: SHOW_COMPLETE_RESET_PASSWORD_POPUP,
		payload: token
	})
}
