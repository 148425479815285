import {
	LOAD_SINGLE_REMEDIES,
	SCHUSSLER_LOADED,
	GEMMOTHERAPY_LOADED,
	PHYTOTHERAPY_LOADED,
	OLIGOTHERAPY_LOADED
} from './types'
import { api } from '../api'
import processAvailableRemedies from '../utils/processAvailableRemedies'

function handleErrors(response) {
	if (!response.ok) {
		throw Error(response.statusText)
	} else return response
}

export const loadOligotherapy = () => dispatch => {
	api.singleRemedies
		.getOligotherapy()
		.then(res => res.data.data)
		.then(res => {
			// console.log(res)
			return res
		})
		.then(res => {
			dispatch({ type: OLIGOTHERAPY_LOADED, payload: res })
			return res
		})
}
export const loadPhytotherapy = () => dispatch => {
	api.singleRemedies
		.getPhytotherapy()
		.then(res => res.data.data)
		.then(res => {
			// console.log(res)
			return res
		})
		.then(res => {
			dispatch({ type: PHYTOTHERAPY_LOADED, payload: res })
			return res
		})
}
export const loadGemmotherapy = () => dispatch => {
	api.singleRemedies
		.getGemmotherapy()
		.then(res => res.data.data)
		.then(res => {
			// console.log(res)
			return res
		})
		.then(res => {
			dispatch({ type: GEMMOTHERAPY_LOADED, payload: res })
			return res
		})
}

export const loadSchussler = () => dispatch => {
	api.singleRemedies
		.getSchussler()
		.then(res => res.data.data)
		.then(res => {
			// console.log(res)
			return res
		})
		.then(res => {
			dispatch({ type: SCHUSSLER_LOADED, payload: res })
			return res
		})
}

export const loadSingleRemedies = () => dispatch => {
	// console.log('loadSingleRemedies')
	if (api.singleRemedies.url !== undefined) {
		fetch(api.singleRemedies.url)
			// fetch('./singleRemedies.json')
			.then(handleErrors)
			.then(res => res.json())
			.then(res => {
				// console.log(res)
				return res
			})
			.then(res => {
				// console.log(res.data)
				return processAvailableRemedies(res.data)
			})
			.then(singleRemedies =>
				dispatch({
					type: LOAD_SINGLE_REMEDIES,
					payload: singleRemedies
				})
			)
			.catch(err => {
				console.error(
					`There seems to be a problem with the Single Remedies api: ${err}`
				)
			})
	} else {
		console.log('single remedy api disabled')
	}
}

export const test = 'test'
