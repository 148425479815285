import React from 'react'
import Helmet from 'react-helmet'
import Reveal from 'react-reveal/Reveal'
import Footer from '../Footer';
import _t from '../../utils/translate'
import { LanguageNavLink } from '../../utils/LanguageLink'

import __sitemapEn from '../../contents/sitemap/sitemap-en.json';
import __sitemapDe from '../../contents/sitemap/sitemap-de.json';
import __sitemapFr from '../../contents/sitemap/sitemap-fr.json';
import __sitemapIt from '../../contents/sitemap/sitemap-it.json';

const SitemapPage = (props) => {

    let json = __sitemapEn;
    switch(props.language) {
        case 'fr':
            json = __sitemapFr;
            break;
            case 'de':
            json = __sitemapDe;
            break;
            case 'it':
            json = __sitemapIt;
            break;
    }

    function categories(data) {
        return (
            <div className="__wrapper">
                {Object.keys(data).map((categoryId, j) => {
                    const categoryObj = data[categoryId];
                    return (
                        <div key={j} className={`__category __category--${Math.round(categoryObj.links.length / 10)}`}>
                            {categoryObj.url ? (
                                <LanguageNavLink to={`${categoryObj.url}`}>
                                    <h2 className="__category-name">{categoryObj.name}</h2>
                                </LanguageNavLink>
                            ) : (
                                <h2 className="__category-name">{categoryObj.name}</h2>
                            )}

                            <ul className="__links">
                                {categoryObj.links.map((linkObj, i) => {
                                    return (
                                        <li key={i}>
                                            <LanguageNavLink to={`${linkObj.url}`}>
                                                {linkObj.name}
                                            </LanguageNavLink>
                                        </li>
                                    )
                                })}
                            </ul>

                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <>
            <div
                className="page_content sitemap"
                style={{
                    paddingTop: '70px',
                    position: 'relative',
                    paddingBottom: '50px'
                }}
            >

                <Helmet>
                    <title>SN - Sitemap</title>
                </Helmet>

                <Reveal effect="custom_fade_in">
                    <div>
                        <div className="title_with_icon">
                            <div className="icon icon_story active"></div>
                            <h1>Sitemap</h1>
                        </div>
                    </div>
                </Reveal>

                <Reveal effect="custom_fade_in">
                    <div>
                        {categories(json.categories)}
                    </div>
                </Reveal>

                <Reveal effect="custom_fade_in">
                    <div>
                        <div className="title_with_icon">
                            <div className="icon active icon_homeopathy"></div>
                            <h1>Shop</h1>
                        </div>
                    </div>
                    <div>
                        {categories(json.shop)}
                    </div>
                </Reveal>

            </div>

            <Footer/>

        </>
    );

}

export default SitemapPage
