import _ from 'lodash'

const possibleCh = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  200,
  1000,
]

const possibleK = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '12',
  '14',
  '15',
  '16',
  '18',
  '20',
  '22',
  '24',
  '30',
  '34',
  '35',
  '40',
  '50',
  '60',
  '90',
  '100',
  '200',
  '250',
  '300',
  '400',
  '500',
  '600',
  '900',
  'M',
  '1200',
  '2M',
  '2500',
  '3M',
  '4M',
  '5M',
  '6M',
  '9M',
  'XM',
  '12M',
  '20M',
  'LM',
  'CM',
  'DM',
  'MM',
]

const possibleDh = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
]

const possibleLm = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  77,
  78,
  79,
  80,
  81,
  82,
  83,
  84,
  85,
  86,
  87,
  88,
  89,
  90,
  91,
  92,
  93,
  94,
  95,
  96,
  97,
  98,
  99,
  100,
]

// const filterStr = ['1', '2', 3, 4]

export default function processAvailableRemedies(apiResponse) {
  const processedRemedies = apiResponse.map((remedy) => {
    const availableCh = possibleCh.filter(
      (val) => typeof _.find(remedy.ch, (o) => o == val) === 'undefined'
    )
    const availableK = possibleK.filter(
      (val) => typeof _.find(remedy.k, (o) => o === val) !== 'undefined'
    )
    const availableLM = possibleLm.filter(
      (val) => remedy.lmf <= val && val <= remedy.lmt
    )
    const availableDH = possibleDh.filter(
      (val) =>
        typeof _.find(availableCh, (o) => o == val / 2) !== 'undefined' ||
        (typeof _.find(availableCh, (o) => o == (val + 1) / 2) !==
          'undefined' &&
          typeof _.find(availableCh, (o) => o == (val - 1) / 2) !==
            'undefined') ||
        (val === 1 && typeof _.find(availableCh, (o) => o == 1) !== 'undefined')
    )
    return {
      ...remedy,
      availableDilutions: {
        CH: availableCh,
        K: availableK,
        LM: availableLM,
        DH: availableDH,
      },
    }
  })

  // const filtered = possibleCh.filter(
  // 	val => typeof _.find(filterStr, o => o === val) === 'undefined'
  // )

  // console.log(processedRemedies)
  // console.log(typeof _.find(filterStr, o => o === 2) !== 'undefined')

  return processedRemedies
}
