import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import shortid from 'shortid'
import _ from 'lodash'
import Reveal from 'react-reveal/Reveal'

import Footer from '../Footer'
import BackLink from '../BackLink'
import { addToCart } from '../../actions/cartActions'
import formatPrice from '../../utils/formatPrice'
import _t from '../../utils/translate'
import QuantityField from '../../utils/QuantityField'

class SingleFoodSupplement extends React.Component {
	constructor(props) {
		super(props)
		this.addToCartHandler = this.addToCartHandler.bind(this)
		this.changeQuantity = this.changeQuantity.bind(this)
		this.state = { product: false, quantity: 1 }
	}

	static getDerivedStateFromProps(nextProps, lastState) {
		const { products, match, loading } = nextProps

		if (!loading) {
			const product = _.find(products, { slug: match.params.product })

			return { product }
		}

		return null
	}

	addToCartHandler = () => {
		const { product, quantity } = this.state
		const { addToCart: _addToCart } = this.props

		const newProduct = {
			WcId: product.id,
			price: parseFloat(product.price),
			name: product.name,
			icon: 'package_unknown',
			info: {},
			quantity
		}
		_addToCart(newProduct)
	}

	changeQuantity = quantity => this.setState({ quantity })

	render() {
		const { product, quantity } = this.state
		const { language } = this.props
		const { changeQuantity } = this

		if (product && product.acf) {
			// console.log(product)
			return (
				<div className="page_content single_product food_supplements padding_top_140">
					<Helmet>
						<title>{`SN - ${
							product.acf[`title_${language}`]
						}`}</title>
					</Helmet>
					<BackLink
						to={`/${language}/${_t('shop', 'routes')}/${_t(
							'food_supplements',
							'routes'
						)}`}
					>
						{_t('Back to Food Supplements')}
					</BackLink>

					<div className="title_with_icon_wrapper">
						<div className="title_with_icon">
							<div className="icon icon_food_supplements active" />
							<h1>{product.acf[`title_${language}`]}</h1>
						</div>
					</div>
					<div className="single_product_wrapper">
						<div className="product_image">
							<div className="icon icon_food_supplements" />
							<div
								className="image"
								style={{
									// eslint-disable-next-line
									backgroundImage: `url(${product.acf &&
										product.acf.hasOwnProperty(
											'product_image'
										) &&
										product.acf.product_image.hasOwnProperty(
											'sizes'
										) &&
										product.acf.product_image.sizes.large})`
								}}
							/>
						</div>
						<div className="product_info">
							<div
								className="product_description"
								dangerouslySetInnerHTML={{
									__html:
										product.acf[
											`description_${language}`
										] || null
								}}
							/>

							<div className="buy_section">
								<span className="price">
									{product &&
										formatPrice(parseFloat(product.price))}
								</span>
								<QuantityField
									onChange={changeQuantity}
									quantity={quantity}
								/>
								<button
									type="button"
									className="buy"
									onClick={this.addToCartHandler}
									disabled={!product}
								>
									{_t('Add to Cart', 'shop')}
								</button>
							</div>
						</div>
						<div className="clearfix" />
					</div>

					<Footer />
				</div>
			)
		}
		return null
	}
}

SingleFoodSupplement.defaultProps = {}

SingleFoodSupplement.propTypes = {}

const mapStateToProps = state => ({
	productsLoading: state.products.loading,
	products: state.products.products,
	language: state.ui.language
})

const SingleFoodSupplementWrapper = props => (
	<Reveal effect="custom_fade_in" key={shortid.generate()}>
		<SingleFoodSupplement {...props} />
	</Reveal>
)

export default connect(mapStateToProps, { addToCart })(
	SingleFoodSupplementWrapper
)
