import React from 'react';
import { PropTypes } from 'prop-types'

export default class QuantityField extends React.Component {


    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this)
    }

    changeHandler = e => {
        const { onChange } = this.props
        // const value = parseInt(e.target.value, 10) <= 1 ? e.target.value : 1
        const value = e.target.value
        onChange(value)
    }

    increase = () => {
        const { onChange, quantity } = this.props
        onChange(quantity + 1)
    }

    decrease = () => {
        const { onChange, quantity } = this.props
        if (quantity > 1)
            onChange(quantity - 1)
    }

    render() {
        const { quantity, style } = this.props
        const { changeHandler, increase, decrease } = this
        return (
            <div className="quantity_field" style={style}>
                
                            <input type="number" className="quantity" min="1" name="quantity" value={quantity} onChange={changeHandler}/>
                            <button type="button" className="change_qty add" onClick={increase}>+</button>
                            <button type="button" className="change_qty substract" onClick={decrease}>-</button>
            </div>
        );
    }
}

QuantityField.defaultProps = {
    style: {}
}

QuantityField.propTypes = {
    onChange: PropTypes.func.isRequired,
    quantity: PropTypes.number.isRequired,
    style: PropTypes.object // eslint-disable-line
};