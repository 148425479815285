import React from 'react'
import { withRouter } from 'react-router-dom'

import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { login, loadUserData } from '../actions/userActions'
import { hidePopups } from '../actions/popupActions'

import SignupForm from '../forms/SignupForm'

class SignupPopup extends React.Component {
	constructor(props) {
		super(props)
		// this.onSubmit = this.onSubmit.bind(this)
		this.submit = this.submit.bind(this)
	}

	submit = data =>
		this.props
			.login(data)
			.then(() => this.props.loadUserData())
			.then(() => this.props.history.push('/account')) // eslint-disable-line

	closePopup = () => this.props.hidePopups()

	// onSubmit(e) {
	// 	const { data, errors } = this.state
	// 	console.log(data)

	// 	// this.validate(errors)
	// 	// Validation

	// }

	// onSubmit = e => {
	// 	const { data } = this.state
	// 	const { login, history } = this.props
	// 	e.preventDefault()
	// 	const errors = this.validate(data)
	// 	this.setState({ errors })
	// 	if (Object.keys(errors).length === 0) {
	// 		login(data).then(() => history.push('/account'))
	// 		// this.setState({ loading: true })
	// 		// this.props
	// 		//   .submit(this.state.data)
	// 		//   .catch(err =>
	// 		//     this.setState({ errors: err.response.data.errors, loading: false })
	// 		//   );
	// 	}
	// }

	render() {
		const { visible } = this.props
		// const data = { email: 'adsf' }
		return (
			<div className={`popup signup ${visible ? 'visible' : ''}`}>
				<button
					type="button"
					className="close_popup "
					onClick={this.closePopup}
				>
					<div className="icon icon_close active" />
				</button>
				<SignupForm submit={this.submit} />
			</div>
		)
	}
}
SignupPopup.defaultProps = {
	children: []
}

SignupPopup.propTypes = {
	children: PropTypes.array, // eslint-disable-line
	visible: PropTypes.bool.isRequired,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired
	}).isRequired
}

const mapStateToProps = state => ({
	visible: state.popup.signup
})

export default connect(
	mapStateToProps,
	{ login, hidePopups, loadUserData }
)(withRouter(SignupPopup))
