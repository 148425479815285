import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import SinglePageRenderer from '../SinglePageRenderer'

import _t from '../../utils/translate'

const CompanyPage = ({ language }) => {
    return (
        <React.Fragment>
			<Route
				path={`/${language}/${_t('medicine','routes')}`}
				exact
				component={() => <Redirect to={`/${language}/${_t('medicine','routes')}/${_t('homeopathy','routes')}`} />}
			/>
			<Route
				path={`/${language}/${_t('medicine','routes')}/${_t('homeopathy','routes')}`}
				component={() => (
					<SinglePageRenderer pageId="medicine_homeopathy" />
				)}
			/>
			<Route
				path={`/${language}/${_t('medicine','routes')}/${_t('gemmotherapy','routes')}`}
				component={() => (
					<SinglePageRenderer pageId="medicine_gemmotherapy" />
				)}
			/>
			<Route
				path={`/${language}/${_t('medicine','routes')}/${_t('phytotherapy','routes')}`}
				component={() => (
					<SinglePageRenderer pageId="medicine_phytotherapy" />
				)}
			/>
			<Route
				path={`/${language}/${_t('medicine','routes')}/${_t('oligotherapy','routes')}`}
				component={() => (
					<SinglePageRenderer pageId="medicine_oligotherapy" />
				)}
			/>
			<Route
				path={`/${language}/${_t('medicine','routes')}/${_t('schussler_salts','routes')}`}
				component={() => (
					<SinglePageRenderer pageId="medicine_schussler_salts" />
				)}
			/>
			<Route
				path={`/${language}/${_t('medicine','routes')}/${_t('food_supplements','routes')}`}
				component={() => (
					<SinglePageRenderer pageId="medicine_food_supplements" />
				)}
			/>
		</React.Fragment>
    )
}
export default CompanyPage