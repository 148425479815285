import {
	SHOW_LOGIN_POPUP,
	SHOW_SIGNUP_POPUP,
	HIDE_POPUPS,
	SHOW_COMPLETE_RESET_PASSWORD_POPUP
} from '../actions/types'

const initialState = {
	login: false,
	signup: false
}

export default function(state = initialState, action) {
	switch (action.type) {
		case SHOW_LOGIN_POPUP:
			return {
				...state,
				login: true
			}
		case SHOW_SIGNUP_POPUP:
			return {
				...state,
				signup: true
			}
		case SHOW_COMPLETE_RESET_PASSWORD_POPUP:
			return {
				...state,
				completeResetPassword: true,
				resetPasswordToken: action.payload
			}
		case HIDE_POPUPS:
			return {
				...initialState
			}
		default:
			return state
	}
}
