import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const LandingLinkShop = ({ apiElement }) => {
	const shortcodesApplied = apiElement.text.replace(
		/\[icon_([^\]]*)\]/g,
		(matchedString, name) => {
			// const iconName = name !== '' ?  name : 'homeopathy'

			return `<div class="icon shortcode icon_${name} active" ></div>`;
		}
	);
	return (
		<div className="landing_link_shop_wrapper">
			<div className="content_holder">
				<div
					className="image"
					style={{
						backgroundImage: `url(${apiElement.image.sizes.large})`,
						backgroundSize: 'contain',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
					}}
				/>
				<div className="content_box">
					<h1>{apiElement.heading}</h1>
					<div
						className="text_block align_center" // eslint-disable-next-line
						dangerouslySetInnerHTML={{
							__html: shortcodesApplied || null,
						}}
					/>
					<Link
						className="button primary"
						to={
							apiElement.button_link === ''
								? '#'
								: apiElement.button_link
						}
					>
						{apiElement.button_text}
					</Link>
				</div>
			</div>
		</div>
	);
};

LandingLinkShop.defaultProps = {
	apiElement: {},
};
LandingLinkShop.propTypes = {
	apiElement: PropTypes.object, // eslint-disable-line
};

export default LandingLinkShop;
