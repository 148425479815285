import React from 'react'
// import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'

class Video extends React.Component {
	constructor(props) {
		super(props)
		this.togglePlay = this.togglePlay.bind(this)
		this.state = {
			videoPlaying: false
		}
	}

	togglePlay = () => {
		this.setState(oldState => ({ videoPlaying: !oldState.videoPlaying }))
	}

	render() {
		const { videoPlaying } = this.state
		const { apiElement } = this.props
		return (
			<div className="video_player">
				<ReactPlayer
					className="video"
					url={apiElement.youtube_url}
					playing={videoPlaying}
					width="100%"
					height="100%"
					onDuration={this.onDuration}
					onEnded={this.togglePlay}
					preload="auto"
					ref={this.ref}
					config={{
						youtube: {
							playerVars: {
								showinfo: 0,
								controls: 1,
								modestbranding: 1,
								playsinline: 0
							}
						}
					}}
				/>
				<div
					onClick={this.togglePlay}
					className={`cover ${videoPlaying ? '' : 'visible'}`}
					style={{
						background: `url(${apiElement.cover &&
							apiElement.cover.sizes.large})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center'
					}}
				/>
			</div>
		)
	}
}

Video.defaultProps = {}
Video.propTypes = {}

export default Video
