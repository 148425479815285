import React, { createContext, useState, useContext, useEffect } from 'react';
import { getLocations } from '../utils/airtableService';

const MarkerDataContext = createContext();

export const useMarkerData = () => useContext(MarkerDataContext);

export const MarkerDataProvider = ({ children }) => {
    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getLocations();
            setLocations(data);
            setLoading(false);
        };

        fetchData().then();
    }, []);

    return (
      <MarkerDataContext.Provider value={{ locations, loading }}>
          {children}
      </MarkerDataContext.Provider>
    );
};
