import React from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Message } from 'semantic-ui-react'
import Validator from 'validator'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'

import InlineError from '../messages/InlineError'
import { showMessage } from '../actions/uiActions'
import _t from '../utils/translate'
// import { LanguageLink } from '../utils/LanguageLink'

class LoginForm extends React.Component {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)

        this.state = {
            data: {
                username: '',
                password: ''
            },
            loading: false,
            errors: {}
        }
    }

    onChange = e =>
        this.setState({
            data: { ...this.state.data, [e.target.name]: e.target.value },
            errors: { ...this.state.errors, [e.target.name]: false }
        })

    onSubmit = () => {
        const { submit, showMessage } = this.props
        const { data } = this.state
        const errors = this.validate(data)
        this.setState({ errors })
        if (Object.keys(errors).length === 0) {
            this.setState({ loading: true })
            submit(data)
                .then(res => {
                    // console.log(res)
                    this.setState({ loading: false })
                    return res
                })
                .catch(err => {
                    // console.error(err.response.data.code)
                    console.error(err)
                    showMessage(
                        err.response &&
                            err.response.data &&
                            err.response.data.message
                            ? _t(err.response.data.message)
                            : _t('There was a problem logging you in.'),
                        'error'
                    )
                    this.setState({
                        errors: { global: 'There is a problem.' } || {},
                        loading: false
                    })
                })
        }
    }

    validate = data => {
        const errors = {}
        if (!Validator.isEmail(data.username))
            errors.username = _t('Invalid email', 'account')

        return errors
    }

    render() {
        const { data, errors, loading } = this.state
        // const errors = {}

        // console.log(this.state)

        return (
            <Form onSubmit={this.onSubmit} loading={loading}>
                {errors.global && (
                    <Message negative>
                        <Message.Header>
                            {_t('Something went wrong', 'account')}
                        </Message.Header>
                        <p>{errors.global}</p>
                    </Message>
                )}
                <h2 className="align_center">
                    {_t('Reset Password', 'account')}
                </h2>
                <p className="align_center">
                    {_t(
                        'To reset your password, please enter your email address.',
                        'account'
                    )}
                </p>
                <Form.Field error={!!errors.username}>
                    <input
                        type="email"
                        id="email"
                        name="username"
                        placeholder={_t('Email', 'account')}
                        autoComplete="email"
                        value={data.username}
                        onChange={this.onChange}
                    />

                    {errors.username && <InlineError text={errors.username} />}
                </Form.Field>
                <Button primary type="submit">
                    {_t('Reset Password', 'account')}
                </Button>
                <div className="loading">
                    <span>{_t('Loading...')}</span>
                </div>
            </Form>
        )
    }
}

LoginForm.propTypes = {
    submit: PropTypes.func.isRequired
}

export default connect(null, { showMessage })(LoginForm)
