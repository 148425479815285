import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const SeasonalRemedyDisplay = ({ apiElement }) => {
	return (
		<div className="seasonal_remedy_wrapper">
			<div
				className="image"
				style={{
					backgroundImage: `url(${apiElement.image.sizes.large})`
				}}
			>
				<div className="overlay" />
				<h1>{apiElement.title}</h1>
			</div>
			<div className="content_box box">
				{apiElement.products.map(element => {
					const imageUrl =
						element &&
						element.hasOwnProperty('image') &&
						element.image.hasOwnProperty('sizes') &&
						element.image.sizes.large
					// console.log(element)
					return (
						<Link
							key={`seasonal_link_${element.title.toLowerCase()}`}
							className="remedy"
							to={element.link || '#'}
						>
							{/*<span className="no">{product.acf.potency}</span> */}
							{imageUrl && (
								<div
									className="image"
									style={{
										backgroundImage: `url("${imageUrl}")`
									}}
								/>
							)}
							{!imageUrl && <div className="image" />}
							<div className="text">
								<p className="title">{element.title}</p>
								<p className="potency">
									{element.contents_line_1}
									{element.contents_line_2 !== '' &&
										` · ${element.contents_line_2}`}
								</p>
							</div>
						</Link>
					)
				})}
			</div>
			<div
				className="text_holder"
				dangerouslySetInnerHTML={{
					__html: apiElement.text || null
				}}
			/>
		</div>
	)
}

SeasonalRemedyDisplay.defaultProps = {
	apiElement: {}
}
SeasonalRemedyDisplay.propTypes = {
	apiElement: PropTypes.object // eslint-disable-line
}

export default SeasonalRemedyDisplay
