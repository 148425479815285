import React from 'react'
import { Link } from 'react-router-dom'

import _t from '../../utils/translate'
import urlFormat from '../../utils/urlFormat'

const ComplexeSingle = ({ product, linkBase, style }) => (
  <Link
    key={`Complexe_${product.name.toLowerCase()}`}
    className="single_complexe"
    to={`${linkBase}/${urlFormat(product.name)}`}
    style={style}
  >
    <span className="no">N°{product.acf.nr}</span>
    {<div className={`image complexe_${product.acf.nr}`} />}
    <div className="text">
      <p className="title">
        N°{product.acf.nr}
        <br />
        {product.name} {_t('Complexe')}
      </p>
      {
        // product.acf.composition.split('\r').map(line => (
        // 	<p>{line}</p>
        // ))
      }
      <p className="nr_substances">
        {product.acf.nr_substances} {_t('substances')}
      </p>
    </div>
  </Link>
)

export default ComplexeSingle
