import React from 'react'
import { connect } from 'react-redux'
import shortid from 'shortid'

import { Link } from 'react-router-dom'

import Reveal from 'react-reveal/Reveal'
import Helmet from 'react-helmet'
import { Textfit } from 'react-textfit'
import { MobileView, TabletView, BrowserView } from 'react-device-detect'

import Footer from '../Footer'
import BackLink from '../BackLink'

import _t from '../../utils/translate'
import Settings from '../../Settings'
import urlFormat from '../../utils/urlFormat'

/* eslint-disable */
function getRandom(arr, n) {
	let result = new Array(n)
	let len = arr.length
	let taken = new Array(len)
	if (n > len)
		throw new RangeError('getRandom: more elements taken than available')
	while (n--) {
		const x = Math.floor(Math.random() * len)
		result[n] = arr[x in taken ? taken[x] : x]
		taken[x] = --len in taken ? taken[len] : len
	}
	return result
}
/* eslint-enable */

const SingleRessourcesPage = ({ match, apiContent, loading, language }) => {
	if (!loading && 'acf' in apiContent && 'content' in apiContent.acf) {
		const apiElement = apiContent.acf.content.filter(
			value => value.acf_fc_layout === 'health_topics_display'
		)[0]

		const { health_topics: healthTopics } = apiElement;

		const [topic] = apiElement.health_topics.filter(
			value => urlFormat(value.name) === match.params.topic
		)

		if (!topic) {
			return (
				<div></div>
			);
		}

		return (
			<React.Fragment>
				<Helmet>
					<title>
						{topic.name} - {Settings.siteName}
					</title>
				</Helmet>
				<Reveal effect="custom_fade_in" key={shortid.generate()}>
					<div className="page_content padding_top_70">
						<div className="single_health_page">
							<div
								className="header_image"
								style={{
									// eslint-disable-next-line
									backgroundImage: `url(${(topic.header_image.hasOwnProperty(
										'sizes'
									) &&
										topic.header_image.sizes.large) ||
										(topic.preview_image.hasOwnProperty(
											'sizes'
										) &&
											topic.preview_image.sizes.large)})`
								}}
							/>
							<BackLink to={apiElement.base_url}>
								{_t('Back to Resources')}
							</BackLink>
							<div className="title_with_icon_wrapper">
								<div className="title_with_icon">
									<div className="icon icon_bulb active" />
									<h1>{topic.name}</h1>
								</div>
							</div>
							<div className="content_wrapper">
								{'content' in topic &&
									topic.content &&
									topic.content.map(element => {
										switch (element.acf_fc_layout) {
											case 'general_text':
												return (
													<div
														className={`general_text ${element.layout}`}
														key={shortid.generate()}
													>
														<div
															className="text"
															dangerouslySetInnerHTML={{
																__html:
																	element.text ||
																	null
															}}
														/>
													</div>
												)
											case 'tip':
												return (
													<div
														className={`tip ${element.layout}`}
														key={shortid.generate()}
													>
														<div
															className="image"
															style={{
																backgroundImage: `url(${element.image &&
																	element
																		.image
																		.sizes
																		.medium_large})`
															}}
														/>
														<div className="text">
															<h1>
																{element.title}
															</h1>
															<div
																dangerouslySetInnerHTML={{
																	__html:
																		element.text ||
																		null
																}}
															/>
														</div>
													</div>
												)
											case 'product_link':
												return (
													<div
														className="product_link_wrapper"
														key={shortid.generate()}
													>
														<div className="product_link">
															<div className="text">
																<h1>
																	{
																		element.title
																	}
																</h1>
																<div
																	dangerouslySetInnerHTML={{
																		__html:
																			element.text ||
																			null
																	}}
																/>
																<Link
																	className="button"
																	to={
																		element.button_link !==
																		''
																			? element.button_link
																			: './'
																	}
																>
																	{
																		element.button_text
																	}
																</Link>
															</div>

															<div
																className="image"
																style={{
																	backgroundImage: `url(${element.image &&
																		element
																			.image
																			.sizes
																			.medium_large})`
																}}
															/>
														</div>
													</div>
												)
											default:
												return (
													<div>
														{element.acf_fc_layout}
													</div>
												)
										}
									})}
							</div>
						</div>

						<div className="other_topics">
							<h1>{_t('Other Resources', 'resources')}</h1>
							{getRandom(
								healthTopics.filter(
									value =>
										urlFormat(value.name) !==
										match.params.topic
								),
								3
							).map(topic => {
								const key = urlFormat(topic.name)
								return (
									<Link
										key={key}
										to={`${apiElement.base_url}/${key}`}
										className="topic_preview"
										style={{
											backgroundImage: `url(${topic.preview_image.hasOwnProperty(
												'sizes'
											) &&
												topic.preview_image.sizes
													.medium_large})`
										}}
									>
										<div className="green_overlay" />{' '}
										<div className="text_holder">
											<div className="text_holder_inner">
												<MobileView>
													<Textfit
														mode="single"
														max={20.6}
													>
														<p>{topic.name}</p>
													</Textfit>
												</MobileView>
												<TabletView>
													<Textfit
														mode="single"
														max={26}
													>
														<p>{topic.name}</p>
													</Textfit>
												</TabletView>
												<BrowserView>
													<Textfit
														mode="single"
														max={26}
													>
														<p>{topic.name}</p>
													</Textfit>
												</BrowserView>
											</div>
										</div>
									</Link>
								)
							})}
							<div className="clearfix" />
						</div>

						<Footer />
					</div>
				</Reveal>
			</React.Fragment>
		)
	}
	return <div />
}
// 'acf' in apiContent &&
// 'content' in apiContent.acf &&

const mapStateToProps = state => ({
	loading: state.pages.loading,
	apiContent: state.pages.resources,
	language: state.ui.language
})

export default connect(mapStateToProps)(SingleRessourcesPage)
