import React from 'react'
import { withRouter } from 'react-router-dom'
import { LanguageNavLink, LanguageLink } from '../utils/LanguageLink'
import { connect } from 'react-redux'
import { showLoginPopup } from '../actions/popupActions'
import { loadLanguage } from '../actions/pagesActions'
import { showLanguageChooser } from '../actions/uiActions'
import { showSearch } from '../actions/uiActions'
import { hideCart } from '../actions/cartActions'
import _t from '../utils/translate'
import CartButton from './CartButton'
import Settings from '../Settings'

class MainMenu extends React.Component {
  constructor(props) {
    super(props)
    this.accountButtonHandler = this.accountButtonHandler.bind(this)
    // this.changeLanguage = this.changeLanguage.bind(this)
  }

  accountButtonHandler() {
    const { showLoginPopup, loggedIn, history, language } = this.props // eslint-disable-line

    // CHANGE SHOP ONLINE

    // window.open(Settings.shopLink) || window.location.replace(Settings.shopLink)
    // history.push(`/${language}/`)
    loggedIn
      ? history.push(`/${language}/${_t('account', 'routes')}/`)
      : showLoginPopup() // eslint-disable-line
  }

  // changeLanguage() {
  //     this.props.loadLanguage('en')
  // }

  render() {
    const {
      loggedIn,
      firstName,
      lastName,
      showLanguageChooser,
      language,
      showSearch,
      hideCart,
      searchVisible,
    } = this.props
    // const { changeLanguage } = this
    return (
      <div className={`main_menu ${!searchVisible && 'visible'}`}>
        <LanguageNavLink className="home_link" exact to="/" />
        <LanguageLink
          className="button contact_button_menu"
          to={`/${_t('contact', 'routes')}`}
        >
          {_t('Contact', 'menu')}
        </LanguageLink>
        <div className="menu_center" key={`menu_center_${language}`}>
          <LanguageNavLink to={`/${_t('shop', 'routes')}`}>
            {_t('Shop', 'menu')}
          </LanguageNavLink>

          <LanguageNavLink to={`/${_t('company', 'routes')}`}>
            {_t('Company', 'menu')}
          </LanguageNavLink>
          <LanguageNavLink to={`/${_t('medicine', 'routes')}`}>
            {_t('Medicine', 'menu')}
          </LanguageNavLink>
          <LanguageNavLink to={`/${_t('health', 'routes')}`}>
            {_t('Health', 'menu')}
          </LanguageNavLink>
          <LanguageNavLink to={`/${_t('resources', 'routes')}`}>
            {_t('Resources', 'menu')}
          </LanguageNavLink>
          <LanguageNavLink to={`/${_t('news', 'routes')}`}>
            {_t('News', 'menu')}
          </LanguageNavLink>
        </div>
        <div className="menu_right">
          <button type="button" onClick={showLanguageChooser}>
            <div className="icon icon_language active" />
          </button>
          <button
            type="button"
            onClick={() => {
              showSearch()
              hideCart()
            }}
          >
            <div className="icon icon_search active" />
          </button>
          {loggedIn && <CartButton />}
          <button type="button" onClick={this.accountButtonHandler}>
            {loggedIn ? (
              <div className="initials">
                <span>
                  {firstName && firstName[0]}
                  {lastName && lastName[0]}
                </span>
              </div>
            ) : (
              <div className="icon icon_user active" />
            )}
          </button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.user.loggedIn,
  firstName: state.user.firstName,
  lastName: state.user.lastName,
  language: state.ui.language,
  searchVisible: state.ui.searchVisible,
})

export default withRouter(
  connect(mapStateToProps, {
    showLoginPopup,
    loadLanguage,
    showLanguageChooser,
    showSearch,
    hideCart,
  })(MainMenu)
)
